import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';

@Component({
  selector: 'tch-word-list',
  templateUrl: './word-list.component.html',
  styleUrls: ['./word-list.component.css']
})
export class WordListComponent implements OnInit {
	lesson: number;
	wordlist : ISpellingWord[] = [];
	errorMessage : string; 

	constructor (private spellingWordService : SpellingWordService, 
		private route: ActivatedRoute) {}
 
	ngOnInit(): void {
        this.lesson = +this.route.parent.snapshot.params['lesson'];
		this.wordlist = this.spellingWordService.spellingWords;
		// we've lost the words -- probably through a page refresh, so get them again
		if (this.wordlist.length == 0) {
			this.spellingWordService.getWords(this.lesson).subscribe(
				words => {this.wordlist = words},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)}
			);
		}
	}
  
	listen(idx) {
		var audioElement = document.createElement('audio');
		audioElement.setAttribute('src',this.wordlist[idx].audioWordUrl);
		audioElement.play();
	}
	
}
