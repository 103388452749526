import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LessonService } from '../core/lesson.service';
import { ILesson } from '../core/lesson';
import { IDictation } from '../core/dictation';
import { DictationLessonService } from '../core/dictation-lesson.service';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { FormsModule, NgForm } from '@angular/forms';
import { BsModalComponent, BsModalService  } from 'ng2-bs3-modal';

@Component({
  selector: 'tch-dictation-lessons',
  templateUrl: './dictation-lessons.component.html',
  styleUrls: ['./dictation-lessons.component.css'],
  encapsulation: ViewEncapsulation.None    
})
export class DictationLessonsComponent implements OnInit {
	lessons: ILesson[];
	errorMessage : string;
	currLesson : ILesson;
	showAddNew : boolean;
	showOneLesson : boolean;
	newLessonName : string;
	showAddNewItem : boolean = false;
	app : string;
	items: IDictation[] = [];
	@ViewChild('dictation') dictation: NgForm;	
	@ViewChild('confirmDelete') confirmDelete: BsModalComponent;
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;
	deleteIdx : number;
	audioElement: any;
	

	constructor(private lessonService : LessonService, private route: ActivatedRoute, 
				private dictationLessonService: DictationLessonService) { 
		}

	  ngOnInit() {
		this.app = this.route.snapshot.data['app'];		
		this.audioElement = document.createElement('audio');
		this.route.data.subscribe(
			data => this.lessons = data['lessonList']
		);
	}

	save(idx : number) {
		if (this.items[idx].position != null && this.items[idx].text != null && this.items[idx].normalAudioUrl != null && this.items[idx].slowAudioUrl != null) {
			if (this.items[idx].itemId == null) {
				this.dictationLessonService.addItem(this.items[idx]).subscribe(
					() => {this.showAddNewItem = true;
							this.items[idx].dirty = false;}
				);
			} else {
				this.dictationLessonService.updateItem(this.items[idx]).subscribe(
					() => {this.showAddNewItem = true;
							this.items[idx].dirty = false;}
				);
			}
		}
	}

	makeDirty(idx) {
		this.items[idx].dirty = true;
	}

	isDirty(idx) {
		return (this.items[idx].dirty);
	}

	selectLesson() {
		// reset currLesson, because it loses the active flag
		for (var x=0;x<this.lessons.length;x++) {
			if (this.currLesson.id == this.lessons[x].id) {
				this.currLesson.active = this.lessons[x].active;	
				break;
			}				
		}
		if (this.currLesson.id == -1) {
			this.showAddNew = true;
			this.showOneLesson = false;
		} else {	
			// get the lesson items from the database and add them to page
			this.dictationLessonService.getDictationLesson(this.currLesson.id).subscribe(
				items => this.items = items,
				error => this.errorMessage = <any>error,				
				() => {
					this.showAddNew = false; 
					this.showOneLesson = true;
					this.showAddNewItem = true;
				}
			);			
		}
	}

	createNewLesson() {
		this.showAddNew = false;
		// make the new lesson
		this.lessonService.addLesson(this.app, this.newLessonName).subscribe(
			data => {'back from adding lesson' + this.newLessonName},
			error => {this.errorMessage = <any>error;
						console.log(this.errorMessage)},
			() => {
				// requery the database and rebuild the lesson list
				this.lessonService.getAdminLessons(+this.app).subscribe(
					lessons => {
						this.lessons = lessons;
						// set the new lesson as the current lesson -- it will be the one with the highest id
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (+this.lessons[x].id > +this.currLesson.id) {
								this.currLesson = this.lessons[x];
							}
						}
						this.showOneLesson = true;
						this.showAddNewItem = true;
					},
					error => this.errorMessage = <any>error
				);
			}
		);
	}

	updateLesson() {
		this.lessonService.updateLesson(this.currLesson).subscribe(
			() => {
				this.lessonService.getAdminLessons(+this.app).subscribe (
					data => {this.lessons = data;
					},
					error => this.errorMessage = <any>error,
					() => {
						// reselect the lesson
						let cId = this.currLesson.id;
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (this.lessons[x].id == cId) {
								this.currLesson = this.lessons[x];
							}
						}
					}
				);
			}
		);
	}
	
	oops() {
		this.confirmDelete.close();
	}

	deleteItem() {
		this.confirmDelete.close();
		// has not yet been added to the database
		if (this.items[this.deleteIdx].itemId == null) {
			if (this.items.length == 1) {
				this.items = [];
			} else {
				this.items.splice(this.deleteIdx,1);
			}
		// already in the database, so needs to be deleted
		} else {
			this.dictationLessonService.deleteItem(this.items[this.deleteIdx]).subscribe(
				data => {console.log(data)},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)},
				() => {this.items.splice(this.deleteIdx,1);}
			);				
		}
	}

	addItem() {
		// add all new items in the 5th block, beginning with 51
		let nextIdx = this.items.length + 1;
		this.items.push ({	
			itemId : null,
			lessonId : this.currLesson.id,
			position : nextIdx,
			text: '',
			normalAudioUrl: '',
			slowAudioUrl: ''
		});
		this.showAddNewItem = false;		
	}

	setUpDelete(idx : number) {
		this.deleteIdx = idx;
		this.confirmDelete.open();
	}

	listenNormal(idx) {
		this.audioElement.setAttribute('src',this.items[idx].normalAudioUrl);
		this.audioElement.play();
	}

	listenSlow(idx) {
		this.audioElement.setAttribute('src',this.items[idx].slowAudioUrl);
		this.audioElement.play();
	}
}
