<div>
	<div class="col-md-offset-2 myprogress"  >
		<div class="row-fluid" >
			<h1>All Done!</h1>
		</div>
		<div class="row-fluid" >
			<img src="./images/smiley.jpg" />
		</div>
	</div>
</div>
	