import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class LoginService {
	private _webServiceUrl : string;
	private subdomain : string;
	private body : any;

	constructor(private _http: HttpClient) {
	    if (window.location.hostname != 'localhost') {
    		 this.subdomain = window.location.hostname.split('.')[0];
    	}
	}

	confirmAccess(tokenId : string): Observable<any> {   
		if (this.subdomain == null) {
    		var dummy = {result: '14'};
			return Observable.of(dummy);
		} else {
	    	this.body = 'tokenId=' + tokenId;
    		this._webServiceUrl = 'webservices/services/validateAdminPassword.php';
			return this._http.post<any>(this._webServiceUrl, this.body, {
        		headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
	      		})
    	  		.catch(this.handleError);
		}
	}  

	confirmTeacherAccess(tokenId : string): Observable<any> {   
		if (this.subdomain == null) {
    		var dummy = {result: '14'};
			return Observable.of(dummy);
		} else {
    		this.body = 'tokenId=' + tokenId;
		    this._webServiceUrl = 'webservices/services/validateTeacherPassword.php';
			return this._http.post<any>(this._webServiceUrl, this.body, {
        		headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
		    	})
      			.catch(this.handleError);
		}
	}  

	private handleError(err: HttpErrorResponse) {
		console.log(err.message);
		return Observable.throw(err.message);
	}  

}