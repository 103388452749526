import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { Observable } from 'rxjs/Observable';
import { Subscription} from 'rxjs/Subscription';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/takeWhile';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
    selector: 'tch-type-word-cloze',
	templateUrl: './type-word-cloze.component.html',
	styleUrls: ['./type-word-cloze.component.css']
})
export class TypeWordClozeComponent implements OnInit {
	lesson: number;
	words : ISpellingWord[] = [];
	curr : number = 0;
	currWord : string;  // holds partial word as it is displayed
	atEnd : boolean = false;
	showWord : boolean = false;
	showRepeat : boolean = false;
	showSentence : boolean = false;
	useCloze : boolean = true;
	typedWord : string;
	wordsExt : any[] = [];
	audioElement : any;
	currLtr : number;
	inputFocus : boolean = true;
	audioPath: Subscription;
	visualPath : Subscription;
	correct : string[] = [];
	incorrect : string[] = [];
	errorMessage : string;
	
	constructor (private spellingWordService : SpellingWordService, private spellingTasksService : SpellingTasksService,
				private route: ActivatedRoute, private router: Router) {}
   
	ngOnInit(): void {
		this.lesson = +this.route.parent.snapshot.params['lesson'];
		this.words = this.spellingWordService.spellingWords;
		if (this.words.length > 0) {
			this.prepWords();
		// we've lost the words -- probably through a page refresh, so get them again
		} else {
			this.spellingWordService.getWords(this.lesson).subscribe(
				words => {this.words = words; this.prepWords();},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)}
			);
		}
	}

	prepWords(): void {
		this.spellingWordService.shuffle(this.words);
		// add some Extension properties to the words applicable for this activity
		for (var i=0;i<this.words.length;i++) {
			this.wordsExt.push({});
			this.wordsExt[i].attempts = 0;
			let fieldWidth = this.words[i].word.length < 6 ? this.words[i].word.length*24 : this.words[i].word.length*20;
			if (fieldWidth == 24) {
				fieldWidth = 48;
			}
			this.wordsExt[i].width = (fieldWidth) + "px";
		}
		this.audioElement = document.createElement('audio');
		this.displayWord();
	}

	displayWord() {
		this.typedWord = "";
		this.currWord = "";
		this.showWord = true;
		this.showSentence = false;
		this.showRepeat = false;
		this.spell();
	}
	
	redisplayWord() {
		this.showWord = true;
		this.spell();
	}


	spell() {
		this.currLtr = 0;
		this.audioElement.setAttribute('src',this.words[this.curr].audioSpellUrl);
		this.audioElement.play();
		this.audioPath = Observable.interval(1000)
			.takeWhile(() => !this.audioElement.paused)
			.subscribe(t=>{},error=>{},()=>{
				if (this.useCloze) {
					this.audioElement.setAttribute('src',this.words[this.curr].audioClozeUrl);
					this.audioElement.play();
				}		
			}
		);
		this.visualPath = Observable.interval(1000)
			.takeWhile(() => this.currLtr+1 <= this.words[this.curr].word.length)
			.subscribe(t=>{this.nextLetter()},error=>{},()=>{
				this.showSentence = true;
				this.showRepeat = true;
				this.inputFocus = true;
			});
	}
	
	// This function re-displays the word with one more letter added.  When the last letter is displayed, it queues the sentence to be displayed.
	nextLetter() {
		this.showWord = true;		
		this.currLtr++;
		this.currWord = this.words[this.curr].word.substring(0, this.currLtr);
	}

	repeatWord() {
		this.audioPath.unsubscribe();
		this.visualPath.unsubscribe();
		this.redisplayWord();
	}
		
	onCheck() {
		this.audioPath.unsubscribe();
		this.visualPath.unsubscribe();
		this.wordsExt[this.curr].attempts += 1;
		if (this.typedWord.trim() == this.words[this.curr].word) {
			this.curr++;
			this.showSentence = false;
			this.currWord = "";
			this.typedWord = "";
			if (this.curr == this.words.length) {
				this.finishUp();
			} else {
				this.displayWord();
			}
		} else {
			this.redisplayWord();
		}
	}

	finishUp() {
		this.atEnd = true;
		for (var i=0;i<this.words.length;i++)  {
			if (this.wordsExt[i].attempts == 1) {
				this.correct.push(this.words[i].word);
			} else {
				this.incorrect.push(this.words[i].word);
			}
		}
	}

	onKeyUp(event : any) {
		this.showWord = false;
	}

	ngOnDestroy(): void {
		this.audioPath.unsubscribe();
		this.audioElement.setAttribute('src','');
	}
}