import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentPlansComponent } from './current-plans.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
		{ path: 'CurrentPlans/:plan', component: CurrentPlansComponent },
	  ]) ],
	declarations: [CurrentPlansComponent]
})
export class CurrentPlansModule { }
