import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Component({
  selector: 'tch-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {
	message: string = '';
	myFile : FileList;
	private subdomain : string;
	

	constructor(private _http: HttpClient) { 
		if (window.location.hostname != 'localhost') {
			this.subdomain = window.location.hostname.split('.')[0];
		}
	}

	ngOnInit() {
	}

	onChange(event : any) {
		this.myFile = event.target.files;
		this.message = '';
	}

	uploadImageFile(){
		this.message = '';
		this.makeFileRequest ("webservices/services/uploadimage.php", this.myFile).subscribe(
			data => this.message = data,
			error => this.message = error
		);
	}
  
	uploadAudioFile(){
		this.message = '';
		this.makeFileRequest ("webservices/services/uploadaudio.php", this.myFile).subscribe(
			data => this.message = data,
			error => this.message = error
		);
	}

	makeFileRequest(url: string, files: FileList ) : Observable<any> {
		let formData = new FormData();
		let file = files[0];
		formData.append("file", file, file.name);
		return this._http.post(url, formData)
		.catch(this.errorHandler);
	}

	private errorHandler(error: Response) {
		console.error('Error Occurred: ' + error);
		return Observable.throw(error || 'Some Error on Server Occurred');
	}
}
