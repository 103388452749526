import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { DragulaService } from 'ng2-dragula/components/dragula.provider';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
	selector: 'tch-scrambled-word',
	templateUrl: './scrambled-word.component.html',
	styleUrls: ['./scrambled-word.component.css']
})
export class ScrambledWordComponent implements OnInit {
	lesson: number;
	words: ISpellingWord[] = [];
	atEnd : Boolean = false;
	dropSub: any;
	sliderClass : string = 'slide-in';
	word : ISpellingWord;
	currWord : number = 0;
	target : any[];
	source : any[];
	errorMessage : string;
  
	constructor (private spellingWordService : SpellingWordService, private spellingTasksService : SpellingTasksService,
				private route: ActivatedRoute, private dragulaService: DragulaService) {
		this.dragulaService.setOptions('dropTarget', {
			revertOnSpill: true,
			// prevents items from moving into the top row or around on it, or from causing 2 letters to be in the same target space
			accepts: function (el, target, source, sibling) {
				return target.classList.contains('toCont') && target.childElementCount < 2;
			},
			// prevents fixed elements from moving
			moves: function (el, source, handle, sibling) {
				return el.draggable;
			}
		});
		this.dropSub = this.dragulaService.drop.subscribe((value) => {
			this.onDrop(value.slice(1));
		});
	}
  
    ngOnInit(): void {
		document.body.style.overflow = "hidden";
        this.lesson = +this.route.parent.snapshot.params['lesson'];
		this.words = this.spellingWordService.spellingWords;
		if (this.words.length > 0) {
			this.prepWords();
		// we've lost the words -- probably through a page refresh, so get them again
		} else {
			this.spellingWordService.getWords(this.lesson).subscribe(
				words => {this.words = words; this.prepWords();},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)}
			);
		}
	}
	
	prepWords(): void {
		this.spellingWordService.shuffle (this.words);
		this.currWord = 0;
		this.setupWord();
	}
	  
	// dragula doesn't clean up after itself
	ngOnDestroy() {
		this.dropSub.unsubscribe();
		this.dragulaService.destroy('dropTarget');
		document.body.style.overflow = "visible";		
	}
  
    setupWord() {
		this.word = this.words[this.currWord];
		this.target = [];
		this.source = [];
		let letters = this.word.word.split('');
		for (var i=0; i<letters.length; i++) {
			this.source.push({letter: letters[i], drag: true});
			this.target.push({drag : true});
		}
		this.spellingWordService.shuffle(this.source);
    }

	onDrop(dragInfo) {
		var toIdx = +dragInfo[1].attributes.idx.value;
		var fromIdx = +dragInfo[2].attributes.idx.value;
		// moving letters around in the target
		if (dragInfo[1].classList.contains('toCont') && dragInfo[2].classList.contains('toCont')) {
			// if both the from and to locations are still draggable
			if (this.target[fromIdx].drag && this.target[toIdx].drag) {
				// if the to-location already has a letter, swap them
				if (this.target[toIdx].letter) {
					let temp = this.target[toIdx].letter;
					this.target[toIdx].letter = this.target[fromIdx].letter;
					this.target[fromIdx].letter = temp;
				// otherwise move the letter
				} else {
					this.target[toIdx].letter = this.target[fromIdx].letter;
					delete this.target[fromIdx].letter;
				}
			// otherwise, you cannot move them
			} else {
				this.dragulaService.find('dropTarget').drake.cancel(true);							
				return;
			}
		// moving from the source to the target (top to bottom)
		} else {
			// if you try to move a letter on top of another one
			if (this.target[toIdx].letter) {
				// if the target is draggable swap them, otherwise cancel the drag
				if (this.target[toIdx].drag) {
					let temp = this.target[toIdx].letter;
					this.target[toIdx].letter = this.source[fromIdx].letter;
					this.source[fromIdx].letter = temp;
				} else {
					this.dragulaService.find('dropTarget').drake.cancel(true);			
					return;
				}
			// if the slot is empty, just move it
			} else {
				this.target[toIdx] = this.source[fromIdx];
				this.source[fromIdx] = {drag: true};
			}
		}
		// if we got this far, we did not cancel the drag, so check to see if we are done with this word
		// determine how many of the bubbles have been filled and how many are correct
		let filled = 0;
		let matched = 0;
		for (var i=0;i<this.word.word.length;i++) {
			if (this.target[i].letter) {
				filled +=1 ;
				if (this.target[i].letter == this.word.word.charAt(i)) {
					matched += 1;
				}
			}
		}
		// if all the bubbles all filled
		if (filled == this.word.word.length) {
			// go on to the next word (or finish up) if they are all correct
			if (filled == matched) {
				if (this.currWord+1 >= this.words.length) {
					this.atEnd = true;
				} else {
					let boxes = dragInfo[1].parentElement.children;
					for (var i=0;i<boxes.length;i++) {
						let el = boxes[i];
						el.classList.add('btn-sm-fixed');
					}
					var that = this;
					setTimeout(function() {that.sliderClass='slide-out';
											setTimeout(function() {	that.currWord++;	
																	that.setupWord(); 
																	that.sliderClass='slide-in'; }, 1000);},
											2000);
				}
			// otherwise give feedback on good and bad letters
			} else {
				let boxes = dragInfo[1].parentElement.children;
				for (var i=0;i<this.word.word.length;i++) {
					// send all the bad ones back to the target
					if (this.target[i].letter != this.word.word.charAt(i)) {
						this.source[i] = {letter : this.target[i].letter, drag : true };
						this.target[i] = {drag : true};
					// fix the correct ones so they can't move
					} else {
						this.target[i] = {letter : this.target[i].letter, drag : false };
						this.source[i] = {drag : false};
						let el = boxes[i];
						el.classList.add('btn-sm-fixed');
					}
				}
			}
		}
	}

	listen(idx) {
		var audioElement = document.createElement('audio');
		audioElement.setAttribute('src',this.word.audioWordUrl);
		audioElement.play();
	}	

}
