import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IDictation } from './dictation';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class DictationLessonService {
	dictations : IDictation[] = [];
	errorMessage: string;

  constructor(private _http: HttpClient) { }

	getDictationLesson(lessonId) : Observable<IDictation[]> {
		if (window.location.hostname == 'localhost') {
			return Observable.of ([
				{itemId : 1, lessonId : 1, position : 1, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 2, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 3, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 4, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 5, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 6, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 7, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 8, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 9, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 10, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 11, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 12, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 13, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 14, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 15, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'},
				{itemId : 1, lessonId : 1, position : 16, text : 'black', normalAudioUrl : './audio/black.mp3', slowAudioUrl : './audio/black.mp3'}
			]);
	  	} else {
			let webServiceUrl = 'webservices/services/getDictationLesson.php?lessonId=' + encodeURIComponent(lessonId);
			return this._http.get<IDictation[]>(webServiceUrl)
			  .catch(this.handleError);
		}
	}	

	addItem(item: IDictation) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/addDictation.php', encodedData);
	}

	deleteItem(item: IDictation) {
		var encodedData = JSON.stringify(item.itemId);
		return this._http.post('webservices/services/deleteDictation.php', encodedData);
	}

	updateItem(item: IDictation) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/updateDictation.php', encodedData);
	}

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}

}
