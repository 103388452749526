<div class="panel panel-primary">
	<div class="panel-heading">
		<a [routerLink]="['/teacher/teacher-menu']" style="color: white;"><-Back to Teacher Menu</a>
		<div class="pull-right">Our Stories</div>
	</div>
	<div class="panel-body">
		<div class="row">
			<div class="col-md-1 col-md-offset-1">
				<button id="newLesson" class="btn btn-primary" [routerLink]="[0, 'edit']">New Story</button>
			</div>
		</div>
		<div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>	
        <div class="table-responsive">
			<table class="compact" *ngIf="stories && stories.length">
				<thead>
					<tr>
						<th></th>
						<th style="text-align: center;">Story</th>
						<th>Visible to Students</th>
						<th  style="padding-left: 2em;">Mode</th>
					</tr>					
				</thead>
				<tbody>
					<tr *ngFor="let story of stories">
						<td><a class="btn btn-primary" [routerLink]="[story.lessonId,'edit']">Edit</a></td>
						<td>{{story.lessonName}}</td>
						<td style="text-align: center;" *ngIf="story.active != 1">No</td>
						<td style="text-align: center;" *ngIf="story.active == 1">Yes</td>
						<td style="padding-left: 2em;">{{story.mode}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
