import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ISpellingWord } from './spelling-word';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class SpellingWordService {
    spellingWords : ISpellingWord[] = [];
    lessonId : number;
	errorMessage : string;
	hideWord : boolean;
    
    constructor(private _http: HttpClient) {  }

  getSpellingWords(lessonId) : void {
    if (window.location.hostname == 'localhost') {
      this.spellingWords = [
        {id: 1, word: 'black', cloze1: 'Do you like my', cloze2: 'boots?', imageUrl: './images/black.jpg', audioWordUrl: 'audio/black.mp3', audioClozeUrl: 'audio/black_cloze.mp3', audioSpellUrl: 'audio/black_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
        {id: 2, word: 'blue', cloze1: 'She is wearing', cloze2: 'jeans to the picnic.', imageUrl: '/images/blue.jpg', audioWordUrl: 'audio/blue.mp3', audioClozeUrl: 'audio/blue_cloze.mp3', audioSpellUrl: 'audio/blue_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
        {id: 4, word: 'green', cloze1: 'He is wearing', cloze2: 'pants.', imageUrl: 'images/green.jpg', audioWordUrl: 'audio/green.mp3', audioClozeUrl: 'audio/green_cloze.mp3', audioSpellUrl: 'audio/green_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
        {id: 6, word: 'construction', cloze1: 'A', cloze2: 'worker works hard.', imageUrl: 'construction.jpg', audioWordUrl: 'audio/construction.mp3', audioClozeUrl: 'audio/construction_cloze.mp3', audioSpellUrl: 'audio/construction_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''}
      ];
    } else if (lessonId != this.lessonId || this.spellingWords.length == 0) {
        this.getWords(lessonId).subscribe(
            words => this.spellingWords = words,
            error => this.errorMessage = <any>error
        );
    }
  }

	getAllSpellingWords() : Observable<ISpellingWord[]> {
    	if (window.location.hostname == 'localhost') {
      		return Observable.of([
		        {id: 1, word: 'black', cloze1: 'Do you like my', cloze2: 'boots?', imageUrl: './images/black.jpg', audioWordUrl: 'audio/black.mp3', audioClozeUrl: 'audio/black_cloze.mp3', audioSpellUrl: 'audio/black_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
    		    {id: 2, word: 'blue', cloze1: 'She is wearing', cloze2: 'jeans to the picnic.', imageUrl: '/images/blue.jpg', audioWordUrl: 'audio/blue.mp3', audioClozeUrl: 'audio/blue_cloze.mp3', audioSpellUrl: 'audio/blue_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
        		{id: 3, word: 'blue2', cloze1: 'The sky is', cloze2: '.', imageUrl: '/images/blue.jpg', audioWordUrl: 'audio/blue.mp3', audioClozeUrl: 'audio/blue_cloze.mp3', audioSpellUrl: 'audio/blue_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
	        	{id: 4, word: 'green', cloze1: 'He is wearing', cloze2: 'pants.', imageUrl: 'images/green.jpg', audioWordUrl: 'audio/green.mp3', audioClozeUrl: 'audio/green_cloze.mp3', audioSpellUrl: 'audio/green_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
	    	    {id: 5, word: 'green2', cloze1: 'This', cloze2: 'patchwork is interesting.', imageUrl: 'images/green.jpg', audioWordUrl: 'audio/green.mp3', audioClozeUrl: 'audio/green_cloze.mp3', audioSpellUrl: 'audio/green_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''},
    	    	{id: 6, word: 'construction', cloze1: 'A', cloze2: 'worker works hard.', imageUrl: 'construction.jpg', audioWordUrl: 'audio/construction.mp3', audioClozeUrl: 'audio/construction_cloze.mp3', audioSpellUrl: 'audio/construction_spell.mp3', badSpelling1: 'bad1', badSpelling2: 'bad2', badSpelling3: 'bad3', avoid: ''}
      		]);
    	} else {
			let webServiceUrl = 'webservices/services/getAllSpellingWords.php';
			return this._http.get<ISpellingWord[]>(webServiceUrl)
				  .catch(this.handleError);
	    }
	  }
	  
	getSpellingLessonWords() : Observable<any[]> {
		let webServiceUrl = 'webservices/services/getLessonWords.php';
		return this._http.get<any[]>(webServiceUrl)
			  .catch(this.handleError);
	}

  wordSetSize (curr, defaultSize) : number {
    if (this.spellingWords.length - curr > defaultSize + 1)
      return defaultSize;
    else if (this.spellingWords.length - curr == defaultSize + 1)
      return defaultSize - 1;
    else
      return this.spellingWords.length - curr;
  }

  shuffleNoConflict (attempts, defaultSize) : void {
    for (var t=0; t<attempts; t++) {
      this.shuffle(this.spellingWords);
      if (this.trySets(defaultSize)) {
        break;
      }
    }
  }

  shuffle(data) : void {
    if (data && data.length > 1) {
      // make a clone
      var clone = [];
      for (var i=0;i<data.length;i++) {
        clone.push(data[i]);
      }
      // do the shuffle
      var currentIndex = data.length;
      var temporaryValue;
      var randomIndex;
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        // And swap it with the current element.
        temporaryValue = data[currentIndex];
        data[currentIndex] = data[randomIndex];
        data[randomIndex] = temporaryValue;
      }
      // check whether a shuffle actually happened
      var diffound = false;
      for (i=0;i<data.length;i++) {
        if (data[i] != clone[i]) {
          diffound = true;
        }
      }
      // try it once more if necessary
      if (!diffound) {
          this.shuffle(data);        
      }
    }
  }

  private trySets (defaultSize) : Boolean {
    var noConflict = true;
    var curr = 0;
    var ssize;
    var setStart = [];
    var setSize = [];
    // determine how many sets and how long each set is
    while (curr < this.spellingWords.length) {
      ssize = this.wordSetSize (curr, defaultSize);
      setStart.push(curr);
      setSize.push(ssize);
      curr += ssize;
    }
    // for each set
    for (var set=0;set<setStart.length;set++) {
      // add the do-not-combine words from each
      var notCombinable = '';
      for (var word=0;word<setSize[set];word++) {
        if (this.spellingWords[setStart[set] + word].avoid != null && this.spellingWords[setStart[set] + word].avoid.length > 0) {
          notCombinable += this.spellingWords[setStart[set] + word].avoid;
        }
      }
      // check each word against the do-not-combine set
      for (var w=0;w<setSize[set];w++) {
        if (notCombinable.includes(this.spellingWords[setStart[set] + w].word + ',')) {
          noConflict = false;
        }
      }
    }
    return noConflict;
  }

  	// this one is called from Admin, which doesn't pre-fetch for a series of tasks
	getWords(lessonId): Observable<ISpellingWord[]> {   
      let webServiceUrl = 'webservices/services/getSpellingWords.php?lessonId=' + encodeURIComponent(lessonId);
      return this._http.get<ISpellingWord[]>(webServiceUrl)
        .catch(this.handleError);
  	}

	addLessonWord (lessonId : number, wordId : Number) {
		let data = {'lessonId' : lessonId, 'wordId' : wordId};
		var encodedData = JSON.stringify(data);
		return this._http.post('webservices/services/addLessonWord.php', encodedData);
	}

	deleteLessonWord (lessonId : number, wordId : number) {
		let data = {'lessonId' : lessonId, 'wordId' : wordId};
		var encodedData = JSON.stringify(data);
		return this._http.post('webservices/services/deleteLessonWord.php', encodedData);
	}

	deleteWord (word : ISpellingWord) {
		var encodedData = JSON.stringify(word);
		return this._http.post('webservices/services/deleteSpellingWord.php', encodedData);
	}

	updateWord (word : ISpellingWord) {
		word.audioClozeUrl = word.audioWordUrl.replace('.mp3', '_cloze.mp3');
		word.audioSpellUrl = word.audioWordUrl.replace('.mp3', '_spell.mp3');
		var encodedData = JSON.stringify(word);
		return this._http.post('webservices/services/updateSpellingWord.php', encodedData);
	}

	addWord (word : ISpellingWord) {
		word.audioClozeUrl = word.audioWordUrl.replace('.mp3', '_cloze.mp3');
		word.audioSpellUrl = word.audioWordUrl.replace('.mp3', '_spell.mp3');
		var encodedData = JSON.stringify(word);
		return this._http.post('webservices/services/addSpellingWord.php', encodedData);
	}

	setHideWord (hideIt : number) {
		this.hideWord = hideIt == 1 ? true : false;
	}

	getHideWord () : boolean {
		return this.hideWord;
	}

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}
}
