import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { PersonService } from '../core/person.service';
import { IPerson } from '../core/person';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PersonResolverService implements Resolve<IPerson[]> {

	constructor (private personService : PersonService, private router: Router) {}

  	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<IPerson[]> {
		return this.personService.getAllPeople();
  	}

}
