import { Injectable } from '@angular/core';

@Injectable()
export class CompareService {

	constructor() { }

	// not currently used, will need to be re-tested if used again
	compareByLetter(key, answer) {
		let keyLen : number = key.length;
		let ansLen : number = answer.length;
		let k : number = 0;
		let a : number = 0;
		let formatted : string = '';
		while (k < keyLen && a < ansLen) {
			if (key[k] == answer[a]) {
				formatted += answer[a];
				k++;
				a++;
			} else {
				let kFound : number = answer.slice(a).indexOf(key.slice(k, k+5));
				let aFound : number = key.slice(k).indexOf(answer.slice(a, k+5));
				// 2 valid restarts found; use the soonest
				if (kFound > 0 && aFound > 0 && kFound < 11 && aFound < 11) {
					if (kFound < aFound) {
						formatted += this.yellow(answer.slice(a, a+kFound));
						a += kFound;
					} else {
						formatted += this.yellow("_".repeat(aFound));
						k += aFound;
					}
				// text missing in the answer
				} else if (aFound > 0 && aFound < 11) {
					formatted += this.yellow("_".repeat(aFound));
					k += aFound;
				// extra text in the answer
				} else if (kFound > 0 && kFound < 11) {
					let numBad : number = kFound;
					formatted += this.yellow(answer.slice(a, a+kFound));
					a += kFound;
				// this character is bad (we might find more on the next loop)
				} else {
					formatted += this.yellow(answer[a]);
					k++;
					a++;
				}
			}
		}
		// handle any leftovers
		if (k+1 < keyLen) {
			formatted += this.yellow("_".repeat(keyLen-k));
		} else if (a+1 < ansLen) {
			formatted += this.yellow(answer.slice(a));
		} else if (ansLen + (formatted.match(/_/g)||[]).length < keyLen) {
			formatted += this.yellow("_".repeat(keyLen-ansLen));				
		}
		return formatted;
	}

	compareByWord(keyString, answerString) {
		// extract the punctuation (if any)
		let keyPunc : string = '';
		if (keyString.charCodeAt(keyString.length-1) < 65) {
			keyPunc = keyString[keyString.length-1];
			keyString = keyString.slice(0, keyString.length-1).trim();
		}
		let ansPunc : string = '';
		if (answerString.charCodeAt(answerString.length-1) < 65) {
			ansPunc = answerString[answerString.length-1];
			answerString = answerString.slice(0, answerString.length-1).trim();
		}
		// split up the string into words
		let key : string[] = keyString.split(" ");
		let answer : string[] = answerString.split(" ");
		let keyLen : number = key.length;
		let ansLen : number = answer.length;
		let k : number = 0;
		let a : number = 0;
		let result : string[] = [];
		while (k < keyLen && a < ansLen) {
			// good match
			if (key[k] == answer[a]) {
				result.push(answer[a]);
				k++;
				a++;
				// check for combined words
			} else if (k+1 < keyLen && answer[a] == key[k]+key[k+1]) {
				result.push(this.yellow(answer[a]));
				a += 1;
				k += 2;
			// if the next two words match, this is just a bad word
			} else if (a+2 < ansLen && k+2 < keyLen && answer[a+1] == key[k+1] && answer[a+2] == key[k+2]) {
				result.push(this.yellow(answer[a]));
				k++;
				a++;
			// if the current word in the answer is found further along in the key, we are missing a word
			} else if (this.subArray (answer.slice(a, a+1), key.slice(k))) {
				result.push(this.yellow(Array(key[k].length).join(" ")));
				k += 1;
			// if the next word in the answer is found further along in the key, this word is extra
			} else if (a+1 < ansLen && this.subArray(answer.slice(a+1, a+2), key.slice(k))) {
				result.push(this.yellow(answer[a]));
				a += 1;
			// if this is the last word in the answer (but not the key) and the last words don't match, this word is bad
			} else if (ansLen-a == 1 && keyLen-k != 1 && answer[a] != key[keyLen-1]) {
				result.push(this.yellow(answer[a]));
				a += 1;
			// if this is the last word in the answer and the key, fill in the blanks
			} else if (ansLen-a == 1 && answer[a] == key[keyLen-1]) {
				while (k < keyLen-1) {
					result.push(this.yellow(Array(key[k].length).join(" ")));
					k += 1;							
				}
			// this word hasn't been found anywhere, and the remainin answer is longer than remaining key, so just move the answer index
			} else if (ansLen-a > keyLen-k) {
				result.push(this.yellow(answer[a]));
				a++;
			// this word is bad (we might find more on the next loop)
			} else {
				result.push(this.yellow(answer[a]));
				k++;
				a++;
			}
		}
		// if we ran out of key, the rest of the answer is bad (only happens if the last word was good)
		while (k == keyLen && a < ansLen) {
			result.push(this.yellow(answer[a]));
			a +=1;						
		}
		// if we ran out of answer, fill with the correct spaces (only happens if the last word was good)
		while (a == ansLen && k < keyLen) {
			result.push(this.yellow(Array(key[k].length).join(" ")));
			k += 1;
		}
		// handle the punctuation
		let formatted : string = result.join(" ");
		if (keyPunc != '') {
			if (keyPunc == ansPunc) 
				formatted += keyPunc;
			else if (ansPunc == '')
				formatted += this.yellow(" ");
			else
				formatted += this.yellow(ansPunc);
		} else if (ansPunc != '') {
			formatted += this.yellow(ansPunc);
		}
		// format the answer
		return formatted;
	}

	subArray(searchFor, searchIn) : boolean {
		let isSubArray : boolean = false;
		// if searchFor is short enough to be in SearchIn
		if (searchFor.length <= searchIn.length) {
			let found : number = searchIn.indexOf(searchFor[0]);
			// if the first element is in the array in a position that allows enough length still to find all of them
			if (found > -1 && searchIn.length-found >= searchFor.length) {
				let allFound : boolean = true;
				for (var x=0;x<searchFor.length;x++) {
					if (searchFor[x] != searchIn[found+x]) allFound = false;
				}
				isSubArray = allFound;
			}
		}
		return isSubArray;
	}	

	yellow(badStr) : string {
		return '<span  class="hilite">' + badStr + '</span>';
	}	
	
}
