import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IPerson } from '../core/person';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BsModalComponent, BsModalService } from 'ng2-bs3-modal';
import { PersonService } from '../core/person.service';


@Component({
	selector: 'tch-people',
	templateUrl: './people.component.html',
	styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit, OnDestroy {
	people: IPerson[] = [];
	errorMessage: string;
	@ViewChild('personUpdated') personUpdated: BsModalComponent;
	animation = true;
	keyboard = true;
	backdrop: string | boolean = true;
	navigationSubscription;

	constructor(private personService: PersonService, private route: ActivatedRoute, private router: Router) {
		this.navigationSubscription = this.router.events.subscribe((e:any) => {
			if (e instanceof NavigationEnd) {
				this.fetchData();
			}
		});
	 }

	ngOnInit() {
		this.fetchData();
	}

	ngOnDestroy() {
		// avoid memory leaks here by cleaning up after ourselves. If we  
		// don't then we will continue to run our initialiseInvites()   
		// method on every navigationEnd event.
		if (this.navigationSubscription) {  
		   this.navigationSubscription.unsubscribe();
		}
	  }

	deactivate(user) {
		this.personService.deactivatePerson(user).subscribe(
			() => {this.people = []; this.fetchData(); this.router.navigate(['admin/people']);}
		);
	}

	fetchData() {
		this.route.data.subscribe(
			data => {this.people = data['peopleList'];},
			error => this.errorMessage = <any>error
		);		
	}

	yesNo (num) {
		return num == '1' ? 'Yes' : 'No';
	}
}
