<div class="wrapper" *ngIf="atEnd == false && words.length > 0">
	<div class="row pull-right">
		<p class="myprogress">word {{curr + 1}} of {{words.length}}</p>
	</div>
	<div class="container col-md-offset-2 col-lg-offset-3">
		<div class="row">
			<div class="col-md-3">
				<img draggable="false" src="{{words[curr].imageUrl}}" />
			</div>
		</div>
		<div class="row">
			<form #form="ngForm" novalidate name="cloze" >
				<div #cloze class="lgfont form-inline row" style="min-height: 55px; vertical-align: middle;">
					<input id="typedWordField" name="typedWordField" class="form-control typein" type="text" autocapitalize="none" autocomplete="off"
								[(ngModel)]="typedWord" [tchFocus] [style.width]="400" />
					<button class="btn btn-default" style="margin-top: 10px;" *ngIf="typedWord && !showButtons" type="submit" (click)="onCheck()">
										Check?</button>
				</div>
			</form>
		</div>
		<div class="row" *ngIf="!showButtons">
			<span class="dot" *ngIf="wordsExt[curr].attempts==0"></span>
			<span class="reddot" *ngIf="wordsExt[curr].attempts>0"></span>
			<span class="dot" *ngIf="wordsExt[curr].attempts<2"></span>
			<span class="reddot" *ngIf="wordsExt[curr].attempts>1"></span>
			<span class="dot" *ngIf="wordsExt[curr].attempts<3"></span>
			<span class="reddot" *ngIf="wordsExt[curr].attempts>2"></span>
		</div>
		<div class="row" *ngIf="showButtons">
				<span>{{words[curr].word}}&nbsp;</span>
		</div>
		<div class="row" *ngIf="showButtons">
			<button class="btn btn-default" (click)="repeatWord()">
				Repeat&nbsp;<span class="glyphicon glyphicon-repeat" aria-hidden="true"></span>
			</button>
			<button class="btn btn-default" (click)="nextWord()">
				Next&nbsp;<span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
			</button>
		</div>
	</div>
</div>
<div class="row container"*ngIf="atEnd == true">
	<div class="col-xs-6 col-md-6">
		<div class="pager">
			<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
			<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
		</div>
		<div class="row">
			<img src="./images/smiley.jpg" />
		</div>
	</div>
</div>  
