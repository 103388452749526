import { Component, OnInit } from '@angular/core';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { LessonService } from '../core/lesson.service';
import { ILesson } from '../core/lesson';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'tch-spelling-words',
  templateUrl: './spelling-words.component.html',
  styleUrls: ['./spelling-words.component.css']
})
export class SpellingWordsComponent implements OnInit {
	ready: boolean = false;
	editMode: boolean = false;
	audioElement: any;
	words : ISpellingWord[] = [];
	currWord : ISpellingWord;
	tempWord : ISpellingWord;
	errorMessage : string;
	currIdx : number;
	wordLessons : string[] = [];
	
	constructor(private route: ActivatedRoute, private spellingWordService: SpellingWordService,
				private lessonService: LessonService) { }

    ngOnInit() {
		this.audioElement = document.createElement('audio');
		this.route.data.subscribe(
			data => this.words = data['wordList']
		)
		
	}

	wordClick(idx) {
		this.currIdx = idx;
		this.currWord = this.words[idx];
		this.editMode = false;
		this.ready = true;
		this.wordLessons = [];
		this.lessonService.getLessonsForWord(this.currWord.id).subscribe(
			lessonNames => this.wordLessons = lessonNames
		);
	}
	
	editWord() {
		this.tempWord = Object.assign({}, this.currWord);
		this.editMode = true;
	}

	deleteWord() {
		this.spellingWordService.deleteWord(this.currWord).subscribe(
			() => {
				this.spellingWordService.getAllSpellingWords().subscribe(
					words => {
						this.words = words;
						this.ready = false;
						this.editMode = false;
					},
					error => this.errorMessage = <any>error		
				);
			}
		);		
	}

	saveWord() {
		this.editMode = false;
		this.ready = false;
		// ensure that the avoid word string ends ina comma
		if (this.tempWord.avoid && this.tempWord.avoid.length > 0 && this.tempWord.avoid[this.tempWord.avoid.length-1] != ',') {
			this.tempWord.avoid += ',';
		}
		// add new words
		if (this.tempWord.id == null){
			this.spellingWordService.addWord(this.tempWord).subscribe(
				() => {
					this.spellingWordService.getAllSpellingWords().subscribe(
						words => {
							this.words = words;
							for (var i=0;i<this.words.length;i++) {
								if (this.words[i].word == this.tempWord.word) {
									this.currIdx = i;
									this.currWord = this.words[this.currIdx];
									this.ready = true;
									this.editMode = false;
									break;
								}
							}
							this.wordLessons = [];
							this.ready = true;
						},
						error => this.errorMessage = <any>error		
					);
				}
			);		
		// update words that already exist
		} else {
			this.spellingWordService.updateWord(this.tempWord).subscribe(
				() => {
					this.words[this.currIdx] = Object.assign({}, this.tempWord);
					this.currWord = this.words[this.currIdx];
				}
			);	
		}
	}

	cancel() {
		this.editMode = false;
	}

	newWord() {
		this.ready = true;
		this.editMode = true;
		this.tempWord = {
			id : null,
			word : '',
			cloze1 : '',
			cloze2 : '',
			imageUrl : '',
			audioWordUrl : '',
			audioClozeUrl : '',
			audioSpellUrl : '', 
			badSpelling1 : '',
			badSpelling2 : '', 
			badSpelling3 : '', 
			avoid : ''
		}
	}

	listen(idx) {
	console.log(this.currWord.audioWordUrl);
		this.audioElement.setAttribute('src',this.currWord.audioWordUrl);
		this.audioElement.play();
	}

	listenSpelling(idx) {		
		this.audioElement.setAttribute('src',this.currWord.audioSpellUrl);
		this.audioElement.play();
	}

	listenSentence(idx) {
		this.audioElement.setAttribute('src',this.currWord.audioClozeUrl);
		this.audioElement.play();
	}
}
