<bs-modal id="personUpdated" [animation]="animation" [keyboard]="keyboard" [backdrop]="false" #personUpdated >
	<bs-modal-body>Updated.</bs-modal-body>
</bs-modal>
<div class="adminstyle">
	<div class="panel panel-primary">
		<div class="panel-heading">
			Admins and Teachers
		</div>
		<div class="panel-body">
			<div class="row">
				<button id="newLesson" class="col-md-offset-5 btn btn-primary" [routerLink]="[0]">New Person</button>
			</div>

			<div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>
			
			<div class="table-responsive">
				<table class="table" *ngIf="people && people.length">
					<thead>
						<tr>
							<th></th>
							<th></th>
							<th>Username</th>
							<th>Name</th>
							<th>Role</th>
							<th>Ever Logged In</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let p of people">
							<td><a class="btn btn-primary" [routerLink]="[p.personId]">Edit</a></td> 
							<td><a class="btn btn-primary" (click)="deactivate(p)">Deactivate</a></td>
							<td>{{p.userName}}</td>
							<td>{{p.firstName}} {{p.lastName}}</td>
							<td>{{p.userRole}}</td>
							<td>{{yesNo(p.confirmed)}}</td>
						</tr>
					</tbody>
				</table>
			</div>

		</div>
	</div>

</div>