import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

@Injectable()
export class TeacherGuardService implements CanActivate {
	teacherId: string;
	isAdmin : boolean;

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean { 
		if (isNaN(Number(this.teacherId))) {
			return false;
		} else {
			return true;
		}
	}

	setTeacherId (result : any) : string {
		var tId : string = result.teacherId;
		var role : string = result.userRole;
		if (isNaN(Number(tId))) {
			console.log('unrecognized teacher id ' + tId);
		} else {
			this.teacherId = tId;
			this.isAdmin = result.userRole == 'admin'? true : false;
		}
		return this.teacherId;
	}

	isMine(ownerId : string) {
		if (ownerId == this.teacherId || this.isAdmin) {
			return true;
		} else {
			return false;
		}
	}

}
