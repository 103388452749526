import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortDictSetComponent } from './short-dict-set.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';

@NgModule({
  imports: [
	CommonModule,
	CoreModule,
	FormsModule,
    RouterModule.forChild([
      { path: 'TCHDictation/:lesson', component: ShortDictSetComponent }
    ])  
  ],
  declarations: [ShortDictSetComponent]
})
export class DictationModule { }
