import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { LessonService } from '../core/lesson.service';
import { ILesson } from '../core/lesson';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class LessonResolverService implements Resolve<ILesson[]> {

	constructor (private lessonService : LessonService, private router: Router) {}

  	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<ILesson[]> {
		return this.lessonService.getAdminLessons(route.data['app']);
  	}

}
