<nav class="navbar-default" >
    <div style="width: 100%;" class="navbar-header">
        <a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
        <div class="pull-right dropdown" style="padding-top: 6px; padding-right: 10px;">
            <button style="margin-top: -3px; font-size: 50%;" class="visible-xs-inline visible-sm-inline" type="button" id="dropdownMenu1" data-toggle="dropdown" 
                       aria-haspopup="true" aria-expanded="true">
                <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
            </button>
            <button class="btn btn-default dropdown-toggle visible-md-inline visible-lg-inline" type="button" id="dropdownMenu1" data-toggle="dropdown" 
                        aria-haspopup="true" aria-expanded="true">Menu
                <span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">			
                <li><a [routerLink]="['type-word-cloze']">1-Type Word (Cloze)</a></li>
                <li><a [routerLink]="['match-picture']">2-Match Picture</a></li>
                <li><a [routerLink]="['choose-spelling']">3-Choose Spelling</a></li>
                <li><a [routerLink]="['scrambled-word']">4-Scrambled Word</a></li>
                <li><a [routerLink]="['match-sound']">5-Match Sound</a></li>
                <li><a [routerLink]="['type-word-only']">6-Type Word Only</a></li>
                <li><a [routerLink]="['fill-in-word']">7-Fill in Word</a></li>
                <li><a [routerLink]="['word-list']">Word List</a></li>
            </ul>
        </div>
    </div>
</nav>
<div class="row">
    <div style="text-align: center; padding-top: 6px;">{{lessonName}}</div>
</div>
<div>
	<router-outlet></router-outlet>
</div>
