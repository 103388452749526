import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ILepItem } from './lep-item';

@Injectable()
export class LepService {
	private subdomain : string;
	
	constructor(private _http: HttpClient) {
	  if (window.location.hostname != 'localhost') {
		this.subdomain = window.location.hostname.split('.')[0];
	  }
	}

	getLesson(lessonId): Observable<ILepItem[]> {
		if (this.subdomain == null) {
			return Observable.of ([
			  {itemId : 1, lessonId: 3, position :1, icon: './assets/PDFIcon.png', link: 'https://www.google.com', description: 'Google' },
			  {itemId : 2, lessonId: 3, position :2, icon: './assets/TCHCircle.png', link: 'https://www.google.com', description: 'TCH Spelling' }
			]);
		} else {
			let webServiceUrl = 'webservices/services/getLEPLesson.php?lessonId=' + encodeURIComponent(lessonId);
			return this._http.get<ILepItem[]>(webServiceUrl)
			.catch(this.handleError);
		}
	}

	addItem(item: ILepItem) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/addLEPItem.php', encodedData);
	}

	updateItem(item : ILepItem) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/updateLEPItem.php', encodedData);
	}

	deleteItem(itemId : number) {
		var encodedData = JSON.stringify(itemId);
console.log('encoded data=' + encodedData);		
		return this._http.post('webservices/services/deleteLEPItem.php', encodedData);
	}

	private handleError(err: HttpErrorResponse) {
		console.log(err.message);
		return Observable.throw(err.message);
	}
}
