import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula/ng2-dragula';
import { TypeWordClozeComponent } from './type-word-cloze.component';
import { MatchPictureComponent } from './match-picture.component';
import { ChooseSpellingComponent } from './choose-spelling.component';
import { ScrambledWordComponent } from './scrambled-word.component';
import { MatchSoundComponent } from './match-sound.component';
import { TypeWordOnlyComponent } from './type-word-only.component';
import { WordListComponent } from './word-list.component';
import { RouterModule } from '@angular/router';
import { SpellingComponent } from './spelling.component';
import { FormsModule } from '@angular/forms';
import { AllDoneComponent } from './all-done.component';
import { StartUpComponent } from './start-up.component';
import { CoreModule } from '../core/core.module';
import { FillInWordComponent } from './fill-in-word.component';

@NgModule({
  imports: [
	CommonModule,
	CoreModule,
	FormsModule,
    RouterModule.forChild([
      { path: 'TCHSpelling/:lesson', 
        component: SpellingComponent,
        children: [
			{ path: '', component: StartUpComponent },
        	{ path: 'type-word-cloze', component: TypeWordClozeComponent },
        	{ path: 'type-word-cloze/repeat', component: TypeWordClozeComponent },
    		{ path: 'match-picture', component: MatchPictureComponent },
    		{ path: 'match-picture/repeat', component: MatchPictureComponent },
        	{ path: 'choose-spelling', component: ChooseSpellingComponent },
        	{ path: 'choose-spelling/repeat', component: ChooseSpellingComponent },
        	{ path: 'scrambled-word', component: ScrambledWordComponent },
        	{ path: 'scrambled-word/repeat', component: ScrambledWordComponent },
        	{ path: 'match-sound', component: MatchSoundComponent },
        	{ path: 'match-sound/repeat', component: MatchSoundComponent },
        	{ path: 'type-word-only', component: TypeWordOnlyComponent },
        	{ path: 'type-word-only/repeat', component: TypeWordOnlyComponent },
        	{ path: 'fill-in-word', component: FillInWordComponent },
        	{ path: 'fill-in-word/repeat', component: FillInWordComponent },
        	{ path: 'word-list', component: WordListComponent },
        	{ path: 'all-done', component: AllDoneComponent }
        ]
       },
    ]),
    DragulaModule       
  ],
  declarations: [
    SpellingComponent,
    TypeWordClozeComponent,
    MatchPictureComponent,
    ChooseSpellingComponent,
    ScrambledWordComponent,
    MatchSoundComponent,
    TypeWordOnlyComponent,
    WordListComponent,
	AllDoneComponent,
	StartUpComponent,
	FillInWordComponent
  ],
})
export class SpellingModule {


 }
