import { Component, OnInit } from '@angular/core';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { LessonService } from '../core/lesson.service';
import { ILesson } from '../core/lesson';
import { TagService } from '../core/tag.service';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';


@Component({
  selector: 'tch-spelling-lessons',
  templateUrl: './spelling-lessons.component.html',
  styleUrls: ['./spelling-lessons.component.css']
})
export class SpellingLessonsComponent implements OnInit {
	app : string;
	allWords : ISpellingWord[];  // holds the words most recently retrived, whether for the whole database or a single lesson
	lessons: ILesson[];
	errorMessage : string;
	currCandidate : number = -1;
	currSelected : number = -1;
	currLesson : ILesson;
	showAddNew : boolean;
	showOneLesson : boolean;
	candidates : ISpellingWord[];
	selected : ISpellingWord[] = [];
	newLessonName : string;
	hideCueWord : boolean;
	tags : string = '';
	lessonTags : string[];
	
	constructor(private spellingWordService : SpellingWordService, private lessonService : LessonService, private tagService : TagService,
		private route: ActivatedRoute) { 
		}

	ngOnInit() {	
		this.app = this.route.snapshot.data['app'];		
		this.route.data.subscribe(
			data => {this.candidates = data['wordList'];
					this.lessons = data['lessonList'];
			}
		)
	}

	selectLesson() {
		this.currCandidate = -1;
		this.currSelected = -1;
		// reset currLesson, because it loses the active flag
		for (var x=0;x<this.lessons.length;x++) {
			if (this.currLesson.id == this.lessons[x].id) {
				this.currLesson.active = this.lessons[x].active;
				this.hideCueWord = this.lessons[x].hideWord == 1 ? true : false;	
				break;
			}				
		}
		// remove everything that is currently on the selected list back to the candidate list
		for (var i=this.selected.length-1;i>=0;i--)
			this.moveWord (this.selected[i], this.selected, this.candidates);
		if (this.currLesson.id == -1) {
			this.showAddNew = true;
			this.showOneLesson = false;
		} else {	
			// get the lesson words from the database and add them to the selected list, removing them from the candidate list
			this.spellingWordService.getWords(this.currLesson.id).subscribe(
				words => this.allWords = words,
				error => this.errorMessage = <any>error,				
				() => {
					for (var i=0;i<this.allWords.length;i++) {
						this.moveWord (this.allWords[i], this.candidates, this.selected);
					}
					this.showAddNew = false; 
					this.showOneLesson = true;		
				}
			);
			this.tagService.getTags(this.currLesson.id).subscribe(
				tags => {this.lessonTags = tags},
				error => this.errorMessage = <any>error,
				() => {this.tags = this.lessonTags.join(','); }
			);
		}
	}

	moveWord(lessonWord : ISpellingWord, fromList : ISpellingWord[], toList : ISpellingWord[]) {
		// add the word to the toList
		if (toList.length == 0) {
			toList.push(lessonWord);
		} else {
			var inserted = false;
			for (var i=0;i<toList.length;i++) {
				if (toList[i].word > lessonWord.word) {
					toList.splice(i, 0, lessonWord);
					inserted = true;
					break;
				}
			}
			if (!inserted) toList.push(lessonWord);
		}
		// remove the word from the fromList
		if (fromList.length > 0) {
			for (var i=fromList.length-1;i>=0;i--) {
				if (fromList[i].id == lessonWord.id) fromList.splice(i, 1);
			}
		}
	}

	selectCandidate(idx : number) {
		this.currCandidate = idx;
		this.currSelected = -1;
	}

	selectSelected(idx : number) {
		this.currSelected = idx;
		this.currCandidate = -1;
	}

	createNewLesson() {
		this.showAddNew = false;
		this.currCandidate = -1;
		this.currSelected = -1;
		// initialize the selected list in prepration for this new lesson
		for (var i=this.selected.length-1;i>=0;i--) {
			this.moveWord (this.selected[i], this.selected, this.candidates);
		}
		// make the new lesson
		this.lessonService.addLesson(this.app, this.newLessonName).subscribe(
			data => {'back from adding lesson' + this.newLessonName},
			error => {this.errorMessage = <any>error;
						console.log(this.errorMessage)},
			() => {
				// requery the database and rebuild the lesson list
				this.lessonService.getAdminLessons(+this.app).subscribe(
					lessons => {
						this.lessons = lessons;
						// set the new lesson as the current lesson -- it will be the one with the highest id
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (+this.lessons[x].id > +this.currLesson.id) {
								this.currLesson = this.lessons[x];
							}
						}
						this.showOneLesson = true;
					},
					error => this.errorMessage = <any>error
				);
			}
		);
	}

	updateLesson() {		
		this.currLesson.hideWord = this.hideCueWord ? 1 : 0;
		this.lessonService.updateLesson(this.currLesson).subscribe(
			() => {
				this.lessonService.getAdminLessons(+this.app).subscribe (
					data => {this.lessons = data;
					},
					error => this.errorMessage = <any>error,
					() => {
						// reselect the lesson
						let cId = this.currLesson.id;
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (this.lessons[x].id == cId) {
								this.currLesson = this.lessons[x];
							}
						}
					}
				);
			}
		);
	}

	addWordToLesson() {
		this.spellingWordService.addLessonWord(this.currLesson.id, this.candidates[this.currCandidate].id).subscribe(
			() => {
				this.moveWord (this.candidates[this.currCandidate], this.candidates, this.selected);
				this.currCandidate = -1;
				this.currSelected = -1;
			}
		);

	}

	removeWordFromLesson() {
		this.spellingWordService.deleteLessonWord(this.currLesson.id, this.selected[this.currSelected].id).subscribe(
			() => {
				this.moveWord (this.selected[this.currSelected], this.selected, this.candidates);
				this.currCandidate = -1;
				this.currSelected = -1;
			}
		);
	}

	saveTags() {
		let tagList : string[] = this.tags.split(",").map((item: string) => item.trim());
		this.tagService.replaceTags(this.currLesson.id, tagList).subscribe(
			data => {},
			error => {this.errorMessage = <any>error; console.log(this.errorMessage)},	
			() =>{console.log('back from tag service');}
		);
	}

}

