import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IPerson } from './person';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class PersonService {
	private _webServiceUrl : string;
	private subdomain : string;
  
	constructor(private _http: HttpClient) { 
		if (window.location.hostname != 'localhost') {
			this.subdomain = window.location.hostname.split('.')[0];
		}		
	}

	getAllPeople() : Observable<IPerson[]> {
		if (this.subdomain == null) {
				return Observable.of ([
					{id: 18, userName:'Somebody@somewhere.com', userRole: 'teacher', firstName: 'Jane', lastName: 'Doe', confirmed: 0}
				]);
		} else {
			let webServiceUrl = 'webservices/services/getAllPeople.php';
			return this._http.get<IPerson[]>(webServiceUrl)
			.catch(this.handleError);
		}
	}

	getPerson(personId): Observable<IPerson> {   
		if (this.subdomain == null) {
			return Observable.of (
				{id: 18, userName:'Somebody@somewhere.com', userRole: 'teacher', firstName: 'Jane', lastName: 'Doe', confirmed: 0}
			);
	} else {
		  this._webServiceUrl = 'webservices/services/getPerson.php?personId=' + encodeURIComponent(personId);
		  return this._http.get<IPerson>(this._webServiceUrl)
		  .catch(this.handleError);
	   }
	  }

	updatePerson(user : IPerson) {
		var encodedData = JSON.stringify(user);
		return this._http.post('webservices/services/updatePerson.php', encodedData);
	}
	
	addNewPerson(user : IPerson) {
		var encodedData = JSON.stringify(user);
		return this._http.post('webservices/services/addNewPerson.php', encodedData);
	}

	deactivatePerson(user : IPerson) {
		var encodedData = JSON.stringify(user);
		return this._http.post('webservices/services/deactivatePerson.php', encodedData);
	}	

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}
}
