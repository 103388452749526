<div class="wrapper" *ngIf="atEnd == false && words.length > 0" class="{{sliderClass}}">
	<div class="row pull-right">
		<p class="myprogress">{{currWord + 1}} of {{words.length}}</p>
	</div>
	<div style="text-align: center;">
		<div class="content" style="margin-bottom: 30px;">
			<div class="row-fluid" >
				<img src="{{word.imageUrl}}" />
				<img class="visible-xs-inline" src="./assets/listenimg.jpg" (click)="listen(i)" />
			</div>
		</div>
		<div class="hidden-xs">
			<div class="content" style="padding-bottom: 30px;">
				<div class='row-fluid'>
					<div class="btn btn-sm-droppable fromCont container" *ngFor="let item of source; let i = index" attr.idx={{i}} [dragula]='"dropTarget"'>
						<div class="btn btn-default btn-sm-draggable darkerbck" *ngIf="item.letter" attr.draggable={{item.drag}} innerHtml="{{item.letter}}" ></div>
					</div>
				</div>
			</div>
			<div class="content" style="padding-bottom: 30px;">
				<div class="row-fluid">
					<div class="btn btn-sm-droppable toCont container" *ngFor="let item of target; let i = index" [class.btn-sm-fixed]="!item.drag"  attr.idx={{i}} [dragula]='"dropTarget"' >
						<div class="btn btn-default btn-sm-draggable darkerbck" *ngIf="item.letter"  attr.draggable={{item.drag}} innerHtml="{{item.letter}}"  ></div>
					</div>
				</div>
			</div>
			<div class="row">
				<img src="./assets/listenimg.jpg" (click)="listen(i)" />
			</div>
		</div>
		<div class="visible-xs-block">
			<div class="content" style="padding-bottom: 30px;">
				<div class='row-fluid'>
					<div class="btn btn-xs-droppable fromCont container" *ngFor="let item of source; let i = index" attr.idx={{i}} [dragula]='"dropTarget"'>
						<div class="btn btn-default btn-xs-draggable darkerbck" *ngIf="item.letter" attr.draggable={{item.drag}} innerHtml="{{item.letter}}" ></div>
					</div>
				</div>
			</div>
			<div class="content" style="padding-bottom: 30px;">
				<div class="row-fluid">
					<div class="btn btn-xs-droppable toCont container" *ngFor="let item of target; let i = index" [class.btn-sm-fixed]="!item.drag"  attr.idx={{i}} [dragula]='"dropTarget"' >
						<div class="btn btn-default btn-xs-draggable darkerbck" *ngIf="item.letter"  attr.draggable={{item.drag}} innerHtml="{{item.letter}}"  ></div>
					</div>
				</div>
			</div>
		</div>		
	</div>
</div>
<div class="row container"*ngIf="atEnd == true">
	<div class="pager">
		<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
		<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
	</div>   
	<div class="col-xs-6 col-md-2">
		<img src="./images/smiley.jpg" />
	</div>
</div>	
		
					