<nav class="navbar-default" >
    <div class="navbar-header">
        <a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
	</div>
    <div class="pull-right" style="padding-top: 6px; padding-right: 10px;">{{lessonName}}</div>
</nav>
<div class="input-group col-md-offset-5" style="margin-top: 10px" *ngIf="atStart">
	<button class="btn btn-default" (click)="startLesson()">Start</button>
</div>
<div class="col-md-offset-6" *ngIf="showNext" >
	<input id="nextButton" autofocus type="button" class="btn btn-primary choosebtn" value="Next >" (click)="nextSet()"/>
</div>
<div class="container" style="margin-top: 10px" *ngIf="pageItems">
	<form #form="ngForm" >
		<div *ngFor="let item of pageItems; let i = index">
			<div class="form-group col-md-12">
				<div class="col-md-1"><img *ngIf="item.status" src="{{ item.status }}" /></div>
				<div id="dict{{item.dictation.position}}" class="col-md-6" spellcheck="false" contenteditable (click)="clearAnswer(i)"
					autocapitalize="none" autocomplete="off" style="border: 1px solid black; padding: .25em; white-space: pre-wrap;">{{ answer }}</div>
				<div class="col-md-5">
					<img src="./assets/listenimg.jpg" (click)="listen(i)" />
					<input type="button" class="btn btn-primary" value="Check" (click)="check(i)"/>
					<img src="./assets/listenimgslow.jpg" (click)="listenSlow(i)" />
					<input *ngIf="item.showShowButton" type="button" class="btn btn-primary" value="Show" (click)="show(i)"/>
				</div>
				<div class="form-group col-md-12">
					<p class="col-md-1"></p>
					<p class="col-md-7">{{ item.showText }}</p>
				</div>
			</div>			
		</div>
	</form>
</div>
<div class="col-md-offset-6" *ngIf="showNext" >
	<input type="button" class="btn btn-primary choosebtn" value="Next >" (click)="nextSet()"/>
</div>
<div class="row container" *ngIf="atEnd == true">
	<div class="col-xs-6 col-md-2">
		<img src="./images/smiley.jpg" />
	</div>	
</div>	