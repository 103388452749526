<div class="wrapper" *ngIf="atEnd == false && words.length > 0" class="{{sliderClass}}">
	<div class="hidden-xs">
		<div class="row pull-right">
			<p class="myprogress">word {{currWord + 1}} of {{words.length}}</p>
		</div>
		<div class="row" style="padding-top: 2em;">
			<ul class="col-md-offset-1 btn-group-vertical" >
				<li *ngFor='let choice of choices; let i = index' class="btn choosebtn xlgfont btn-default {{border[i]}}" 
							(click)="wordClick(i)">
					<span class="{{textColor[i]}}">{{choice}}</span>
				</li>
			</ul>
			<img class="col-md-offset-1 noselect" draggable="false" src="{{words[currWord].imageUrl}}" />
		</div>
	</div>
	<div class="visible-xs-block">
		<div class="row" style="margin-top: 6px;">
			<p class="myprogress pull-right">word {{currWord + 1}} of {{words.length}}</p>
		</div>
		<div class="row" style="text-align: center;">
			<img class="img-reponsive noselect" draggable="false" src="{{words[currWord].imageUrl}}" />
		</div>
		<div class="row" style="padding-top: 1em; text-align: center;">
			<ul>
				<li *ngFor='let choice of choices; let i = index' class="btn btn-default {{border[i]}}" (click)="wordClick(i)">
					<span class="{{textColor[i]}}">{{choice}}</span>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="row container"*ngIf="atEnd == true" >
	<div class="hidden-md">
		<div class="pager">
			<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
			<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
		</div>
	</div>
	<div class="col-md-4 col-md-offset-1">
		<p><b><u>Correct</u></b></p>
		<ul class="nobullets">
			<li *ngFor='let corr of correct'>{{corr}}</li>
		</ul>
		<p><b><u>Incorrect</u></b></p>
		<ul class="nobullets">
			<li *ngFor='let incorr of incorrect'>{{incorr}}</li>
		</ul>
	</div>
	<div class="visible-md-block col-md-6">
		<div class="pager">
			<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
			<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
		</div>
		<div class="row">
			<img src="./images/smiley.jpg" />
		</div>
	</div>
</div>    
