<nav class="navbar-default" >
    <div class="navbar-header">
        <a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
	</div>
    <div class="pull-right" style="padding-top: 6px; padding-right: 10px;">{{lessonName}}</div>
</nav>
<div class="row" style="margin-top: 30px;">
	<router-outlet></router-outlet>
</div>
<div class="container">
	<div class="container-fluid col-md-offset-1">
		<table>
			<tr *ngFor="let l of items">
				<td><img style="width: 30px; margin-right: 15px;" src="{{l.icon}}" /></td>
				<td><a href href="{{l.link}}" target="_blank"><h4>{{l.description}}</h4></a></td>
			</tr>
		</table>
	</div>
</div>
	
	