import { Component, OnInit, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { LoginService } from '../core/login.service';
import { TeacherGuardService } from './teacher-guard.service';
import { Router } from '@angular/router';
declare const gapi: any;

@Component({
	selector: 'tch-teacher-login',
	templateUrl: './teacher-login.component.html',
	styleUrls: ['./teacher-login.component.css']
  })
export class TeacherLoginComponent implements OnInit {
	public auth2: any;
	teacherId : string;
	errorMessage : string;
  
  constructor(private teacherGuard: TeacherGuardService, private loginService: LoginService, 
				private router: Router, private ngZone : NgZone) { 
	}

	ngOnInit() {
	}

	public googleInit() {
		let that = this;
		gapi.load('auth2', () => {
			that.auth2 = gapi.auth2.init({
				client_id: '302970599785-j3rrkc104f9mh12jfecvpvdpeql8trud.apps.googleusercontent.com',
				cookiepolicy: 'single_host_origin',
				scope: 'profile email'
			});
			that.attachSignin(document.getElementById('googleBtn'));
		});
	}
	public attachSignin(element) {
		let that = this;
		this.auth2.attachClickHandler(element, {prompt : 'select_account'}, function (googleUser) {
			let profile = googleUser.getBasicProfile();
			that.loginService.confirmTeacherAccess(googleUser.getAuthResponse().id_token).subscribe(
				data => {
				// important!! otherwise the view gets disconnected from the code!!
				that.ngZone.run(() => {	that.teacherId = that.teacherGuard.setTeacherId(data);
										that.router.navigate(['teacher/teacher-menu']);
					});
				},
				error => that.errorMessage = <any>error,
				() => {if (that.errorMessage)console.log('error =' + that.errorMessage);}
			);          
			}, function (error) {
				console.log(error);  // this is what happens if they close the sign-in pop-up
			});
		}

	ngAfterViewInit(){
		this.googleInit();
	}
}
