<div class="panel panel-primary">
	<bs-modal [animation]="animation" [keyboard]="keyboard" [backdrop]="backdrop" #confirmDelete  >
		<bs-modal-body>Delete {{plan?.lessonPlanName}}?</bs-modal-body>
		<bs-modal-footer>
			<button autofocus type="button" class="btn btn-primary" (click)="deletePlan()">Delete</button>
			<button autofocus type="button" class="btn btn-primary" (click)="oops()">Cancel</button>
		</bs-modal-footer>
	</bs-modal>
	<div class="panel-heading" *ngIf="plan">
		<a [routerLink]="['/teacher/plans']" style="color: white;"><-Back to List</a>
		<div class="pull-right">Lesson Plan: {{plan.lessonPlanName}}</div>
	</div>
	
	<div class="panel-body" >
		<div *ngIf="plan && isMine()">
			<a class="btn btn-primary" [routerLink]="['edit']">Edit</a>
			<a class="btn btn-primary" (click)="setUpDelete()">Delete</a>
			<a *ngIf="plan.visibleToStudents == true" class="btn btn-primary" (click)="hidePlan()">Hide from Students</a>			
			<a *ngIf="plan.visibleToStudents != true" class="btn btn-primary" (click)="showPlan()">Show to Students</a>			
		</div>
	
		<div *ngIf="plan && !isMine()">
			<a class="btn btn-primary" (click)="cloneLesson()">Clone</a>
		</div>

		<div class="table-responsive">
			<table class="table" *ngIf="planItems && planItems.length">
				<thead>
					<tr>
						<th>Item</th>
						<th>Description</th>
						<th>Url</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of planItems; let i = index">
						<td>{{i+1}}</td>
						<td>{{item.description}}</td>
						<td>{{item.url}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>

</div>