import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillBlankComponent } from './fill-blank.component';
import { RouterModule } from '@angular/router';
import { BsModalModule  } from 'ng2-bs3-modal';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';

@NgModule({
  imports: [
    CommonModule,
	CoreModule,
	FormsModule,
    RouterModule.forChild([
      { path: 'TCHGrammar/:lesson', component: FillBlankComponent }
	]),
	BsModalModule    
  ],
  declarations: [	
	FillBlankComponent]
})
export class GrammarModule { }
