import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { LessonService } from '../core/lesson.service';
import { ILesson } from '../core/lesson';
import { IGrammarItem } from '../core/grammar-item';
import { GrammarLessonService } from '../core/grammar-lesson.service';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { FormsModule, NgForm } from '@angular/forms';
import { BsModalComponent, BsModalService  } from 'ng2-bs3-modal';

@Component({
  selector: 'tch-grammar-lessons',
  templateUrl: './grammar-lessons.component.html',
  styleUrls: ['./grammar-lessons.component.css'],
  encapsulation: ViewEncapsulation.None  
})
export class GrammarLessonsComponent implements OnInit {
	app : string;
	lessons: ILesson[];
	errorMessage : string;
	currLesson : ILesson;
	showAddNew : boolean;
	showOneLesson : boolean;
	newLessonName : string;
	showAddNewItem : boolean = false;
	items: IGrammarItem[] = [];
	@ViewChild('grammar') grammar: NgForm;	
	@ViewChild('confirmDelete') confirmDelete: BsModalComponent;
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;
	deleteIdx : number;
	lastItemId : number = 0;

	constructor(private lessonService : LessonService, private route: ActivatedRoute, 
				private grammarLessonService: GrammarLessonService) { 
		}

	ngOnInit() {
		this.app = this.route.snapshot.data['app'];		
		this.route.data.subscribe(
			data => this.lessons = data['lessonList']
		);
	}

	save(idx : number) {
		if (this.items[idx].cue != null && this.items[idx].answer1 != null && this.items[idx].sentenceB != null) {
			// insert
			if (this.items[idx].lessonId == null) {
				this.items[idx].lessonId = this.currLesson.id;
				this.items[idx].sectionId = 5;
				this.grammarLessonService.addItem(this.items[idx]).subscribe(
					data => this.items[idx].dirty = false,
					error => {console.log(error)},
					() => {this.lastItemId = this.items[idx].itemId;
							this.showAddNewItem = true;
						}
				);
			// update
			} else {
				this.grammarLessonService.updateItem(this.items[idx]).subscribe(
					data => this.items[idx].dirty = false,
					error => console.log('error query:' + error),
					() => {this.showAddNewItem = true;}
				);
			}
		}
	}

	makeDirty(idx) {
		this.items[idx].dirty = true;
	}

	isDirty(idx) {
		return (this.items[idx].dirty);
	}

	selectLesson() {
		// reset currLesson, because it loses the active flag
		for (var x=0;x<this.lessons.length;x++) {
			if (this.currLesson.id == this.lessons[x].id) {
				this.currLesson.active = this.lessons[x].active;	
				break;
			}				
		}
		if (this.currLesson.id == -1) {
			this.showAddNew = true;
			this.showOneLesson = false;
		} else {	
			// get the lesson items from the database and add them to page
			this.grammarLessonService.getGrammarLesson(this.currLesson.id).subscribe(
				items => this.items = items,
				error => this.errorMessage = <any>error,				
				() => {
					this.showAddNew = false; 
					this.showOneLesson = true;
					this.showAddNewItem = true;
					if (this.items.length > 0) {
						this.lastItemId = this.items[this.items.length-1].itemId;
					}
					for (var i=0;i<this.items.length;i++) {
						this.items[i].dirty = false;
					}
				}
			);			
		}
	}

	createNewLesson() {
		this.showAddNew = false;
		// make the new lesson
		this.lessonService.addLesson(this.app, this.newLessonName).subscribe(
			data => {'back from adding lesson' + this.newLessonName},
			error => {this.errorMessage = <any>error;
						console.log(this.errorMessage)},
			() => {
				// requery the database and rebuild the lesson list
				this.lessonService.getAdminLessons(+this.app).subscribe(
					lessons => {
						this.lessons = lessons;
						// set the new lesson as the current lesson -- it will be the one with the highest id
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (+this.lessons[x].id > +this.currLesson.id) {
								this.currLesson = this.lessons[x];
							}
						}
						this.showOneLesson = true;
						this.lastItemId = 0;
						this.showAddNewItem = true;
					},
					error => this.errorMessage = <any>error
				);
			}
		);
	}

	updateLesson() {
		this.lessonService.updateLesson(this.currLesson).subscribe(
			() => {
				this.lessonService.getAdminLessons(+this.app).subscribe (
					data => {this.lessons = data;
					},
					error => this.errorMessage = <any>error,
					() => {
						// reselect the lesson
						let cId = this.currLesson.id;
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (this.lessons[x].id == cId) {
								this.currLesson = this.lessons[x];
							}
						}
					}
				);
			}
		);
	}
	
	oops() {
		this.confirmDelete.close();
	}

	deleteItem() {
		this.confirmDelete.close();
		if (this.items[this.deleteIdx].lessonId == null) {
			if (this.items.length == 1) {
				this.items = [];
				this.lastItemId = null;
			} else {
				this.items.splice(this.deleteIdx,1);
				if (this.items.length > 0) {
					this.lastItemId = this.items[this.items.length-1].itemId;
				}
			}
		} else {
			this.grammarLessonService.deleteItem(this.items[this.deleteIdx]).subscribe(
				data => {console.log(data)},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)},
				() => {this.items.splice(this.deleteIdx,1);
						if (this.items.length > 0) {
							this.lastItemId = this.items[this.items.length-1].itemId;
						}
				}
			);				
		}
	}

	addItem() {
		// add all new items in the 5th block, beginning with 51
		let nextItemId = 0;
		if (this.items.length == 0 || this.lastItemId == null || this.lastItemId < 51) {
			nextItemId = 51;
		} else {
			nextItemId = +this.lastItemId + 1;
		}
		this.items.push ({	
			lessonId : null,
			sectionId : 5,
			itemId : nextItemId,
			cue : null,
			sentenceA: null,
			sentenceB: null,
			sentenceC: null,
			answer1: null,
			answer2: null,
			imageUrl: null
		});
		this.showAddNewItem = false;		
	}


	setUpDelete(idx : number) {
		this.deleteIdx = idx;
		this.confirmDelete.open();
	}

	private fixItemIds() {
		// fix the item numbers from the 10*4 old system
		for (var i=0;i<this.items.length;i++) {
			if (this.items[i].sectionId < 5) {
				this.items[i].itemId = (+this.items[i].sectionId*10) + +this.items[i].itemId;
			}
		}
	}
}
