import { Component, OnInit } from '@angular/core';
import { ILepItem } from '../core/lep-item';
import { LepService } from '../core/lep.service';
import { ActivatedRoute } from '@angular/router';
import { LessonService } from '../core/lesson.service';

@Component({
  selector: 'tch-lesson-resources',
  templateUrl: './lesson-resources.component.html',
  styleUrls: ['./lesson-resources.component.css']
})
export class LessonResourcesComponent implements OnInit {
	lesson : number;
	lessonName : string;
	items : ILepItem[] = [];
	errorMessage: string;
	
	constructor(private lepService : LepService, private route: ActivatedRoute,
		private lessonService : LessonService) { 
  }

  ngOnInit() {
	this.lesson = +this.route.snapshot.params['lesson'];
	this.lessonService.getLesson(this.lesson).subscribe(
		result => this.lessonName = result.name
	);  
	this.lepService.getLesson(this.lesson).subscribe (
		data => this.items = data,
		error => this.errorMessage = <any>error
	);

}

}
