import { Component, OnInit, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { LoginService } from '../core/login.service';
import { AdminGuardService } from './admin-guard.service';
declare const gapi: any;

@Component({
  selector: 'tch-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public auth2: any;
  teacherData : any;
  teacherId : string;
  errorMessage : string;
  private subdomain : string;  
  option : string;

  constructor(private element: ElementRef, private loginService: LoginService, private adminGuard: AdminGuardService, private ngZone : NgZone) {
    if (window.location.hostname != 'localhost') {
		this.subdomain = window.location.hostname.split('.')[0];
	}
  }

  ngOnInit() {

}

  public googleInit() {
	let that = this;
	gapi.load('auth2', () => {
		that.auth2 = gapi.auth2.init({
			client_id: '302970599785-j3rrkc104f9mh12jfecvpvdpeql8trud.apps.googleusercontent.com',
			cookiepolicy: 'single_host_origin',
			scope: 'profile email'
		});
		that.attachSignin(document.getElementById('googleBtn'));
	});
  }

  public attachSignin(element) {
	let that = this;
	this.auth2.attachClickHandler(element, {prompt : 'select_account'}, function (googleUser) {
		let profile = googleUser.getBasicProfile();
		that.loginService.confirmAccess(googleUser.getAuthResponse().id_token).subscribe(
			data => {
				// important!! otherwise the view gets disconnected from the code!!
				that.ngZone.run(() => {that.teacherId = that.adminGuard.setTeacherId(data.result);});
				},
			error => that.errorMessage = <any>error
		);          
	}, function (error) {
		console.log(error);  // this is what happens if they close the sign-in pop-up
	});
  }

	ngAfterViewInit(){
		this.googleInit();
	}
}
