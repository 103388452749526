<bs-modal id="nowVisible" [animation]="animation" [keyboard]="keyboard" [backdrop]="false" #nowVisible >
	<bs-modal-body>Lesson plan is now visible to students under your name on the home page.</bs-modal-body>
</bs-modal>	
<bs-modal id="nowHidden" [animation]="animation" [keyboard]="keyboard" [backdrop]="false" #nowHidden >
	<bs-modal-body>Lesson plan is no longer visible to students.</bs-modal-body>
</bs-modal>	
<div *ngIf="!isAdmin()" class="panel panel-primary">
	<div class="panel-heading">
		<a [routerLink]="['/teacher/teacher-menu']" style="color: white;"><-Back to Teacher Menu</a>
		<div class="pull-right">My Lesson Plans</div>
	</div>
    <div class="panel-body">
		<div class="row">
			<button id="newLesson" class="btn btn-primary" [routerLink]="[0, 'edit']">New Lesson Plan</button>
		</div>

        <div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>
		
        <div class="table-responsive">
			<table class="table" *ngIf="plans && plans.length">
				<thead>
					<tr>
						<th></th>
						<th></th>
						<th>Lesson Plan</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let plan of myPlans">
						<td><a class="btn btn-primary" [routerLink]="[plan.lessonPlanId,'edit']">Edit</a></td>
						<td *ngIf="showShow(plan.ownerId, plan.lessonPlanId)"><a class="btn btn-primary" (click)="showPlan(plan.lessonPlanId)">Show to Students</a></td>
						<td *ngIf="showHide(plan.ownerId, plan.lessonPlanId)"><a class="btn btn-primary" (click)="hidePlan(plan.lessonPlanId)">Hide from Students</a></td>
						<td>{{plan.lessonPlanName}}</td>
					</tr>
				</tbody>
			</table>
		</div>

    </div>
</div>
<div class="panel panel-primary">
	<div class="panel-heading">
		<a [routerLink]="['/teacher/teacher-menu']" style="color: white;"><-Back to Teacher Menu</a>
		<div class="pull-right">All Lesson Plans</div>
	</div>

    <!-- Filter the Lesson Plans   -->
    <div class="panel-body">
		<div *ngIf="isAdmin()" class="row">
			<button id="newLesson" class="btn btn-primary" [routerLink]="[0, 'edit']">New Lesson Plan</button>
		</div>
        <div class="row">
            <div class="col-md-2 text-center">Filter by:</div>
            <div class="col-md-4">
                <input id="filter" type="text" [(ngModel)]="listFilter" autocapitalize="none" autocomplete="off" class="form-control typein" />
            </div>
        </div>
        <div class="row" *ngIf="listFilter">
            <div class="col-md-6">
                <h3>Filtered by: {{listFilter}} </h3>
            </div>
        </div>

        <div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>

        <div *ngIf="isAdmin()" class="table-responsive">
            <table class="table" *ngIf="plans && plans.length">
                <thead>
                    <tr>
						<th></th>
                        <th>Lesson Plan</th>
                        <th>Teacher</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let plan of plans | planFilter:listFilter; let i = index">
                        <td><a class="btn btn-primary" [routerLink]="[plan.lessonPlanId]">View</a></td>
                        <td>{{plan.lessonPlanName}}</td>
                        <td>{{plan.firstName}} {{plan.lastName}}</td>
                    </tr>
				</tbody>
            </table>
		</div>
		
        <div *ngIf="!isAdmin()" class="table-responsive">
				<table class="table" *ngIf="plans && plans.length">
					<thead>
						<tr>
							<th></th>
							<th></th>
							<th>Lesson Plan</th>
							<th>Teacher</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let plan of plans | planFilter:listFilter">
								<td *ngIf="!isMine(plan.ownerId)"><a class="btn btn-primary" [routerLink]="[plan.lessonPlanId]">View</a></td>
								<td *ngIf="isMine(plan.ownerId)"><a class="btn btn-primary" [routerLink]="[plan.lessonPlanId,'edit']">Edit</a></td>
								<td *ngIf="showShow(plan.ownerId, plan.lessonPlanId)"><a class="btn btn-primary" (click)="showPlan(plan.lessonPlanId)">Show to Students</a></td>
								<td *ngIf="showHide(plan.ownerId, plan.lessonPlanId)"><a class="btn btn-primary" (click)="hidePlan(plan.lessonPlanId)">Hide from Students</a></td>
								<td style="text-align: center;" *ngIf="!isMine(plan.ownerId)">{{visibility(plan.lessonPlanId)}}</td> 
								<td>{{plan.lessonPlanName}}</td>
								<td>{{plan.firstName}} {{plan.lastName}}</td>
							</tr>
					</tbody>
				</table>
			</div>
	
    </div>
</div>