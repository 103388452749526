import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ILesson } from './lesson';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class LessonService {
  private _webServiceUrl : string;
  private subdomain : string;
  
  constructor(private _http: HttpClient) {
    if (window.location.hostname != 'localhost') {
      this.subdomain = window.location.hostname.split('.')[0];
    }
  }

  getLesson(lessonId): Observable<ILesson> {   
    if (this.subdomain == null) {
      return Observable.of({id: 1, name: 'Colors And Other Tests', active: 1, hideWord: 0});
    } else {
      this._webServiceUrl = 'webservices/services/getLesson.php?lessonId=' + encodeURIComponent(lessonId);
      return this._http.get<ILesson>(this._webServiceUrl)
      .catch(this.handleError);
   }
  }

  getLessons(app): Observable<ILesson[]> {   
    	if (this.subdomain == null) {
	      return Observable.of([{id: 1, name: 'Colors', active: 1, hideWord: 0}]);
    	} else if (this.subdomain == 'tch') {
	      this._webServiceUrl = 'webservices/services/getActiveLessons.php?appId=' + encodeURIComponent(app);
	    } else {
			this._webServiceUrl = 'webservices/services/getDevLessons.php?appId=' + encodeURIComponent(app);
		}
    	return this._http.get<ILesson[]>(this._webServiceUrl)
	      .catch(this.handleError);
  	}

	getAdminLessons(app): Observable<ILesson[]> {   
    	if (this.subdomain == null) {
	      return Observable.of([{id: 1, name: 'Colors', active: 1, hideWord: 0}]);
	    } else {
			this._webServiceUrl = 'webservices/services/getLessons.php?appId=' + encodeURIComponent(app);
		}
    	return this._http.get<ILesson[]>(this._webServiceUrl)
	      .catch(this.handleError);
	}
	  
	getLessonsForWord(wordId): Observable<string[]> {
		this._webServiceUrl = 'webservices/services/getWordLessons.php?wordId=' + encodeURIComponent(wordId);
    	return this._http.get<string[]>(this._webServiceUrl)
		.catch(this.handleError);
  }

	getLessonsByWord(word): Observable<ILesson[]> {
		this._webServiceUrl = 'webservices/services/getLessonsByWord.php?word=' + encodeURIComponent(word);
		return this._http.get<ILesson[]>(this._webServiceUrl)
		.catch(this.handleError);
	}

	addLesson(app: string, name: string) : Observable<Object> {
		let data = {'appId' : app, 'name' : name, 'isActive' : 1, 'hideWord': 0};		
		let encodedData = JSON.stringify(data);
		return this._http.post('webservices/services/addLesson.php', encodedData);
	}

	updateLesson(lesson: ILesson) : Observable<Object> {
		var encodedData = JSON.stringify(lesson);
		return this._http.post('webservices/services/updateLesson.php', encodedData);
	}

	getMostRecentLesson(appId): Observable<ILesson> {   
		if (this.subdomain == null) {
			return Observable.of({id: 1, name: 'Colors And Other Tests', active: 1, hideWord: 0});
		} else {
			this._webServiceUrl = 'webservices/services/getMostRecentLesson.php?appId=' + encodeURIComponent(appId);
			return this._http.get<ILesson>(this._webServiceUrl)
			.catch(this.handleError);
		}
	}

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}
}


