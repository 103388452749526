import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeacherGuardService } from './teacher-guard.service';
import { FormGroup, FormControl } from '@angular/forms';
import { IStory } from '../core/story';
import { StoryService } from '../core/story.service';

@Component({
  selector: 'tch-story-list',
  templateUrl: './story-list.component.html',
  styleUrls: ['./story-list.component.css']
})
export class StoryListComponent implements OnInit {
	stories : IStory[] = [];
	errorMessage : string;

	constructor(private storyService: StoryService, private route: ActivatedRoute, private teacherGuardService : TeacherGuardService) {}

	ngOnInit() {
		this.storyService.getAllStories().subscribe (
			data => this.stories = data,
			error => this.errorMessage = <any>error
		);
	}

}