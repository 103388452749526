import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

@Injectable()
export class AdminGuardService implements CanActivate {
	teacherId: string;

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean { 
		if (isNaN(Number(this.teacherId))) {
			return false;
		} else {
			return true;
		}
	}

	setTeacherId (tId : string) : string {
		if (isNaN(Number(tId))) {
			console.log('unrecognized teacher id');
		} else {
			this.teacherId = tId;
		}
		return this.teacherId;
	}

}
