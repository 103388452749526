<bs-modal id="tryAgain" [animation]="animation" [keyboard]="keyboard" [backdrop]="false" #tryAgain >
		<bs-modal-body>Lesson Name and Right Side are required.</bs-modal-body>
	</bs-modal>
	<div class="container">
		<div class="row pt-5">
			<form [formGroup]="editorForm" (ngSubmit)="onSubmit()">
				<div class="form-group">
					<label for="planName"><br />Name:</label>
					<input required formControlName="lessonName" class="form-control typein" type="text" 
									autocapitalize="none" autocomplete="off"/>
				</div>
				<div class="col-md-5 form-group">
					<label for="editorLeft"><h3>Left Side (Optional)</h3></label>
					<quill-editor formControlName="editorLeft" [modules]="configLeft"></quill-editor>
					<label for="visible" style="margin-top: 1.5em; margin-right: 1em;">Visible to Students:</label>
					<select name="visible" id="visible" formControlName="visible" >	  
						<option>No</option>  
						<option>Yes</option>  
					</select>					
				</div>
				<div class="col-md-1">
					<button class="btn btn-primary">Save</button>
					<button class="btn btn-primary" [routerLink]="['/teacher/stories']">Cancel</button>
					<label for="mode">Mode:</label>
					<select name="mode" id="mode" formControlName="mode" >	  
						<option>Type</option>  
						<option>Drag</option>  
					</select>
					<img style="margin-top: 1.5em;" src="./assets/listenimg.jpg" (click)="listen()" />
				</div>
				<div class="col-md-6 form-group">
					<label for="editorRight"><h3>Right Side (Required)</h3></label>
					<quill-editor formControlName="editorRight" [modules]="configRight"></quill-editor>
					<label class="col-md-3" style="margin-top: 1.5em;" for="audioUrl">Audio File</label>
					<input name="audioUrl" id="audioUrl" class="col-md-9" style="margin-top: 2em;" formControlName="audioUrl" />
				</div>
			</form>		
		</div>
		<div class="row">
			<div class="pt-5 col-md-5 container">
				<div [innerHTML]="editorContentLeft"></div>
			</div>
		</div>
		<div class="row pt-5">
			<h3>Rules, Hints and Tips</h3>
			<ol>
				<li>Underlined words on the Right Side will become the list of words for the students to choose from.  They will be replaced by empty blanks.</li>
				<li>The fastest way to choose a word so you can underline it?  Double-click it.</li>
				<li>You are not limited to underlining single words.  Phrases can also be underlined.</li>
				<li>Text can be typed into the editors, or pasted in.  Images can be added to the Left Side.  
				<li><i>Please respect copyright law.  Do not use pictures, audio, or text unless they are in the public domain. TCH could be held liable for any infringement. Lessons you create are visible to everyone on the Internet, not just TCH students.</i></li>
				<li>These lessons can be edited by any teacher.  Changes you make affect everyone.</li>
				<li>To make this lesson available to students, set Visible to Students to Yes.</li>
			</ol>
		</div>
	</div>	
