<div *ngIf="atEnd == false && words.length > 0">
	<div class="hidden-xs hidden-sm">
		<div class="row" style="margin-top: 30px;">
			<p class="myprogress pull-right">word {{curr + 1}} of {{words.length}}</p>
		</div>
		<div style="margin-bottom: 20px;" class="container" >
			<div class="row noselect">
				<div class="col-md-3 col-md-offset-1">
					<p class="xlgfont spell noselect" *ngIf="showWord">{{currWord}}</p>
				</div>
				<div class="col-md-3">
					<img class="noselect" draggable="false" src="{{words[curr].imageUrl}}" />
				</div>
				<div class="spell">
					<button class="btn btn-default" *ngIf="showRepeat" (click)="repeatWord()">Repeat</button>
				</div>
			</div>
		</div>
		<div class="container">
			<form #form="ngForm" (ngSubmit)="onCheck()" novalidate style="margin-bottom: 20px;" name="cloze" >
				<div #cloze class="lgfont form-inline row" *ngIf="showSentence" style="min-height: 55px; vertical-align: middle;">
					<span style="margin-right: .5em;" *ngIf="useCloze">{{words[curr].cloze1}}</span>
					<input name="typedWordField" class="form-control typein" type="text" autocapitalize="none" autocomplete="off" 
						[(ngModel)]="typedWord" [tchFocus]="true" (keyup)="onKeyUp($event)" [style.width]="wordsExt[curr].width" />
					<span style="margin-left: .5em;" *ngIf="useCloze">&nbsp;{{words[curr].cloze2}}</span>
				</div>
				<div class="row">
					<button class="btn btn-default" style="margin-top: 10px;" *ngIf="typedWord" type="submit" >
						Check?</button>
				</div>
			</form>
		</div>
	</div>
	<div class="container-fluid visible-xs-block visible-sm-block " >
		<div class="row" style="margin-top: 30px;">
			<p class="myprogress pull-right">word {{curr + 1}} of {{words.length}}</p>
		</div>
		<div style="text-align: center;">
			<img class="noselect img-reponsive" style="max-height: 125px;" draggable="false" src="{{words[curr].imageUrl}}" />
			<p class="xlgfont noselect" *ngIf="showWord">{{currWord}}</p>
			<p class="xlgfont noselect" *ngIf="!showWord">&nbsp;</p>
		</div>
		<form #form="ngForm" (ngSubmit)="onCheck()" name="cloze" >
			<button style="margin-right: 6px;" class="btn btn-default" *ngIf="showRepeat" type="button" (click)="repeatWord()">Repeat</button>
			<button class="btn btn-default" *ngIf="typedWord" type="submit" (click)="onCheck()">Check?</button>
			<div class="form-row">
				<div class="col" style="margin: 20px 0px 20px 0px;">
					<div #cloze *ngIf="showSentence" style="min-height: 55px; vertical-align: middle;">
						<span style="margin-right: .5em;" *ngIf="useCloze">{{words[curr].cloze1}}</span>
						<input name="typedWordField" class="inline-form-control typein lgfont" type="text" 
							autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"  
							[(ngModel)]="typedWord" [tchFocus]="true" (keyup)="onKeyUp($event)" [style.width]="wordsExt[curr].width"/>
						<span *ngIf="useCloze">&nbsp;{{words[curr].cloze2}}</span>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
<div class="row container"*ngIf="atEnd == true">
	<div class="hidden-md">
		<div class="pager">
			<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
			<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
		</div>
	</div>
	<div class="col-md-4 col-md-offset-1">
		<p><b><u>Correct</u></b></p>
		<ul class="nobullets">
			<li *ngFor='let corr of correct'>{{corr}}</li>
		</ul>
		<p><b><u>Incorrect</u></b></p>
		<ul class="nobullets">
			<li *ngFor='let incorr of incorrect'>{{incorr}}</li>
		</ul>
	</div>
	<div class="visible-md-block col-md-6">
		<div class="pager">
				<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
				<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
		</div>
		<div class="row">
			<img src="./images/smiley.jpg" />
		</div>
	</div>
</div>    
	