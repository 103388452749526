import {  PipeTransform, Pipe } from '@angular/core';
import { ILessonPlan } from '../core/lesson-plan';

@Pipe({
    name: 'planFilter'
})
export class PlanFilterPipe implements PipeTransform {

    transform(value: ILessonPlan[], filterBy: string): ILessonPlan[] {
        filterBy = filterBy ? filterBy.toLocaleLowerCase() : null;
        return filterBy ? value.filter((plan: ILessonPlan) =>
			(plan.lessonPlanName.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
			 plan.firstName.toLocaleLowerCase().indexOf(filterBy) != -1 ||
			 plan.lastName.toLocaleLowerCase().indexOf(filterBy) != -1 )) : value;
    }
}
