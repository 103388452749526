import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { LessonService } from './core/lesson.service';
import { ILesson } from './core/lesson';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tch-lesson-list',
  templateUrl: './lesson-list.component.html',
  styleUrls: ['./lesson-list.component.css']
})
export class LessonListComponent implements OnInit {
  @Input() elaApp : number;
  @Input() elaAppName : string;
  @Input() searchTerm : string;
  lessons: ILesson[] = [];
  errorMessage : string;

  constructor(private _lessonService : LessonService, route: ActivatedRoute) { 
    this.elaApp = 0;
  }

  ngOnInit() {
//	this.ngOnChanges();
  }

  ngOnChanges() {
	if (this.searchTerm) {
		this._lessonService.getLessonsByWord(this.searchTerm).subscribe(
				lessons => {this.lessons = lessons},
				error => {this.errorMessage = <any>error, console.log(this.errorMessage)}
		);
	} else if (this.elaApp) {
		this._lessonService.getLessons(this.elaApp).subscribe(
        lessons => {this.lessons = lessons },
        error => this.errorMessage = <any>error
      );
    };
  }


}
