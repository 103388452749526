import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherGuardService } from './teacher-guard.service';
import { FormGroup, FormControl } from '@angular/forms';
import { BsModalComponent, BsModalService  } from 'ng2-bs3-modal';
import { IStory } from '../core/story';
import { StoryService } from '../core/story.service';
import { LessonService } from '../core/lesson.service';

@Component({
  selector: 'tch-story-edit',
  templateUrl: './story-edit.component.html',
  styleUrls: ['./story-edit.component.css']
})
export class StoryEditComponent implements OnInit {
	app : string = '5';
	story : IStory;
	errorMessage : string;
	editorForm : FormGroup;
	editorContentLeft: String;
	updating : boolean;
	lessonName : string = '';
	@ViewChild('tryAgain') tryAgain: BsModalComponent;	
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;
	audioElement: any;

	configLeft = { toolbar: [
		['bold','italic','image'], [{ 'align': [] }]
	]}

	configRight = { toolbar: [
		['underline']
	]}

	constructor(private storyService: StoryService, private lessonService: LessonService, private route: ActivatedRoute, 
					private router: Router, private teacherGuardService : TeacherGuardService) {}

	ngOnInit() {
		this.audioElement = document.createElement('audio');
		this.editorForm = new FormGroup({
			'lessonName' : new FormControl(''),
			'editorLeft': new FormControl(null),
			'editorRight': new FormControl(null),
			'mode': new FormControl('Type'),
			'visible': new FormControl('No'),
			'audioUrl': new FormControl('')
		});
		if (+this.route.snapshot.params['id'] == 0) {
			this.updating = false;
			this.story = {lessonId: 0, lessonName: '', active: 0, audioUrl: '', cues: '', mainText: '', mode: 'Type' };
		} else {
			this.updating = true;
			this.storyService.getStory(+this.route.snapshot.params['id']).subscribe (
				data => {this.story = data; 
						this.editorForm = new FormGroup({
							'lessonName' : new FormControl(this.story.lessonName),
							'editorLeft': new FormControl(this.story.cues),
							'editorRight': new FormControl(this.prepForDisplay(this.story.mainText)),
							'mode': new FormControl(this.story.mode),
							'visible': new FormControl(this.story.active == 1? 'Yes' : 'No'),
							'audioUrl': new FormControl(this.story.audioUrl)
						});
						this.lessonName = this.story.lessonName;
						},
				error => this.errorMessage = <any>error
			);
		}
	}

	// add text to make the image stay inside the container
	prepForDisplay(orig: String) : String {
		var preppedString : String;
		preppedString = orig.replace('img src=','img class="img-responsive" src=');
		return preppedString;
	}

	onSubmit() {
		if (this.editorForm.get('lessonName').value == null || this.editorForm.get('lessonName').value.trim() == '' ||
			this.editorForm.get('editorRight').value == null || this.editorForm.get('editorRight').value.trim() == '') {
			let that = this;
			this.tryAgain.open('sm')
				.then(function() { setTimeout(function() {that.tryAgain.close();},2000);
			});
		} else {
			this.story.active = this.editorForm.get('visible').value == 'Yes' ? 1 : 0;
			this.story.audioUrl = this.editorForm.get('audioUrl').value;
			this.story.cues = this.editorForm.get('editorLeft').value;
			this.story.mainText = this.editorForm.get('editorRight').value;
			this.story.mainText = this.story.mainText.replace(/ <\/u>/g,'</u> ');
			this.story.mode = this.editorForm.get('mode').value;
			if (this.story.lessonId == 0) {
				// make the new lesson
				this.lessonService.addLesson(this.app, this.editorForm.get('lessonName').value).subscribe(
					data => {this.lessonService.getMostRecentLesson(this.app).subscribe(
						data=> {this.story.lessonId = data.id; this.storyService.addStory(this.story).subscribe(
							data => {this.router.navigate(['teacher/stories'])},
							error => {this.errorMessage = JSON.stringify(<any>error); console.log(this.errorMessage);}
						)},
						error => {this.errorMessage = JSON.stringify(<any>error); console.log(this.errorMessage);}
					)},
					error => {this.errorMessage = <any>error; console.log(this.errorMessage)},
				);
			} else {
				this.storyService.updateStory(this.story).subscribe(
					data => {
						// if the name changed, update the lesson too
						if (this.editorForm.get('lessonName').value != this.lessonName) {
							this.lessonService.getLesson(this.story.lessonId).subscribe(
								result => 
									{let oldLesson = result;
									oldLesson.name = this.editorForm.get('lessonName').value;
									this.lessonService.updateLesson(oldLesson).subscribe(
										result => {this.router.navigate(['teacher/stories']);},
										error => {this.errorMessage = JSON.stringify(<any>error); 
												console.log(this.errorMessage);}
									)},
									error => {this.errorMessage = JSON.stringify(<any>error); 
										console.log(this.errorMessage);}
							);
						} else {
							this.router.navigate(['teacher/stories'])
						}
					},
					error => {this.errorMessage = JSON.stringify(<any>error); console.log(this.errorMessage);}
				);
			}
		}
	}

	listen() {
		console.log(this.editorForm.get('audioUrl').value);
		this.audioElement.setAttribute('src',this.editorForm.get('audioUrl').value);
		this.audioElement.play();
	}

}