import { Component, OnInit, ViewChild } from '@angular/core';
import { LessonPlanService } from '../core/lesson-plan.service';
import { ILessonPlan } from '../core/lesson-plan';
import { ILessonPlanItem } from '../core/lesson-plan-item';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherGuardService } from './teacher-guard.service';
import { BsModalComponent, BsModalService  } from 'ng2-bs3-modal';

@Component({
  selector: 'tch-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.css']
})
export class PlanDetailComponent implements OnInit {
	plan : ILessonPlan;
	planItems : ILessonPlanItem[] = [];
	errorMessage : string;
	@ViewChild('confirmDelete') confirmDelete: BsModalComponent;
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;

	constructor(private lessonPlanService: LessonPlanService, private route: ActivatedRoute, 
		private router: Router, private teacherGuardService : TeacherGuardService) { }

	ngOnInit() {
	  
		this.lessonPlanService.getLessonPlan(+this.route.snapshot.params['id']).subscribe (
			data => {this.plan = data},
			error => this.errorMessage = <any>error
		);
		this.lessonPlanService.getLessonPlanItems(+this.route.snapshot.params['id']).subscribe (
			data => this.planItems = data,
			error => this.errorMessage = <any>error
		);
	}

	isMine() {
		if (this.plan && this.teacherGuardService.isMine(String(this.plan.ownerId))) {
			return true;
		} else {
			return false;
		}
	}

	setUpDelete() {
		this.confirmDelete.open();
	}

	oops() {
		this.confirmDelete.close();
	}

	deletePlan() {
		this.lessonPlanService.deleteLessonPlan(this.plan).subscribe(
			data => {this.router.navigate(['teacher/plans']);},
			error => this.errorMessage = JSON.stringify(<any>error)
		);		
	}

	showPlan() {
		this.plan.visibleToStudents = true;
		this.lessonPlanService.updateLessonPlan(this.plan).subscribe(
			data =>{let that = this},
			error => this.errorMessage = JSON.stringify(<any>error)
		);		
	}
	
	hidePlan() {
		this.plan.visibleToStudents = false;
		this.lessonPlanService.updateLessonPlan(this.plan).subscribe(
			data =>{let that = this},
			error => this.errorMessage = JSON.stringify(<any>error)
		);		
	}

	cloneLesson() {
		this.plan.lessonPlanId = 0;
		this.plan.ownerId = +this.teacherGuardService.teacherId;
		this.plan.lessonPlanName = 'Enter new lesson plan name';
		this.lessonPlanService.addNewLessonPlan(this.plan).subscribe(
			data => {
				this.lessonPlanService.getMostRecentLessonPlan(String(this.plan.ownerId)).subscribe(
					data => {
						this.plan = data[0]; 
						for (var j=0;j<this.planItems.length;j++) {
							this.planItems[j].lessonPlanId = this.plan.lessonPlanId;
						}
						this.lessonPlanService.addLessonPlanItems(this.planItems).subscribe(
							data => {
								console.log('teacher/plans/' + this.plan.lessonPlanId + '/edit');							
								this.router.navigate(['teacher/plans/' + this.plan.lessonPlanId + '/edit']);
							},
							error => this.errorMessage = JSON.stringify(<any>error),
							() => {if (this.errorMessage) 
										console.log('Error updating plan items: ' + this.errorMessage);
									}
						);
					},
					error => this.errorMessage = JSON.stringify(<any>error),
					() => {if (this.errorMessage) 
								console.log('Error retrieving plan: ' + this.errorMessage);
							}
				);
			},
			error => this.errorMessage = JSON.stringify(<any>error),
			() => {if (this.errorMessage) 
						console.log('Error inserting new plan: ' + this.errorMessage);
					}
		);
	}

}
