<div class="mt-5 container">
	<div class="col-sm-6 col-md-3">
		<a [routerLink]="['/teacher/plans']">
			<div class="panel panel-primary">
				<div class="panel-heading">
					<div class="caption">Make Lesson Plans</div>
				</div>
				<div class="panel-body">
					Lesson plans are a named list of lessons for students to follow.  Lesson plans belong to a teacher.
				</div>
			</div>
		</a>
	</div>
	<div class="col-sm-6 col-md-3">
		<a [routerLink]="['/teacher/stories']">
			<div class="panel panel-primary">
				<div class="panel-heading">
					<div class="caption">Write TCH Stories</div>
				</div>
				<div class="panel-body">
					TCH Stories are fill-in-the blank exercises.  TCH Stories are shared by everyone.
				</div>
			</div>
		</a>
	</div>
</div>	