import { Component, OnInit, ViewChild, ViewEncapsulation  } from '@angular/core';
import { LessonService } from '../core/lesson.service';
import { LepService } from '../core/lep.service';
import { ILesson } from '../core/lesson';
import { ILepItem } from '../core/lep-item';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { FormsModule, NgForm } from '@angular/forms';
import { BsModalComponent, BsModalService  } from 'ng2-bs3-modal';

@Component({
  selector: 'tch-lep-lessons',
  templateUrl: './lep-lessons.component.html',
  styleUrls: ['./lep-lessons.component.css']
})
export class LepLessonsComponent implements OnInit {
	app : string;
	lessons: ILesson[];
	errorMessage : string;
	currLesson : ILesson;
	showAddNew : boolean;
	showOneLesson : boolean;
	newLessonName : string;
	showAddNewItem : boolean = false;
	items: ILepItem[] = [];
	deleteIdx : number;
	lastPosition : number = 0;
	@ViewChild('lepItems') lepItems: NgForm;	
	@ViewChild('confirmDelete') confirmDelete: BsModalComponent;
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;

  constructor(private lepService: LepService, private lessonService : LessonService,
	private route: ActivatedRoute) { }

  	ngOnInit() {
		this.app = this.route.snapshot.data['app'];		
		this.route.data.subscribe(
			data => this.lessons = data['lessonList'],
			error => this.errorMessage = <any>error
		);
	}

	selectLesson() {
		if (this.currLesson.id == -1) {
			this.showAddNew = true;
			this.showOneLesson = false;
		} else {	
			// get the lesson items from the database and add them to page
			this.lepService.getLesson(this.currLesson.id).subscribe(
				items => {this.items = items;
						  if (this.items.length > 0) {
						  this.lastPosition = this.items[this.items.length-1].position;
						}
				},
				error => this.errorMessage = <any>error,				
				() => {
					this.showAddNew = false; 
					this.showOneLesson = true;
					this.showAddNewItem = true;
				}
			);			
		}
	}

	createNewLesson() {
		this.showAddNew = false;
		// make the new lesson
		this.lessonService.addLesson(this.app, this.newLessonName).subscribe(
			data => {'back from adding lesson' + this.newLessonName},
			error => {this.errorMessage = <any>error;
						console.log(this.errorMessage)},
			() => {
				// requery the database and rebuild the lesson list
				this.lessonService.getAdminLessons(+this.app).subscribe(
					lessons => {
						this.lessons = lessons;
						// set the new lesson as the current lesson -- it will be the one with the highest id
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (+this.lessons[x].id > +this.currLesson.id) {
								this.currLesson = this.lessons[x];
							}
						}
						this.showOneLesson = true;
						this.lastPosition = 0;
						this.showAddNewItem = true;
					},
					error => this.errorMessage = <any>error
				);
			}
		);
	}	

	updateLesson() {
		this.lessonService.updateLesson(this.currLesson).subscribe(
			() => {
				this.lessonService.getAdminLessons(+this.app).subscribe (
					data => {this.lessons = data;
					},
					error => this.errorMessage = <any>error,
					() => {
						// reselect the lesson
						let cId = this.currLesson.id;
						this.currLesson = this.lessons[0];
						for (var x=0;x<this.lessons.length;x++) {
							if (this.lessons[x].id == cId) {
								this.currLesson = this.lessons[x];
							}
						}
					}
				);
			}
		);
	}
	
	saveItem(idx : number) {
		if (this.items[idx].position != null && this.items[idx].icon != null && this.items[idx].link != null && this.items[idx].description != null) {
			this.items[idx].dirty = false;
			if (this.items[idx].itemId == null) {
				this.items[idx].lessonId = this.currLesson.id;
				this.lepService.addItem(this.items[idx]).subscribe(
					data => console.log(data),
					error => {},
					() => {this.lastPosition = this.items[idx].position;
							this.showAddNewItem = true;
						}
				);
			} else {
				this.lepService.updateItem(this.items[idx]).subscribe(
					() => {this.showAddNewItem = true;}
				);
			}
		}
	}

	setUpDelete(idx : number) {
		this.deleteIdx = idx;
		this.confirmDelete.open();
	}

	oops() {
		this.confirmDelete.close();
	}

	deleteItem() {
		this.confirmDelete.close();
		if (this.items[this.deleteIdx].itemId == null) {
			if (this.items.length == 1) {
				this.items = [];
				this.lastPosition = null;
			} else {
				this.items.splice(this.deleteIdx,1);
				if (this.items.length > 0) {
					this.lastPosition = this.items[this.items.length-1].position;
				}
			}
		} else {
			this.lepService.deleteItem(this.items[this.deleteIdx].itemId).subscribe(
				data => {console.log('deleted item from db'); console.log(data)},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)},
				() => {this.items.splice(this.deleteIdx,1);
						if (this.items.length > 0) {
							this.lastPosition = this.items[this.items.length-1].position;
						}
				}
			);				
		}
	}
	
	addNewItem() {
		this.lastPosition = +this.lastPosition + 1;
		this.items.push ({	
			itemId : null,
			lessonId : this.currLesson.id,
			position : this.lastPosition,
			icon: '',
			link: '',
			description: ''
		});
		this.showAddNewItem = false;		
	}

	makeDirty(idx) {
		this.items[idx].dirty = true;
	}

	isDirty(idx) {
		return (this.items[idx].dirty == true);
	}	
}
