<div class="panel panel-primary">
	<div class="panel-heading" *ngIf="plan">
		<div>Lesson Plan: {{plan.lessonPlanName}}</div>
	</div>

	<div class="panel-body" *ngIf="plan">

		<p>Enter TCHAskForHelp.html to put up a help page (e.g. for Rosetta Stone).</p>
		
		<p>TCH Spelling runs the tasks in the modules list.  If there are no modules listed, it will run 1,2,3,4,5.
			The modules and their order can be changed. For example, to run 6, then 2, and then 5 for lesson 37, enter:</p>
		<p class="col-md-offset-1" >TCHSpelling/37?modules=625</p>
		<p>The available modules are:</p>
		<ul>
			<li>1-Type Word (Cloze)</li>
			<li>2-Match Picture</li>
			<li>3-Choose Spelling</li>
			<li>4-Scrambed Word</li>
			<li>5-Match Sound</li>
			<li>6-Type Word Only</li>
		</ul>

		<a class="btn btn-primary" (click)="savePlan()">Save</a>
		<a class="btn btn-primary" *ngIf="plan.lessonPlanId != 0" [routerLink]="['/teacher/plans', plan.lessonPlanId]">Cancel</a>
		<a class="btn btn-primary" *ngIf="plan.lessonPlanId == 0" [routerLink]="['/teacher/plans']">Cancel</a>

		<div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>

		<form #form="ngForm">
			<div class="row">
				<div class="col-md-offset-1 col-md-2">
					<label for="showPlan">Show to Students</label>
					<input type="checkbox" name="visible" class="form-check-input" id="showPlan" 
						[(ngModel)]="plan.visibleToStudents" [ngModelOptions]="{standalone: true}" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-1">
					<label for="planName"><br />Name:</label>
				</div>
				<div class="col-md-10">
					<input #planName name="planName" [(ngModel)]="plan.lessonPlanName" class="form-control typein" type="text" 
						autocapitalize="none" autocomplete="off"/>
				</div>
			</div>
			<div *ngFor="let item of planItems; let i = index">
				<div class="col-md-1">{{i+1}}</div>
				<div class="col-md-5">
					<input #description name="desc{{i+1}}" [(ngModel)]="item.description" class="form-control typein" type="text" 
								autocapitalize="none" autocomplete="off"/>
				</div>
				<div class="col-md-6">
					<input #url name="url{{i+1}}" [(ngModel)]="item.url" class="form-control typein" type="text" 
								autocapitalize="none" autocomplete="off"/>
				</div>
			</div>
		</form>

		<a class="btn btn-primary" (click)="listSpelling()">List Spelling Lessons</a>
		<a class="btn btn-primary" (click)="listGrammar()">List Grammar Lessons</a>
		<a class="btn btn-primary" (click)="listDictation()">List Dictation Lessons</a>

		<div *ngIf="spellingList">
			Copy a url below to one of the url fields above to add the spelling lesson to the lesson plan.
			<table class="table">
				<thead>
					<tr>
						<td></td>
						<td>Description</td>
						<td>Url</td>
						<td>Words</td>
					</tr>
				</thead>
				<tbody>
					<tr  *ngFor="let sp of spellingList; let i = index">
							<td class="col-md-1"><a class="btn btn-primary" (click)="addLesson('spelling', i)">Add</a></td>
							<td class="col-md-3">{{sp.name}}</td>
						<td class="col-md-3">{{sp.url}}</td>
						<td class="col-md-5">{{sp?.wordListString}}</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div *ngIf="grammarList">
			Copy a url below to one of the url fields above to add the grammar lesson to the lesson plan.
			<table class="table">
				<thead>
					<tr>
						<td></td>
						<td>Description</td>
						<td>Url</td>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let gr of grammarList; let i = index">
						<td class="col-md-1"><a class="btn btn-primary" (click)="addLesson('grammar', i)">Add</a></td>
						<td class="col-md-3">{{gr.name}}</td>
						<td class="col-md-3">{{gr.url}}</td>
					</tr>
				</tbody>
			</table>		
		</div>
	
		<div *ngIf="dictationList">
			Copy a url below to one of the url fields above to add the dictation lesson to the lesson plan.
			<table class="table">
				<thead>
					<tr>
						<td></td>
						<td>Description</td>
						<td>Url</td>
					</tr>
				</thead>
				<tbody>
					<tr  *ngFor="let d of dictationList; let i = index">
						<td class="col-md-1"><a class="btn btn-primary" (click)="addLesson('dictation', i)">Add</a></td>
						<td class="col-md-3">{{d.name}}</td>
						<td class="col-md-3">{{d.url}}</td>
					</tr>
				</tbody>
			</table>			
		</div>
		
	</div>
</div>