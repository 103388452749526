<nav class="navbar-default" >
        <div class="navbar-header">
            <a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
		</div>
        <div class="dropdown" style="padding-top: 6px; padding-right: 10px;" *ngIf="teacherId">
              <button class="btn btn-default dropdown-toggle pull-right" type="button" id="dropdownMenu1" data-toggle="dropdown" 
                  aria-haspopup="true" aria-expanded="true">Menu
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">			
				<li (click)="option = 'Spelling Lessons'"><a [routerLink]="['spelling-lessons']">Spelling Lessons</a></li>
                <li (click)="option = 'Spelling Words'"><a [routerLink]="['spelling-words']">Spelling Words</a></li>
                <li (click)="option = 'Grammar Lessons'"><a [routerLink]="['grammar-lessons']">Grammar Lessons</a></li>
                <li (click)="option = 'Dictation Lessons'"><a [routerLink]="['dictation-lessons']">Dictation Lessons</a></li>
                <li (click)="option = 'LEP Lessons'"><a [routerLink]="['lep-lessons']">LEP Lessons</a></li>
                <li (click)="option = 'Upload Files'"><a [routerLink]="['upload-files']">Upload Files</a></li>
                <li (click)="option = 'People'"><a [routerLink]="['people']">People</a></li>
            </ul>
		</div>
		<div class="pull-right" style="padding-top: 6px; padding-right: 10px;">{{option}}</div>		
    </nav>
<div *ngIf="!teacherId">
    Sign in with your TCH-authorized Google account to access the admin pages.
    <div id="googleBtn">
        <img style="margin: 40px;" src="assets/btn_google_signin_dark_normal_web.png" />
	</div>
	<div class="row" style="margin-top: 10px;">
		<p><i>Please read carefully if you are using a shared or public computer.</i></p>
		<p>Leaving the Tacoma Community House app will NOT automatically sign you out of your Google account.
			Google assumes you want to remain logged in until you tell it otherwise.  If you are using Chrome,
			you can probably log out using the circle in the upper right-corner of this page.  If not, you can 
			always sign out at www.google.com.
		</p>
		<p style="color: red;"><i>Remember to sign out of Google after using the Tacoma Community House app, and all other apps
			that use the Google sign-in.  Otherwise, the next person using this computer will have access to any other app 
			using your Google login, including your gmail.</i>
		</p>
	</div>
</div>
<div class="row" style="margin-top: 10px;">
    <router-outlet></router-outlet>
</div>

    