import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherMenuComponent } from './teacher-menu.component';
import { TeacherLoginComponent } from './teacher-login.component';
import { RouterModule } from '@angular/router';
import { TeacherGuardService } from './teacher-guard.service';
import { LessonPlanService } from '../core/lesson-plan.service';
import { PlanListComponent } from './plan-list.component';
import { PlanDetailComponent } from './plan-detail.component';
import { PlanEditComponent } from './plan-edit.component';
import { StoryListComponent } from './story-list.component';
import { StoryService } from '../core/story.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalModule  } from 'ng2-bs3-modal';
import { PlanFilterPipe } from './plan-filter.pipe';
import { QuillModule} from 'ngx-quill';
import { StoryEditComponent } from './story-edit.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		BsModalModule,	
		QuillModule.forRoot(),	
		ReactiveFormsModule,
    	RouterModule.forChild([
			{ path: 'teacher', 
				component: TeacherLoginComponent,
		  		children: [
					{ path: 'teacher-menu', 
						component: TeacherMenuComponent,
						canActivate: [TeacherGuardService]
					},
					{ path: 'plans', 
						component: PlanListComponent, 
						canActivate: [TeacherGuardService] 
					},
					{ path: 'plans/:id', 
						component: PlanDetailComponent, 
						canActivate: [TeacherGuardService] 
					},
					{ path: 'plans/:id/edit', 
						component: PlanEditComponent, 
						canActivate: [TeacherGuardService] 
					},
					{ path: 'stories',
						component:StoryListComponent,
						canActivate: [TeacherGuardService]
					},
					{ path: 'stories/:id/edit',
						component:StoryEditComponent,
						canActivate: [TeacherGuardService]
					}
				]  
			}
		])
	],
	declarations: [TeacherLoginComponent, 
					TeacherMenuComponent, 
					PlanListComponent, 
					PlanDetailComponent, 
					PlanEditComponent, 
					PlanFilterPipe,
					StoryListComponent,
					StoryEditComponent],
	providers: [ TeacherGuardService, LessonPlanService, StoryService ]
})

export class TeacherModule { }




