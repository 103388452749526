import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ILessonPlan } from './lesson-plan';
import { ILessonPlanItem } from '../core/lesson-plan-item';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class LessonPlanService {
	private webServiceUrl : string;
	private subdomain : string;
  
	constructor(private _http: HttpClient) {
	  if (window.location.hostname != 'localhost') {
		this.subdomain = window.location.hostname.split('.')[0];
	  }
	}  

	getAllLessonPlans() : Observable<ILessonPlan[]> {
		if (this.subdomain == null) {
			return Observable.of([
				{ownerId: 14, firstName: 'Arlene', lastName: '', lessonPlanId: 1, lessonPlanName: 'Lesson Plan 1', visibleToStudents: true},
				{ownerId: 14, firstName: 'Arlene', lastName: '', lessonPlanId: 2, lessonPlanName: 'Lesson Plan 2', visibleToStudents: true},
			])
		} else {
			this.webServiceUrl = 'webservices/services/getAllLessonPlans';
			return this._http.get<ILessonPlan[]>(this.webServiceUrl)
				.catch(this.handleError);
		}
	}

	getLessonPlan(lpId : number) : Observable<ILessonPlan> {
		if (this.subdomain == null) {
			return Observable.of(
				{ownerId: 14, firstName: '', lastName: '', lessonPlanId: 2, lessonPlanName: 'Lesson Plan 2', visibleToStudents: true},
			)
		} else {
			this.webServiceUrl = 'webservices/services/getLessonPlan?lessonPlan=' + encodeURIComponent(String(lpId));
			return this._http.get<ILessonPlan>(this.webServiceUrl)
				.catch(this.handleError);
		}
	}

	getLessonPlanItems (lpId : number) : Observable<ILessonPlanItem[]> {
		if (this.subdomain == null) {
			return Observable.of([
				{lessonPlanId: lpId, itemOrder: 1, description: 'Item #1', url: 'https://www.google.com'}
			])
		} else {
			this.webServiceUrl = 'webservices/services/getLessonPlanItems?lessonPlan=' + encodeURIComponent(String(lpId));
			return this._http.get<ILessonPlanItem[]>(this.webServiceUrl)
				.catch(this.handleError);
		}
	}

	addNewLessonPlan(plan) {
		var encodedData = JSON.stringify(plan);
		this.webServiceUrl = 'webservices/services/addNewLessonPlan.php';
		return this._http.post(this.webServiceUrl, encodedData);
		
	}

	getMostRecentLessonPlan(ownerId : string): Observable<ILessonPlan> {
		this.webServiceUrl = 'webservices/services/getMostRecentLessonPlan.php?ownerId=' + ownerId;
		return this._http.get<ILessonPlan>(this.webServiceUrl)
		.catch(this.handleError);
	}

	getTeacherLessonPlans(ownerId : number): Observable<ILessonPlan[]> {
		if (this.subdomain == null) {
			return Observable.of([
				{ownerId: 14, firstName: 'Arlene', lastName: '', lessonPlanId: 1, lessonPlanName: 'Lesson Plan 1', visibleToStudents: true},
				{ownerId: 14, firstName: 'Arlene', lastName: '', lessonPlanId: 2, lessonPlanName: 'Lesson Plan 2', visibleToStudents: true},
			])
		} else {		
			this.webServiceUrl = 'webservices/services/getTeacherLessonPlans.php?ownerId=' + ownerId;
			return this._http.get<ILessonPlan[]>(this.webServiceUrl)
			.catch(this.handleError);
		}
	}	

	updateLessonPlan(plan) {
		var encodedData = JSON.stringify(plan);
		this.webServiceUrl = 'webservices/services/updateLessonPlan.php';
		return this._http.post(this.webServiceUrl, encodedData);
	}	

	deleteLessonPlan(plan) {
		var encodedData = JSON.stringify(plan);
		this.webServiceUrl = 'webservices/services/deleteLessonPlan.php';
		return this._http.post(this.webServiceUrl, encodedData);
	}

	addLessonPlanItems(items) {
		var encodedData = JSON.stringify(items);
		this.webServiceUrl = 'webservices/services/addLessonPlanItems.php';
		return this._http.post(this.webServiceUrl, encodedData);
	}	

	updateLessonPlanItems(items) {
		var encodedData = JSON.stringify(items);
		this.webServiceUrl = 'webservices/services/updateLessonPlanItems.php';
		return this._http.post(this.webServiceUrl, encodedData);
	}
	
	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}

}
