import { Component, OnInit, OnChanges, Input  } from '@angular/core';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { ActivatedRoute } from '@angular/router';
import { DragulaService } from 'ng2-dragula/components/dragula.provider';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
  selector: 'tch-match-sound',
  templateUrl: './match-sound.component.html',
  styleUrls: ['./match-sound.component.css']
})
export class MatchSoundComponent implements OnInit {
	lesson: number;
	words: ISpellingWord[] = [];
	images : any[];
	unmatched : any[];
	defaultWordSetSize : number = 4;
	firstPageWord = 0;
	atEnd : Boolean = false;
	dropSub: any;
	sliderClass : string = 'slide-in';
	errorMessage : string;

    constructor (private spellingWordService : SpellingWordService, private spellingTasksService : SpellingTasksService,
                  private route: ActivatedRoute,
                  private dragulaService: DragulaService) {
        this.dragulaService.setOptions('dropTarget', {
                      revertOnSpill: true,
                      moves: function (el, container, handle) {
                        return container.classList.contains('fromCont'); // prevents top row from ever moving
                      }
        });
        this.dropSub = this.dragulaService.drop.subscribe((value) => {
            this.onDrop(value.slice(1));
        });
    }
  
    ngOnInit() {
		document.body.style.overflow = "hidden";
        this.lesson = +this.route.parent.snapshot.params['lesson'];
		this.words = this.spellingWordService.spellingWords;
		if (this.words.length > 0) {
			this.prepWords();
		// we've lost the words -- probably through a page refresh, so get them again
		} else {
			this.spellingWordService.getWords(this.lesson).subscribe(
				words => {this.words = words; this.prepWords();},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)}
			);
		}
	}

	prepWords(): void {
		this.spellingWordService.shuffleNoConflict (5, this.defaultWordSetSize);
        this.createSet(this.firstPageWord);
	}
	
	// dragula doesn't clean up after itself
	ngOnDestroy() {
		this.dropSub.unsubscribe();
		this.dragulaService.destroy('dropTarget');
		document.body.style.overflow = "visible";
	}

    createSet(curr) {
        this.images = [];
        this.unmatched = [];
		// adjust the set size if it will leave a single word on the last page
		let wordSetSize = this.spellingWordService.wordSetSize (curr, this.defaultWordSetSize);
		// fill the arrays for this page	
        for (var i=0; i<wordSetSize; i++) {
          this.images.push({imageUrl: this.words[curr+i].imageUrl, key: this.words[curr+i].word, match: ''});
		  this.unmatched.push({word: this.words[curr+i].word, audio: this.words[curr+i].audioWordUrl});
		}
		this.spellingWordService.shuffle(this.unmatched);
	}
  
    onDrop(dragInfo) {
        var toIdx = +dragInfo[1].title;
		var fromIdx = +dragInfo[2].title;
		// only allow a drop if the target is empty and the word matches (unmatched doesn't come into this method correctly on a second time through)
        if (this.images[toIdx].match == '' && this.images[toIdx].key == this.unmatched[fromIdx].word) {
          this.images[toIdx].match = this.unmatched[fromIdx].word;
        } else {
          this.dragulaService.find('dropTarget').drake.cancel(true);
		}
		// if all the words have been matched, request the next set of words
		let setDone = true;
		for (var i=0; i<this.images.length; i++) {
			if (this.images[i].match == '') {
				setDone = false;
				break;
			}
		}
		if (setDone) {
			this.firstPageWord += this.images.length;		
			if (this.firstPageWord >= this.words.length) {
				this.atEnd = true;
			} else {
				this.sliderClass='slide-out';
				var that = this;
				setTimeout(function() { that.createSet(that.firstPageWord); that.sliderClass='slide-in'; }, 1000);
			}
		}
	}
	listen(idx) {
		var audioElement = document.createElement('audio');
		audioElement.setAttribute('src',this.unmatched[idx].audio);
		audioElement.play();
	}	
}
