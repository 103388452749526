<nav class="navbar-default" >
	<div class="navbar-header">
		<a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
	</div>
	<div class="pull-right" style="padding-top: 6px; padding-right: 10px;">Lesson Plans</div>
</nav>
<div *ngIf="!teacherId">
	Sign in with your TCH-authorized Google account to access the lesson plan pages.
	<div id="googleBtn">
		<img style="margin: 40px;" src="assets/btn_google_signin_dark_normal_web.png" />
	</div>
	<div class="row" style="margin-top: 10px;">
		<p><i>Please read carefully if you are using a shared or public computer.</i></p>
		<p>Leaving the Tacoma Community House app will NOT automatically sign you out of your Google account.
			Google assumes you want to remain logged in until you tell it otherwise.  If you are using Chrome,
			you can probably log out using the circle in the upper right-corner of this page.  If not, you can 
			always sign out at www.google.com.
		</p>
		<p style="color: red;"><i>Remember to sign out of Google after using the Tacoma Community House app, and all other apps
			that use the Google sign-in.  Otherwise, the next person using this computer will have access to any other app 
			using your Google login, including your gmail.</i>
		</p>
	</div>
</div>
<div class="adminstyle">
	<div class="row" style="margin-top: 10px;">
		<router-outlet></router-outlet>
	</div>
</div>
