import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TeacherGuardService } from './teacher-guard.service';


@Component({
  selector: 'tch-teacher-menu',
  templateUrl: './teacher-menu.component.html',
  styleUrls: ['./teacher-menu.component.css']
})
export class TeacherMenuComponent implements OnInit {

  constructor(private route: ActivatedRoute,
	private teacherGuardService : TeacherGuardService) { }

  ngOnInit() {
  }

}
