import { Component, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LessonService } from '../core/lesson.service';
import { IStory } from '../core/story';
import { StoryService } from '../core/story.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'tch-fill-in',
  templateUrl: './fill-in.component.html',
  styleUrls: ['./fill-in.component.css']
})
export class FillInComponent implements OnInit {
	lesson : number;
	lessonName : string = '';
	story : IStory;
	errorMessage : string;
	para : string;
	leftPara : string = '';
	words : string[] = [];
	twoParagraphs : boolean = false;
	stillWorking : boolean = true;
	@ViewChild('paraDiv') paraDiv: ElementRef;
	maxLength : number = 0;
	fieldWidth : number = 24;
	wordList : string[] = [];
	solution : string;
	safeSolution : SafeHtml;
	hasAudio : boolean;
	audioElement: any;
	dragMode : boolean;
	listenersAdded : boolean = false;

	constructor(private elementRef:ElementRef, private route: ActivatedRoute, private lessonService : LessonService,
				private storyService: StoryService, private sanitizer: DomSanitizer ) {
		this.lesson = +this.route.snapshot.params['lesson'];
		this.lessonService.getLesson(this.lesson).subscribe(
			result => this.lessonName = result.name
		); 
		var start : number;
		var end : number = 0;
		var newWord : string;
		var tempPara : string;
		this.storyService.getStory(this.lesson).subscribe (
			data => {this.story = data; 
					this.leftPara = this.story.cues;
					if (this.story.cues != null) {
						this.leftPara = this.story.cues.replace('img src=','img class="img-responsive" src=');
					}
					this.para = this.story.mainText;
					this.hasAudio = this.story.audioUrl ? true : false;
					this.dragMode = this.story.mode == 'Drag' ? true : false;
					this.extractWords();
					this.shuffle();
					this.fieldWidth = this.maxLength < 6 ? this.maxLength*24 : this.maxLength*14;
					this.underlines();				
			},
			error => this.errorMessage = <any>error,
		);
	}

	ngOnInit() {
		this.audioElement = document.createElement('audio');
	}


	ngAfterViewChecked() {
		if (!this.listenersAdded && this.solution && this.dragMode) {
			this.listenersAdded = true;
			var ele = document.querySelector('#paraDiv');
			const spans = ele.querySelectorAll('span');
			for (let i = 0; i < spans.length; i++) {
				spans[i].addEventListener('drop', this.drop_handler.bind(this));
				spans[i].addEventListener('dragover', this.dragover_handler.bind(this));
			}
		}
	}


	dragstart_handler(event) {
		// Add the drag data
		event.dataTransfer.setData("text/plain", event.target.id);
		event.dataTransfer.dropEffect = "copy";
	}

	// required for dragging, but unused
	dragover_handler(event) {
		event.preventDefault();
		event.dataTransfer.dropEffect = "copy";
   	}
   
	drop_handler(event) {
		var expected : string = event.target.attributes[0].value;
		var actual : string = event.dataTransfer.getData("text/plain");
		event.preventDefault();
		if (expected === actual) {
			event.target.innerHTML = expected;
		}
	}	

   	extractWords() {
		var start : number;
		var end : number = 0;
		var newWord : string;
		var tempPara : string;
		this.para = this.para.replace(/&lt;/g,'<');
		this.para = this.para.replace(/&gt;/g,'>');
		this.para = this.para.replace(/&nbsp;/g,' ');
		this.twoParagraphs = (this.leftPara != null);
		if (this.twoParagraphs) {
			this.leftPara = this.leftPara.replace(/&nbsp;/g,' ');
		}
		tempPara = this.para;
		while (tempPara.indexOf('<u>') > -1) {
			start = tempPara.indexOf('<u>');
			end = tempPara.indexOf('</u>') + 4;
			newWord = tempPara.slice(start,end).replace('<u>','').replace('</u>','');
			newWord = newWord.trim();
			this.wordList.push(newWord);
			if (!this.words.includes(newWord)) {
				this.words.push (newWord);
				if (newWord.length > this.maxLength) this.maxLength = newWord.length;
			}
			tempPara = tempPara.substring(end,this.para.length);
		}
	}

	underlines() {
		var start : number;
		var end : number = 0;
		var word : string;
		this.solution = '';	
		this.para = this.para.replace(/"/g,'&quot;');	
		while (this.para.indexOf('<u>') > -1) {
			start = this.para.indexOf('<u>');
			end = this.para.indexOf('</u>') + 4;
			word = this.para.slice(start,end).replace('<u>','').replace('</u>','');
			word = word.trim();
			this.solution += this.para.substring(0,start);			
			if (this.dragMode) {
				this.solution += ' <span name="' + word + '">_______________</span> ';
			} else {
				this.solution += ' <input name="' + word + '" class="typein" style="width: ' + this.fieldWidth +
					'px;" type="text" autocapitalize="none" autocomplete="off" />';
			}
			this.para = this.para.substring(end,this.para.length);
		}
		this.solution += this.para;	
		this.safeSolution = this.sanitizer.bypassSecurityTrustHtml(this.solution);	
	}

	shuffle() {
	  var currentIndex = this.words.length;
	  var temporaryValue : string;
	  var randomIndex : number;
	  while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		// And swap it with the current element.
		temporaryValue = this.words[currentIndex];
		this.words[currentIndex] = this.words[randomIndex];
		this.words[randomIndex] = temporaryValue;
	  }
	}

	check() {
		var start : number = 0;
		var end : number = 0;
		var key : string;
		var ans : string;
		var tempPara : string = document.getElementById('paraDiv').innerHTML;
		var typeIns = document.getElementsByClassName('typein');
		this.para = '';
		this.stillWorking = false; // assume all the answers are right until proven otherwise
		for (var i=0;i<typeIns.length;i++) {
			start = tempPara.indexOf('<input');
			end = tempPara.indexOf('>',start+2) + 1;
			key = typeIns[i].attributes.getNamedItem('name').value;
			ans = (<HTMLInputElement>typeIns[i]).value.trim();
			this.para += tempPara.substring(0,start);
			if (key == ans) {
				this.para += '<span style="color: blue">' + ans + '</span>';
			} else {
				this.para += ' <input name="' + key + '" value="' + ans + '" class="typein" style="width: ' + 
				this.fieldWidth + 'px;" type="text" autocapitalize="none" autocomplete="off" />';
				this.stillWorking = true;
			}
			tempPara = tempPara.substring(end,tempPara.length);
		}
		this.para += tempPara;
		this.safeSolution = this.sanitizer.bypassSecurityTrustHtml(this.para);	
	}

	listen() {
		this.audioElement.setAttribute('src',this.story.audioUrl);
		this.audioElement.play();
	}	
}



