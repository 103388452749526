<h1 class="text-center hidden-xs">Tacoma Community House ELA Apps</h1>
<p class="text-center visible-xs-block">ELA Apps</p>
<div class="row">
	<div class="hidden-md hidden-lg">
		<p>Search:</p>
		<input style="margin-bottom: 10px;" name="searchTerm" maxlength="20" class="editing" [(ngModel)]="searchTerm" (input)="search()" autocapitalize="none" autocomplete="off" class="form-control typein"/>
		<div *ngIf="elaApp > 0" >
			<tch-lesson-list [elaApp]='elaApp' [elaAppName]='elaAppName' [searchTerm]='searchTerm'></tch-lesson-list>
			<hr />
		</div>
		<div *ngIf="elaTeacherId > 0">
			<tch-teacher-plan-list  [elaTeacherId]='elaTeacherId'></tch-teacher-plan-list>
			<hr />
		</div>
	</div>
	<div class="col-md-3 col-md-offset-1">
		<ul class="list-unstyled">
			<ul class="list-unstyled" *ngFor="let teacher of teachers; let i = index">
				<li><a (click)='changeTeacher(i)'>Teacher {{ teacher.firstName }}</a></li>
			</ul>
			<li><a (click)='changeApp(1, "/TCHSpelling")'>TCH Spelling</a></li>
			<li><a (click)='changeApp(2, "/TCHGrammar")'>TCH Grammar</a></li>
			<li><a (click)='changeApp(3, "/TCHDictation")'>TCH Dictation</a></li>
			<li><a (click)='changeApp(4,"/TCHLEP")'>LEP</a></li>
			<li><a (click)='changeApp(5,"/TCHNarrative")'>TCH Narrative</a></li>
			<li *ngIf="notTCH"><a routerLink="/admin">Admin</a></li>
			<li *ngIf="notTCH"><a routerLink="/teacher">Teachers</a></li>
		</ul>
		<div class="visible-md-block visible-lg-block">
			<hr />
			<p>Search:</p>
			<input name="searchTerm" maxlength="20" class="editing" [(ngModel)]="searchTerm" (input)="search()" autocapitalize="none" autocomplete="off" class="form-control typein"/>
		</div>
		</div>
	<div class="visible-md-block visible-lg-block col-md-6 col-md-offset-1">
		<div *ngIf="elaApp > 0">
			<tch-lesson-list [elaApp]='elaApp' [elaAppName]='elaAppName' [searchTerm]='searchTerm'></tch-lesson-list>
		</div>
		<div *ngIf="elaTeacherId > 0">
				<tch-teacher-plan-list  [elaTeacherId]='elaTeacherId'></tch-teacher-plan-list>
		</div>
	</div>
</div>