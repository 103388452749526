import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class SpellingTasksService {
	plannedTasks : string[] = [];
	currTask : number = -1; // indicates we have not started
	basePath : string;
	lesson : number;

  	constructor(private router: Router) {

	}

	setTasks(lesson: number, tasks : string[]) {
//		if (lesson != this.lesson) {
			this.basePath = 'TCHSpelling/' + String(lesson) + '/';
			this.currTask = -1;
			this.plannedTasks = tasks;
			this.plannedTasks.push("done");
//		}
	}

	nextTask() {
		if (this.currTask < this.plannedTasks.length) {
			this.currTask++;
			this.goThere(this.plannedTasks[this.currTask], false);
		} else {
			this.currTask = -1;
		}
	}

	sameTask() {
		let path: String = this.router.url;
		if (this.router.url.endsWith('/repeat')) {
			path = path.substr(0, path.length-7);
		} else {
			path += '/repeat';
		}
		this.router.navigate([path]);
	}

	prevTask() {
		if (this.currTask == 0) {
			this.currTask = -1;
		} else {
			this.currTask--;
			this.goThere(this.plannedTasks[this.currTask], false);
		}
	}

	insertTask(task : string) {
		if (this.currTask && this.currTask != -1) {
			this.plannedTasks.splice(this.currTask, 0, task);
			this.goThere(this.plannedTasks[this.currTask], true);
		} else {
			this.currTask = 0;
			this.plannedTasks = [task];
			this.goThere(this.plannedTasks[this.currTask], true);
		}
	}

	goThere(task : string, again: boolean) {
		let path: string = '';
		switch (task) {
			case "1": {path = this.basePath + 'type-word-cloze'; break;}
			case "2": {path = this.basePath + 'match-picture'; break;}
			case "3": {path = this.basePath + 'choose-spelling'; break;}
			case "4": {path = this.basePath + 'scrambled-word'; break;}
			case "5": {path = this.basePath + 'match-sound'; break;}
			case "6": {path = this.basePath + 'type-word-only'; break;}
			case "7": {path = this.basePath + 'fill-in-word'; break;}
			case "done": {path = this.basePath + 'all-done'; break;}
			default: {console.log("Bad task number: " + task);}
		}
		if (path != '') {
			if (again && !this.router.url.endsWith('/repeat')) {
				path += '/repeat';
			}
			this.router.navigate([path]);
		}
	}
	
}
