import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillInComponent } from './fill-in.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		FormsModule,
		RouterModule.forChild([
		  { path: 'TCHNarrative/:lesson', component: FillInComponent }
		])  
	],
  	declarations: [
		FillInComponent
	  ]
})
export class NarrativeModule { }
