import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IStory } from './story';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class StoryService {
	dictations : IStory[] = [];
	errorMessage: string;
	private subdomain : string;
  
	constructor(private _http: HttpClient) { 
		if (window.location.hostname != 'localhost') {
			this.subdomain = window.location.hostname.split('.')[0];
		}		
	}

	  getAllStories() : Observable<IStory[]> {
			if (this.subdomain == null) {
			return Observable.of ([
				{lessonId: 204, name: 'Test Story', active: 1, audioUrl: '', cues: '<p>Cue text</p>', mainText: '<p>This is &lt;u&gt;a&lt;/u&gt; test.</p>', mode: 'Type' }
			]);
		} else {
			let webServiceUrl = 'webservices/services/getAllStories.php';
			return this._http.get<IStory[]>(webServiceUrl)
			.catch(this.handleError);
		}
	}
	
	getStory(lessonId : any) : Observable<IStory> {
		if (window.location.hostname == 'localhost') {
			return Observable.of (
				{lessonId : 204, active: 1, audioUrl: '', cues : '<p>This is the optional queue text.</p>', mainText : '<p>This is the required <u>text</u>.</p>', mode: 'Type' }
			);
		} else {
			let webServiceUrl = 'webservices/services/getStory.php?lessonId=' + encodeURIComponent(lessonId);
			return this._http.get<IStory>(webServiceUrl)
			.catch(this.handleError);
		}
	}	

	addStory(story: IStory) {
		var encodedData = JSON.stringify(story);
		return this._http.post('webservices/services/addStory.php', encodedData);
	}	

	updateStory(story: IStory) {
		var encodedData = JSON.stringify(story);
		return this._http.post('webservices/services/updateStory.php', encodedData);
	}	

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}
}
