import { Component, OnInit } from '@angular/core';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
  selector: 'tch-start-up',
  templateUrl: './start-up.component.html',
  styleUrls: ['./start-up.component.css']
})
export class StartUpComponent implements OnInit {

  constructor(private spellingTasksService : SpellingTasksService) { }

  ngOnInit() {
  }

  nextTask() {
	this.spellingTasksService.nextTask();
  }

}
