import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
  selector: 'tch-choose-spelling',
  templateUrl: './choose-spelling.component.html',
  styleUrls: ['./choose-spelling.component.css']
})
export class ChooseSpellingComponent implements OnInit {
	lesson: number;
	words: ISpellingWord[] = [];
	atEnd : Boolean = false;
	sliderClass : string = 'slide-in';
	choices : any[];
	textColor : string[];
	border : string[];
	currWord : number = 0;
	attempts : number[];
	correct : string[];
	incorrect : string[];
	origWordCount : number;
	allowClick : boolean;
	errorMessage : string;
	 
    constructor (private spellingWordService : SpellingWordService, private spellingTasksService : SpellingTasksService,
					private route: ActivatedRoute) {

	}
  
    ngOnInit() {
        this.lesson = +this.route.parent.snapshot.params['lesson'];
		this.words = this.spellingWordService.spellingWords;
		if (this.words.length > 0) {
			this.prepWords();
		// we've lost the words -- probably through a page refresh, so get them again
		} else {
			this.spellingWordService.getWords(this.lesson).subscribe(
				words => {this.words = words; this.prepWords();},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)}
			);
		}
	}
	
	prepWords() : void {
		this.origWordCount = this.words.length;
		this.spellingWordService.shuffle (this.words);
		this.attempts = [];
		for (var i=0; i<this.words.length;i++) {
			this.attempts.push(0);
		}
		this.setupWord();
	}	

	wordClick(idx) : void {
	if (this.allowClick) {
		this.allowClick = false;
		this.attempts[this.currWord] += 1;
		if (this.choices[idx] == this.words[this.currWord].word) {
			if (this.currWord+1 >= this.words.length) {
				this.currWord += 1;
				this.finishUp();
			} else {
				// if we made a mistake before getting it right, stop to show the right word for a bit
				if (this.attempts[this.currWord] > 1) {
					this.border[idx] = 'border';				
					for (var c=0;c<this.choices.length;c++) {
						if (c != idx) {
							this.textColor[c] = "text-white";
						}
					}
					var that = this;
					setTimeout(function() {that.sliderClass='slide-out';
											setTimeout(function() {	
												that.currWord += 1;
												that.setupWord(); 
												that.sliderClass='slide-in'; }, 1000);},
					3000);
				} else {
					this.currWord += 1;
					var that = this;
					setTimeout(function() {that.setupWord();}, 200); // decreases double-click events
				}
			}
		} else {
			this.allowClick = true;
			this.textColor[idx] = "text-white";
			// on the first mistake, add the word again to the end of the queue
			if (this.attempts[this.currWord] == 1) {
				var repeated = JSON.parse(JSON.stringify(this.words[this.currWord]));	
				this.attempts.push(0);
				this.words.push (repeated);
			}
		}
	}
	}

	private setupWord() {
		this.choices = [this.words[this.currWord].word, this.words[this.currWord].badSpelling1,
						this.words[this.currWord].badSpelling2, this.words[this.currWord].badSpelling3];
		this.spellingWordService.shuffle(this.choices);
		this.textColor = [];
		this.border = [];
		this.allowClick = true;
	}
	
	private finishUp() : void {
		this.atEnd = true;
		// prune off the extra words that were added to the end
		for (var x=this.words.length-1;x>this.origWordCount-1;x--) {
			this.words.pop();
		}
		// show the score
		this.correct = [];
		this.incorrect = [];
		for (var i=0; i<this.words.length;i++) {
			if (this.attempts[i] == 1) {
				this.correct.push(this.words[i].word);
			} else {
				this.incorrect.push(this.words[i].word);				
			}
		}
	}
}
