import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonResourcesComponent } from './lesson-resources.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'TCHLEP/:lesson', component: LessonResourcesComponent }
    ])    
  ],
  declarations: [LessonResourcesComponent]
})
export class LepModule { }
