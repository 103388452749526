import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class TagService {
    tags : string[] = [];
    lessonId : number;
	errorMessage : string;
    
    constructor(private _http: HttpClient) {  }

	getLessonTags(lessonId) : void {
    	if (window.location.hostname == 'localhost') {
    	  this.tags = ['tag1','tag2','tag3'];
	    } else if (lessonId != this.lessonId || this.tags.length == 0) {
        	this.getTags(lessonId).subscribe(
	            tags => this.tags = tags,
            	error => this.errorMessage = <any>error
        	);
    	}
	}

	getTags(lessonId): Observable<string[]> {   
		let webServiceUrl = 'webservices/services/getTags.php?lessonId=' + encodeURIComponent(lessonId);
		return this._http.get<string[]>(webServiceUrl)
		  .catch(this.handleError);
		}

	replaceTags (lessonId : number, tags : string[]) {
		let data = {'lessonId' : lessonId, 'tags' : tags};
		var encodedData = JSON.stringify(data);
		return this._http.post('webservices/services/replaceTags.php', encodedData);
	}


	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}
}
