import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonService } from '../core/person.service';
import { IPerson } from '../core/person';

@Component({
	selector: 'tch-person-edit',
	templateUrl: './person-edit.component.html',
	styleUrls: ['./person-edit.component.css']
})
export class PersonEditComponent implements OnInit {
	person: IPerson;
	errorMessage: string;

	constructor(private personService: PersonService, private route: ActivatedRoute, private router: Router) { }

	ngOnInit() {
		if (+this.route.snapshot.params['id'] == 0) {
			this.person = { id: 0, userName: '', userRole: 'teacher', firstName: '', lastName: '', confirmed: 0 }

		} else {
			this.personService.getPerson(+this.route.snapshot.params['id']).subscribe(
				data => this.person = data,
				error => this.errorMessage = <any>error,
				() => { console.log(this.person); }
			);
		}
	}

	savePerson() {
		if (this.person.id == 0) {
			this.personService.addNewPerson(this.person).subscribe(
				data => {
					this.router.navigate(['admin/people']);
				},
				error => this.errorMessage = JSON.stringify(<any>error)
			);
		} else {
			this.personService.updatePerson(this.person).subscribe(
				data => {
					this.router.navigate(['admin/people']);
				},
				error => this.errorMessage = JSON.stringify(<any>error)
			);
		}
	}
}
