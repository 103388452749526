<div class="adminstyle panel panel-primary">
	<div class="panel-heading" *ngIf="person">
		<div>Edit: {{person.userName}}</div>
	</div>

	<div class="panel-body" *ngIf="person">


		<a class="btn btn-primary" (click)="savePerson()">Save</a>
		<a class="btn btn-primary" [routerLink]="['/admin/people']">Cancel</a>
		<span>{{person.userName}}</span>
		<div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>

		<form #form="ngForm">
			<div *ngIf="person.id == 0" class="row">
				<div class="col-md-2">
					<label for="userName"><br />Email for Login:</label>
				</div>
				<div class="col-md-9">
					<input name="firstName" [(ngModel)]="person.userName" class="form-control typein" type="text" 
							autocapitalize="none" autocomplete="off" />
				</div>
			</div>

			<div class="row">
				<div class="col-md-2">
					<label for="firstName"><br />First Name:</label>
				</div>
				<div class="col-md-9">
					<input name="firstName" [(ngModel)]="person.firstName" class="form-control typein" type="text" autocomplete="off" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<label for="lastName"><br />Last Name:</label>
				</div>
				<div class="col-md-9">
					<input name="lastName" [(ngModel)]="person.lastName" class="form-control typein" type="text" autocomplete="off" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-2">
					<label for="role"><br />Role:</label>
				</div>
				<div class="col-md-9">
					<select name="roles" id="roles" [(ngModel)]="person.userRole">
						<option value="admin">admin</option>
						<option value="teacher">teacher</option>
					</select>
				</div>
			</div>
		</form>
	</div>
</div>