import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpellingLessonsComponent } from './spelling-lessons.component';
import { SpellingWordsComponent } from './spelling-words.component';
import { GrammarLessonsComponent } from './grammar-lessons.component';
import { DictationLessonsComponent } from './dictation-lessons.component';
import { LepLessonsComponent } from './lep-lessons.component';
import { UploadFilesComponent } from './upload-files.component';
import { AdminComponent } from './admin.component';
import { RouterModule } from '@angular/router';
import { AdminGuardService } from './admin-guard.service';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { SpellingWordsResolverService } from './spelling-words-resolver.service';
import { LessonResolverService } from './lesson-resolver.service';
import { BsModalModule  } from 'ng2-bs3-modal';
import { PeopleComponent } from './people.component';
import { PersonResolverService } from './person-resolver.service';
import { PersonEditComponent } from './person-edit.component';

@NgModule({
  imports: [
    CommonModule,
	CoreModule,
	FormsModule,
	BsModalModule,
    RouterModule.forChild([
		{ path: 'admin', 
			component: AdminComponent,
		  	children: [
				{ path: 'spelling-lessons', component: SpellingLessonsComponent, 
				  canActivate: [AdminGuardService],
				  resolve: {lessonList : LessonResolverService, wordList: SpellingWordsResolverService},
				  data: { app: '1'}
				},
				{ path: 'spelling-words', component: SpellingWordsComponent, 
				  canActivate: [AdminGuardService],
				  resolve: {wordList: SpellingWordsResolverService} 
				},
				{ path: 'grammar-lessons', component: GrammarLessonsComponent, 
				  canActivate: [AdminGuardService],
				  resolve: {lessonList: LessonResolverService},
				  data: { app: '2'}
				},
				{ path: 'dictation-lessons', component: DictationLessonsComponent, 
				  canActivate: [AdminGuardService],
				  resolve: {lessonList: LessonResolverService},
				  data: { app: '3'}
				},
				{ path: 'lep-lessons', component: LepLessonsComponent, 
				  canActivate: [AdminGuardService],
				  resolve: {lessonList: LessonResolverService},
				  data: { app: '4'}
			  	},
			  { path: 'upload-files', component: UploadFilesComponent, 
			  		canActivate: [AdminGuardService] },
			  { path: 'people', component: PeopleComponent, 
				  resolve: {peopleList: PersonResolverService},
				  canActivate: [AdminGuardService],
				  runGuardsAndResolvers: 'always' },
			  { path: 'people/:id', component: PersonEditComponent, canActivate: [AdminGuardService] }

		]
		}
	])  
  ],
  declarations: [AdminComponent, 
    SpellingLessonsComponent, 
    SpellingWordsComponent, 
    GrammarLessonsComponent, 
    DictationLessonsComponent, 
    LepLessonsComponent, 
	UploadFilesComponent, 
	PeopleComponent, 
	PersonEditComponent
  ],
  providers: [ AdminGuardService, LessonResolverService, SpellingWordsResolverService, PersonResolverService]
})
export class AdminModule { }
