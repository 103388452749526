<nav class="navbar-default" >
    <div class="navbar-header">
        <a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
	</div>
    <div class="pull-right" style="padding-top: 6px; padding-right: 10px;">{{lessonName}}</div>
</nav>
<div class="container">
	<div *ngIf="twoParagraphs" class="panel panel-default col-sm-6">
		<div class="panel-body" [innerHTML]="leftPara"></div>
	</div>

	<div *ngIf="dragMode">
		<div *ngIf="twoParagraphs" class="panel panel-default col-md-6">
			<div class="panel-body">
				<div *ngIf="hasAudio" style="margin: 10px; text-align: center">
					<img src="./assets/listenimg.jpg" (click)="listen()" />
				</div>
				<div style="margin-bottom: 10px;"><b>Drag the words to the blanks:</b></div>
				<div style="margin-bottom: 10px;">
					<div id="{{word}}" *ngFor='let word of words; let i = index' style="padding: 0px 3px; margin-left: 3px;" 
								class="btn btn-default d-inline" draggable="true" (dragstart)="dragstart_handler($event);">
						<div class="dragtile">{{word}}</div>
					</div>
				</div>			
				<div id="paraDiv" class="panel-body" [innerHTML]="safeSolution"></div>
			</div>
		</div>
		<div *ngIf="!twoParagraphs" class="panel panel-default">
			<div class="panel-body">
				<div *ngIf="hasAudio" style="margin: 10px; text-align: center">
					<img src="./assets/listenimg.jpg" (click)="listen()" />
				</div>
				<div style="margin-bottom: 10px;"><b>Drag the words to the blanks:</b></div>
				<div style="margin-bottom: 10px;">
					<div id="{{word}}" *ngFor='let word of words; let i = index' style="padding: 0px 3px; margin-left: 3px;" 
								class="btn btn-default d-inline" draggable="true" (dragstart)="dragstart_handler($event);">
						<div class="dragtile">{{word}}</div>
					</div>
				</div>			
				<div id="paraDiv" class="panel-body" [innerHTML]="safeSolution"></div>
			</div>
		</div>
	</div>

	<div *ngIf="!dragMode">
		<div *ngIf="twoParagraphs" class="panel panel-default col-sm-6">
			<div class="panel-body">
				<div *ngIf="hasAudio" style="margin: 10px; text-align: center">
					<img src="./assets/listenimg.jpg" (click)="listen()" />
				</div>
				<div *ngIf="stillWorking" style="margin-bottom: 10px;">
					<b>Type the words in the blanks and then click </b>
					<button class="btn btn-primary" (click)="check()">Check</button>
					<b> :</b>
				</div>
				<div style="margin-bottom: 10px;">
					<div id="{{word}}" *ngFor='let word of words; let i = index' style="padding: 0px 3px; margin-left: 3px;" 
								class="btn btn-default d-inline" >
						<div>{{word}}</div>
					</div>
				</div>			
				<div id="paraDiv" class="panel-body" [innerHTML]="safeSolution"></div>
			</div>
		</div>
		<div *ngIf="!twoParagraphs" class="panel panel-default">
			<div class="panel-body">
				<div *ngIf="hasAudio" style="margin: 10px; text-align: center">
					<img src="./assets/listenimg.jpg" (click)="listen()" />
				</div>			
				<div *ngIf="stillWorking" style="margin-bottom: 10px;">
					<b>Type the words in the blanks and then click </b>
					<button class="btn btn-primary" (click)="check()">Check</button>
					<b> :</b>
				</div>
				<div style="margin-bottom: 10px;">
					<div id="{{word}}" *ngFor='let word of words; let i = index' style="padding: 0px 3px; margin-left: 10px;" 
								class="btn btn-default d-inline">
						<div>{{word}}</div>
					</div>
				</div>			
				<div id="paraDiv" class="panel-body" [innerHTML]="safeSolution"></div>
			</div>
		</div>
	</div>
</div>
