<div class="container">
	<table class="table table-bordered" *ngIf="wordlist">
		<tr *ngFor="let word of wordlist; let i = index">
			<td class="text-center"><img src="{{word?.imageUrl}}" width="100"/></td>
			<td>{{ word?.word }}</td>
			<td><img src="./assets/listenimg.jpg" (click)="listen(i)" /></td>
			<td>{{word?.cloze1}} <u>{{word?.word}}</u> {{word?.cloze2}}</td>
		</tr>
	</table>
</div>

