import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tch-all-done',
  templateUrl: './all-done.component.html',
  styleUrls: ['./all-done.component.css']
})
export class AllDoneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
