<div class="adminstyle">
	<bs-modal [animation]="animation" [keyboard]="keyboard" [backdrop]="backdrop" #confirmDelete  >
		<bs-modal-body>Delete item {{deleteIdx + 1}}?</bs-modal-body>
		<bs-modal-footer>
			<button autofocus type="button" class="btn btn-primary" (click)="deleteItem()">Delete</button>
			<button autofocus type="button" class="btn btn-primary" (click)="oops()">Cancel</button>
		</bs-modal-footer>
	</bs-modal>	
	<div class="col-md-12" >
		<div class="row" >
			<form #form="ngForm">
				<label for="lessons">Choose a Lesson:</label>
				<select name="lessons" id="lessons" [(ngModel)]="currLesson" (ngModelChange)="selectLesson()">	  
					<option *ngFor="let lesson of lessons" [ngValue]="lesson">{{lesson.name}}</option>  
				</select>
				<input class="btn btn-primary col-md-offset-1" type="submit" value="New" *ngIf="!showAddNew"
					(click)="showAddNew=true;showOneLesson=false"/>
			</form>
		</div>
	<div class="row" *ngIf="showAddNew">
			<div class="form-hoizontal text-center">
				<label class="col-md-3 control-label" for="newLesson">New Lesson Name</label>
				<input name="newLessonName" class="editing col-md-6" [(ngModel)]="newLessonName" />				
				<input class="btn btn-primary btn-spaced" type="submit" value="Save" (click)="createNewLesson()"/>
			</div>
		</div>
		<div class="row" *ngIf="showOneLesson">
			<label class="col-md-3 control-label" for="newLesson">Lesson Name</label>
			<input class="editing col-md-6" [(ngModel)]="currLesson.name" />				
			<input class="btn btn-primary col-md-offset-1" type="submit" value="Update" (click)="updateLesson()"/>
			<label class="radio-inline col-md-offset-3"><input type="radio" [(ngModel)]="currLesson.active" value="1" >Active in TCH and Dev</label>
			<label class="radio-inline"><input type="radio" [(ngModel)]="currLesson.active" value="2" >Active in Dev Only</label>
			<label class="radio-inline"><input type="radio" [(ngModel)]="currLesson.active" value="0" >Admin Only</label>
			<hr class="fulldark" />
		</div>
	</div>
	<form #dictation="ngForm" class="form-horizontal">
		<div class="row" *ngFor="let i of items; let idx = index" >
			<div id="rightcol" class="col-md-12">
				<div class="form-group">
					<div class="row">
						<label class="col-md-1 control-label">Position:</label>
						<input name="pos{{idx}}" class="col-md-1" required [(ngModel)]="i.position" (keyup)="makeDirty(idx)"/>
						<input name="txt{{idx}}" class="col-md-10" required [(ngModel)]="i.text" (keyup)="makeDirty(idx)"/>
					</div>
					<div class="row">
						<label class="col-md-2 control-label">Normal Audio Url:</label>
						<input name="a1{{idx}}" class="col-md-9" required [(ngModel)]="i.normalAudioUrl" (keyup)="makeDirty(idx)"/>
						<img src="./assets/listenimg.jpg"  (click)="listenNormal(idx)" />
					</div>
					<div class="row">
						<label class="col-md-2 control-label">Slow Audio Url:</label>
						<input name="a2{{idx}}" class="col-md-9" required [(ngModel)]="i.slowAudioUrl" (keyup)="makeDirty(idx)"/>
						<img src="./assets/listenimg.jpg"  (click)="listenSlow(idx)" />
					</div>
					<div class="row">
						<div class="col-md-1 col-md-offset-1">
							<input type="submit" class="btn btn-primary smbtn" value="Save" (click)="save(idx)" *ngIf="isDirty(idx)" />
						</div>
					</div>
					<div class="col-md-1 col-md-offset-10">
							<input type="submit" class="btn btn-primary smbtn" value="Delete" (click)="setUpDelete(idx)" />
					</div>
				</div>
			</div>
		</div>
		<hr class="fulldark" />
		<div>
			<input type="submit" *ngIf="showAddNewItem" class="col-md-offset-2 btn btn-primary smbtn" value="Add Dictation" (click)="addItem()" />
		</div>
	</form>			
</div>