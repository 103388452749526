<div class="adminstyle">
	<div class="row">
		<div class="col-md-2">
			<form class="form-hoizontal text-center">
				<input class="btn btn-primary btn-spaced" type="submit" value="New" 
						(click)="newWord()"/>
			</form>
			<ul class="list-group list-scrollable">
				<li class="list-group-item" *ngFor="let w of words; let i = index" 
						(click)="wordClick(i)" 
						[ngClass]="{active: i == currIdx}">
					{{w.word}}
				</li>
			</ul>
		</div>
		<div class="col-md-10">
			<div class="col-md-12 col-md-offset-2" *ngIf="!ready">
				<br /><br /><br /><br />Click a word to the left to view or edit it.
			</div>
			<div class="col-md-12" *ngIf="ready">
				<div *ngIf="!editMode">
					<form class="form-horizontal">
						<div class="col-md-12 text-center">
							<input type="button" class="btn btn-primary" (click)="editWord()" 
									value="Edit" />
							<input type="button" class="btn btn-primary" (click)="deleteWord()" 
									value="Delete" />
						</div>
						<div class="form-group">
							<label class="col-md-2 control-label" for="cloze1">Sentence</label>
							<input readonly name="cloze1" class="col-md-4" [(ngModel)]="currWord.cloze1" />
							<input readonly name="word" class="col-md-2" [(ngModel)]="currWord.word" />
							<input readonly name="cloze2" class="col-md-4" [(ngModel)]="currWord.cloze2" />
						</div>
						<div id=leftSide class="col-md-8">
							<div class="form-group" >
								<label class="col-md-3 control-label" for="badSpell1">Bad Spellings</label>
								<input readonly name="badSpell1" id="badSpell1" class="col-md-3" [(ngModel)]="currWord.badSpelling1" />
								<input readonly name="badSpell2" class="col-md-3" [(ngModel)]="currWord.badSpelling2" />
								<input readonly name="badSpell3" class="col-md-3" [(ngModel)]="currWord.badSpelling3" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="avoid">Avoid</label>
								<input readonly name="avoid" id="avoid" class="col-md-7" [(ngModel)]="currWord.avoid" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="imageName">Image File</label>
								<input readonly name="imageName" id="imageName" class="col-md-7" [(ngModel)]="currWord.imageUrl" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="baseAudio">Base Audio File</label>
								<input readonly name="baseAudio" id="baseAudio" class="col-md-7" [(ngModel)]="currWord.audioWordUrl" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="btnBase">Listen:</label>
								<div>
									<input id="btnBase" type="button" class="btn btn-primary" value="Base File" 
										(click)="listen()"/>
									<input type="button" class="btn btn-primary" value="Spelling" 
										(click)="listenSpelling()"/>
									<input type="button" class="btn btn-primary" value="Sentence" 
										(click)="listenSentence()"/>
								</div>
							</div>
							<div>
								Lessons using this word:
								<ul class="list-group">
									<li class="list-group-item" *ngFor="let lname of wordLessons" >
										{{lname}}
									</li>
								</ul>
							</div>
						</div>
						<div id="rightSide" class="col-md-4" *ngIf="currWord.imageUrl!==''">
							<img src={{currWord.imageUrl}} />
						</div>
					</form>
				</div>
				<div *ngIf="editMode">
					<form class="form-horizontal">
						<div class="col-md-12 text-center">
							<input type="button" class="btn btn-primary" (click)="saveWord()" value="Save" />
							<input type="button" class="btn btn-primary" (click)="cancel()" value="Cancel" />
						</div>
						<div class="form-group">
							<label class="col-md-2 control-label" for="cloze1">Sentence</label>
							<input name="cloze1e" class="editing col-md-4" [(ngModel)]="tempWord.cloze1" />
							<input name="worde" class="editing col-md-2" [(ngModel)]="tempWord.word" />
							<input name="cloze2e" class="editing col-md-4" [(ngModel)]="tempWord.cloze2" />
						</div>
						<div id=leftSide class="col-md-8">
							<div class="form-group" >
								<label class="col-md-3 control-label" for="badSpell1">Bad Spellings</label>
								<input class="editing col-md-3" name="badSpell1e" id="badSpell1" [(ngModel)]="tempWord.badSpelling1" />
								<input class="editing col-md-3" name="badSpell2e" [(ngModel)]="tempWord.badSpelling2" />
								<input class="editing col-md-3" name="badSpell3e" [(ngModel)]="tempWord.badSpelling3" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="avoid">Avoid</label>
								<input id="avoid" class="editing col-md-7" name="avoide" [(ngModel)]="tempWord.avoid" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="imageName">Image File</label>
								<input id="imageName" class="editing col-md-7" name="imageNamee" [(ngModel)]="tempWord.imageUrl" />
							</div>
							<div class="form-group">
								<label class="col-md-3 control-label" for="baseAudio">Base Audio File</label>
								<input id="baseAudio" class="editing col-md-7" name="baseAudioe" [(ngModel)]="tempWord.audioWordUrl" />
							</div>
						</div>
						<div id="rightSide" class="col-md-4">
							<img src={{tempWord.imageUrl}} />
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>