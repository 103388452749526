<div class="wrapper" *ngIf="atEnd == false && words.length > 0" class="{{sliderClass}}">
	<div class="row hidden-xs">
		<ul class="list-inline thumbnails" >
			<li class="col-sm-6 col-md-3" *ngFor='let word of images; let i = index'>
				<div class="panel panel-info">
					<div class="text-center" style="height: 250px;">
						<img draggable="false" src="{{word.imageUrl}}" />
					</div>
					<div class="text-center" style="margin-top: 10px; margin-bottom: 10px;">
						<div class="btn btn-snd-droppable toCont container" title="{{i}}" [dragula]='"dropTarget"'></div>                
					</div>
				</div>
			</li>
		</ul>
	</div>
	<div class="row visible-xs-block">
		<ul class="list-inline thumbnails" style="margin-bottom: 0px;">
			<li *ngFor='let word of images; let i = index'>
				<div class="panel panel-info" style="margin-bottom: 6px;">
					<div class="text-center" style="height: 115px;">
						<img style="max-height: 100px; max-width: 160px;" draggable="false" src="{{word.imageUrl}}" />
					</div>
					<div class="text-center">
						<div class="btn btn-snd-droppable toCont container" title="{{i}}" [dragula]='"dropTarget"'></div>                
					</div>
				</div>
			</li>
		</ul>
	</div>	
	<div class="row">
		<div class="list-inline" style="text-align: center;" >
			<div class="btn btn-snd-droppable container fromCont" title="{{i}}" *ngFor='let word of unmatched; let i = index' [dragula]='"dropTarget"'>
				<div class="btn btn-default btn-snd-draggable lgfont" >
					<div class="dragtile"><img (mousedown)="listen(i)" src="./assets/listenimg.jpg" /></div>
				</div>
			</div>
		</div>  
	</div>
</div> 
<div class="row container"*ngIf="atEnd == true">
	<div class="pager">
		<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
		<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
	</div>
	<div class="col-xs-6 col-md-2">
		<img src="./images/smiley.jpg" />
	</div>
</div>   
