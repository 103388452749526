import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { ISpellingWord } from '../core/spelling-word';
import { SpellingWordService } from '../core/spelling-word.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class SpellingWordsResolverService implements Resolve<ISpellingWord[]> {
	
	constructor (private spellingWordService : SpellingWordService, private router: Router) {}
	
		 resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<ISpellingWord[]> {
			return this.spellingWordService.getAllSpellingWords();
	}
}
