import { Component, OnInit } from '@angular/core';
import { LessonPlanService } from './core/lesson-plan.service';
import { ILessonPlan } from './core/lesson-plan';


@Component({
	selector: 'tch-app-lessons',
	templateUrl: './app-lessons.component.html',
	styleUrls: ['./app-lessons.component.css']
})

export class AppLessonsComponent implements OnInit {
	elaApp: number;
	elaAppName: string;
	elaTeacherId: number;
	searchTerm : string = '';
	notTCH: boolean = true;
	teachers: any[] = [];
	plans: ILessonPlan[] = [];
	errorMessage : string;

	constructor(private lessonPlanService: LessonPlanService) {
		if (window.location.hostname != 'localhost') {
			let subdomain: string = window.location.hostname.split('.')[0];
			if (subdomain == 'tch') {
				this.notTCH = false; // we are in tch
			}
		}
	}

	ngOnInit() {
		this.lessonPlanService.getAllLessonPlans().subscribe (
			data => this.plans = data,
			error => this.errorMessage = <any>error,
			() => {
				var  names: string[] = [];
				for(var i=0;i<this.plans.length;i++) {
					if (this.plans[i].firstName != '' && this.plans[i].visibleToStudents == true &&
							!names.includes(this.plans[i].firstName)) {
						names.push(this.plans[i].firstName);
						this.teachers.push ({ownerId: this.plans[i].ownerId, firstName: this.plans[i].firstName});
					}
				}
			}
		);
	 }

	changeApp(chosenApp, appPath): void {
		this.elaTeacherId = 0;
		this.elaApp = chosenApp;
		this.elaAppName = appPath;
		this.searchTerm = '';
	}

	changeTeacher(chosenTeacher): void {
		this.elaApp = 0;
		this.elaTeacherId = this.teachers[chosenTeacher].ownerId;
		this.searchTerm = '';
	}

	search(): void {
		this.searchTerm = this.searchTerm.trim();
		this.elaTeacherId = 0;
		this.elaApp = 1;
		this.elaAppName = '/TCHSpelling';
	}

}
