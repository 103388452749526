import { Component, OnInit, ViewChild } from '@angular/core';
import { LessonPlanService } from '../core/lesson-plan.service';
import { ILessonPlan } from '../core/lesson-plan';
import { ILessonPlanItem } from '../core/lesson-plan-item';
import { ActivatedRoute } from '@angular/router';
import { BsModalComponent } from 'ng2-bs3-modal';
import { TeacherGuardService } from './teacher-guard.service';

@Component({
  selector: 'tch-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.css']
})
export class PlanListComponent implements OnInit {
	plans: ILessonPlan[] = [];
	planItems : ILessonPlanItem[] = [];
	errorMessage : string;
	listFilter: string;
	@ViewChild('nowVisible') nowVisible: BsModalComponent;	
	@ViewChild('nowHidden') nowHidden: BsModalComponent;	
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;
	plansById: ILessonPlan[] = [];
	myPlans: ILessonPlan[] = [];

	constructor(private lessonPlanService: LessonPlanService, private route: ActivatedRoute,
		private teacherGuardService : TeacherGuardService) { }

	ngOnInit() {
		this.listFilter = this.route.snapshot.queryParams['filterBy'] || '';
		this.lessonPlanService.getAllLessonPlans().subscribe (
			data => this.plans = data,
			error => this.errorMessage = <any>error,
			()  => {for (var i=0;i<this.plans.length;i++) {
						this.plansById[this.plans[i].lessonPlanId] = this.plans[i];
						if (this.isMine(this.plans[i].ownerId)) {
							this.myPlans.push(this.plans[i]);
						}
					}
				}
		);
	}

	showPlan(planId) {
		this.plansById[planId].visibleToStudents = true;
		this.lessonPlanService.updateLessonPlan(this.plansById[planId]).subscribe(
			data =>{
				let that = this;				
				this.nowVisible.open('sm')
					.then(function() { setTimeout(function() {that.nowVisible.close();},2000);
				});
			},
			error => this.errorMessage = JSON.stringify(<any>error)
		);		
	}
	
	hidePlan(planId) {
		this.plansById[planId].visibleToStudents = false;
		this.lessonPlanService.updateLessonPlan(this.plansById[planId]).subscribe(
			data =>{
				let that = this;				
				this.nowHidden.open('sm')
					.then(function() { setTimeout(function() {that.nowHidden.close();},2000);
				});
			},
			error => this.errorMessage = JSON.stringify(<any>error)
		);		
	}

	visibility(planId) {
		return this.plansById[planId].visibleToStudents == true ? 'Visible' : 'Hidden';
	}

	isMine(planOwnerId : number) {
		if (this.teacherGuardService.isMine(String(planOwnerId))) {
			return true;
		} else {
			return false;
		}
	}

	isAdmin() {
		return this.teacherGuardService.isAdmin;
	}

	showShow(planOwnerId : number, planId) {
		return this.isMine(planOwnerId) && this.plansById[planId].visibleToStudents != true;
	}

	showHide (planOwnerId : number, planId) {
		return this.isMine(planOwnerId) && this.plansById[planId].visibleToStudents == true;
	}
}
