<div class="adminstyle">
	<div class="col-md-offset-2">
		<div class="row">
			<input type = "file" (change)="onChange($event)" />
		</div>
		<div class="row">
			This upload will not load files to tch if you are using tchdev.
		</div>
		<div class="row">
			<input type="submit" class="btn btn-primary btn-spaced" (click)="uploadImageFile()" value="Upload Image" />
			<input type="submit" class="btn btn-primary btn-spaced" (click)="uploadAudioFile()" value="Upload Audio" />
		</div>
		<div class="row">{{message}}</div>
	</div>
</div>