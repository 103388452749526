import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LessonService } from '../core/lesson.service';
import { DictationLessonService } from '../core/dictation-lesson.service';
import { IDictation, IActiveDictation } from '../core/dictation';
import { CompareService } from '../core/compare.service';
import { HostListener } from '@angular/core';

@Component({
  selector: 'tch-short-dict-set',
  templateUrl: './short-dict-set.component.html',
  styleUrls: ['./short-dict-set.component.css']
})
export class ShortDictSetComponent implements OnInit {
	lesson : number;
	lessonName : string;
	allItems : IDictation[] = [];
	pageItems : IActiveDictation[] = [];
	errorMessage: string;
	atStart : boolean = true;
	atEnd : boolean = false;
	answer : string;
	totalPages : number;
	defaultSetSize : number = 5;
	showNext: boolean = false;
	currItem : number;
	currPage : number;
	audioElement : any;

  constructor(private dictationLessonService : DictationLessonService,
	private route: ActivatedRoute, private lessonService : LessonService,
	private compareService : CompareService) { }

	ngOnInit() {
		this.currPage = 0;
		this.currItem = 0;
		this.lesson = +this.route.snapshot.params['lesson'];
		this.lessonService.getLesson(this.lesson).subscribe(
			result => this.lessonName = result.name
		);  
		this.dictationLessonService.getDictationLesson(this.lesson).subscribe (
			data => this.allItems = data,
			error => this.errorMessage = <any>error,
			() => {
				this.totalPages = Math.floor(this.allItems.length/this.defaultSetSize);
				if (this.totalPages * this.defaultSetSize != this.allItems.length) this.totalPages += 1;
			}
		);
	}

	startLesson() : void {
		this.audioElement = document.createElement('audio');
		this.atStart = false;
		this.currItem = 0;
		this.itemSet();
	}

	itemSet() : void {
		this.showNext = false;
		this.currPage += 1;
		let setSize = this.defaultSetSize;
		// change the set size if we don't have 5 items left
		if (this.allItems.length - this.currItem < this.defaultSetSize) {
			setSize = this.allItems.length - this.currItem;
		}
		this.pageItems.length = 0;
		for (var i=0;i<setSize;i++) {
			this.pageItems.push ({dictation : this.allItems[this.currItem+i],
									status: '',
									showText : '',
									inputText: '',
									showShowButton: false
			});
		}
		let that = this;
		setTimeout(function() {that.listen(0)});
	}

	nextSet() : void {
		this.currItem += this.pageItems.length;
		this.itemSet();
	}

	listen(idx): void {
		this.pageItems[idx].showText = ' ';
		this.audioElement.pause();	
		this.audioElement.setAttribute('src',this.pageItems[idx].dictation.normalAudioUrl);
		this.audioElement.volume = 1;
		this.audioElement.play();	
		var element = document.getElementById("dict" + (idx+this.currItem+1));
		element.focus();
	}

	listenSlow(idx): void {
		this.pageItems[idx].showText = ' ';
		this.audioElement.setAttribute('src',this.pageItems[idx].dictation.slowAudioUrl);
		this.audioElement.volume = 1;		
		this.audioElement.play();	
		var element = document.getElementById("dict" + (idx+this.currItem+1));
		element.focus();
	}

	// called from the Show button
	show(idx) : void {
		this.pageItems[idx].showText = this.pageItems[idx].dictation.text;
	}

	clearAnswer(idx): void {
		this.pageItems[idx].showText = ' ';
	}

	// called from the Check button
	check(idx) : void {
		let key = this.pageItems[idx].dictation.text;
		let answer = this.strip(document.getElementById("dict" + this.pageItems[idx].dictation.position).innerHTML);
		if (answer.length > 0) {
			this.pageItems[idx].showShowButton = true;
		}
		let formatted : string = this.compareService.compareByWord (key,answer);
		// remove duplicate spans
		let stripped = formatted.replace (/<\/span><span  class="hilite">/g,'');
		document.getElementById("dict" + this.pageItems[idx].dictation.position).innerHTML = stripped;
		if (this.pageItems[idx].dictation.text == stripped) {
			this.pageItems[idx].status = './assets/GoodCheck.png';
			this.checkPageStatus();
		} else {
			this.pageItems[idx].status = '';
			let answerField = document.getElementById("dict" + this.pageItems[idx].dictation.position);
			let spanList = answerField.getElementsByTagName("span");
			if (spanList.length > 0) {
				let range = document.createRange();
				let sel = window.getSelection();
				range.setStart(spanList[0],0);
				range.collapse(true);
				sel.removeAllRanges();
				sel.addRange(range);
			}

		}
	}

	checkPageStatus() : void {
		this.showNext = false;
		let cnt = 0;
		for (var i=0;i<this.pageItems.length;i++) {
			if (this.pageItems[i].status != '') cnt++;
		}
		if (cnt == this.pageItems.length) {
			if (this.currItem + this.pageItems.length >= this.allItems.length) {
				this.atEnd = true;
				this.currPage = 0;
				this.currItem = 0;
				this.pageItems = null;
			} else {
				this.showNext = true;
				setTimeout(function() {
					var element = document.getElementById('nextButton');
					if (element) {
						element.focus();
					}
				});
			}
		}
	}

	// removes underscores and leading and training spaces, and converts duplicate spaces inside to a single space
	strip(html) : string {
		var tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		var tmp2 = tmp.textContent || tmp.innerText || "";
		tmp2 = tmp2.replace(/_/g,' ').trim();
		tmp2 = tmp2.replace(/\s+/g,' ');       	
		return tmp2;
	}

	// catches the enter key and handles it differently in input fields
	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		const target = event.target as HTMLDivElement;
		if (event.keyCode === 13 && target.id.indexOf("dict") == 0) {
			let that = this;
			setTimeout(function() {
				var posInFullList = parseInt(target.id.substr(4));
				var pos = posInFullList;
				while (pos > that.defaultSetSize) {
					pos = pos - that.defaultSetSize;
				}
				that.check(pos-1);
				if (that.pageItems && that.pageItems[pos-1].status != '') {
					if (posInFullList != that.allItems.length && pos < that.defaultSetSize) {
						that.listen(pos);
					}
				}			
			});			
			event.preventDefault();
		}
	}
}
