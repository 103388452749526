<div class="wrapper" *ngIf="atEnd == false && words.length > 0" class="{{sliderClass}}">
	<div class="row hidden-xs ">
		<ul class="list-inline thumbnails" >
			<li class="col-sm-6 col-md-3" *ngFor='let word of images; let i = index'>
				<div class="panel panel-info">
					<div class="text-center" style="height: 250px;">
						<img draggable="false" src="{{word.imageUrl}}" />
					</div>
					<div class="text-center" style="margin-top: 10px; margin-bottom: 10px;">
						<div class="btn btn-droppable toCont container" title="{{i}}" [dragula]='"dropTarget"'></div>                
					</div>
				</div>
			</li>
		</ul>
	</div>
	<div class="row visible-xs-block">
		<ul class="list-inline thumbnails" style="margin-bottom: 0px;" >
			<li *ngFor='let word of images; let i = index'>
				<div class="panel panel-info" style="margin-bottom: 6px;">
					<div class="text-center" style="height: 115px;">
						<img style="max-height: 100px; max-width: 160px;" draggable="false" src="{{word.imageUrl}}" />
					</div>
					<div class="text-center">
						<div class="btn btn-xs-droppable toCont container" title="{{i}}" [dragula]='"dropTarget"'></div>                
					</div>
				</div>
			</li>
		</ul>
	</div>
	<div class="visible-xs-block row">
		<div style="text-align: center;" >
			<div style="margin-right: 10px;" class="btn btn-xs-droppable container fromCont" title="{{i}}" *ngFor='let word of unmatched; let i = index' [dragula]='"dropTarget"'>
				<div class="btn btn-default btn-xs-draggable">
					<div *ngIf="word.length <= 11" class="dragtile">{{word}}</div>
					<div *ngIf="word.length > 11" class="dragtile" >{{word}}</div>
				</div>
			</div>
		</div>
	</div>	
	<div class="hidden-sm hidden-xs row">
		<div class="list-inline" style="text-align: center;" >
			<div class="btn btn-droppable container fromCont" title="{{i}}" *ngFor='let word of unmatched; let i = index' [dragula]='"dropTarget"'>
				<div class="btn btn-default btn-draggable lgfont" >
					<div *ngIf="word.length <= 11" class="dragtile">{{word}}</div>
					<div *ngIf="word.length > 11" class="dragtile" style="font-size: 30px; white-space: normal; line-height: 0.8;" >{{word}}</div>
				</div>
			</div>
		</div>
	</div>  
	<div class="hidden-lg hidden-md hidden-xs row">
		<div style="text-align: center;" >
			<div style="margin-right: 100px;" class="btn btn-droppable container fromCont" title="{{i}}" *ngFor='let word of unmatched; let i = index' [dragula]='"dropTarget"'>
				<div class="btn btn-default btn-draggable lgfont" >
					<div *ngIf="word.length <= 11" class="dragtile">{{word}}</div>
					<div *ngIf="word.length > 11" class="dragtile" style="font-size: 30px; white-space: normal; line-height: 0.8;" >{{word}}</div>
				</div>
			</div>
		</div>
	</div>  
	  
</div>
<div class="row container"*ngIf="atEnd == true">
    <div class="pager">
		<button class="btn btn-default" (click)="spellingTasksService.sameTask()">&larr; Repeat</button>
		<button class="btn btn-default" (click)="spellingTasksService.nextTask()">Next &rarr;</button>
	</div>
    <div class="col-md-2">
        <img src="./images/smiley.jpg" />
    </div>
</div>    
