import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
	selector: '[tchFocus]'
})
export class FocusDirective {

	@Input() tchFocus: boolean = true;
	
    constructor(private element: ElementRef, private renderer: Renderer2) { 
		if (this.tchFocus) {
			element.nativeElement.focus();
			element.nativeElement.focus();
		}
	}

    ngOnChanges() { 
		this.focusM(this.tchFocus);
	}

    ngOnInit() { 
		this.focusM(this.tchFocus);
	}
	
	private focusM(fm : boolean) {
		if (fm) {
        	this.element.nativeElement.focus();		
			this.element.nativeElement.focus();
		}
	}
}
