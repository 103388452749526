import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpellingWordService } from '../core/spelling-word.service';
import { LessonService } from '../core/lesson.service';
import { ILesson } from '../core/lesson';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
    selector: 'tch',
	templateUrl: './spelling.component.html'
})
export class SpellingComponent implements OnInit {
    lesson: number;
	lessonName : string;
	started : boolean = true;

	constructor (private route: ActivatedRoute, 
				private router: Router,
				private spellingWordService : SpellingWordService, 
				private spellingTasksService : SpellingTasksService,
				private lessonService : LessonService) { 
		this.started = false;
	}

    // get the spelling words, storing them in the service so they can be re-used by all the spelling tasks
    ngOnInit(): void {
		this.lesson = +this.route.snapshot.params['lesson'];   
        this.spellingWordService.getSpellingWords(this.lesson);   
        this.lessonService.getLesson(this.lesson).subscribe(
			result => {this.lessonName = result.name;
						this.spellingWordService.setHideWord(result.hideWord);}
        );  
		// determine if any tasks have been specified
		let tasks = ["1","2","3","4","5"];
		if (this.route.snapshot.queryParams['modules']) {
			tasks = this.route.snapshot.queryParams['modules'].split('');
		}
		this.spellingTasksService.setTasks(this.lesson, tasks);
	}
 
}      

