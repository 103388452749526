<div class="adminstyle">
	<div class="row">
		<div id="leftcol" class="col-md-2">
			<ul class="list-group list-scrollable">
				<li class="list-group-item" *ngFor="let c of candidates; let i = index" 
						(click)="selectCandidate(i)" 
						[ngClass]="{'active': i == currCandidate}">
					{{c.word}}
				</li>
			</ul>
		</div>
		<div class="col-md-1">
			<div style="margin-top: 30px;">
				<input *ngIf="currCandidate > -1" class="btn btn-primary" type="submit" 
						value="&gt;" (click)="addWordToLesson()"/>
				<input *ngIf="currSelected > -1" class="btn btn-primary" type="submit" 
						value="&lt;" (click)="removeWordFromLesson()"/>
			</div>
		</div>  
		<div id="rightcol" class="col-md-9">
			<div class="col-md-12" >
				<div class="row" >
					<form #form="ngForm">
						<label for="lessons">Choose a Lesson:</label>
						<select name="lessons" id="lessons" [(ngModel)]="currLesson" (ngModelChange)="selectLesson()">	  
							<option *ngFor="let lesson of lessons" [ngValue]="lesson">{{lesson.name}}</option>  
						</select>
						<input class="btn btn-primary col-md-offset-1" type="submit" value="New" *ngIf="!showAddNew"
							(click)="showAddNew=true;showOneLesson=false"/>
					</form>
				</div>
				<div class="row" *ngIf="showAddNew">
					<div class="form-hoizontal text-center">
						<label class="col-md-3 control-label" for="newLesson">New Lesson Name</label>
						<input name="newLessonName" class="editing col-md-6" [(ngModel)]="newLessonName" />				
						<input class="btn btn-primary btn-spaced" type="submit" value="Save" 
								(click)="createNewLesson()"/>
					</div>
				</div>
				<div class="row" *ngIf="showOneLesson">
					<label class="col-md-3 control-label" for="newLesson">Lesson Name</label>
					<input class="editing col-md-6" [(ngModel)]="currLesson.name" />				
					<input class="btn btn-primary col-md-offset-1" type="submit" value="Update" 
								(click)="updateLesson()"/>
					<label class="checkbox-inline"><input type="checkbox" [(ngModel)]="hideCueWord" class="form-check-input">Hide cue word</label>
					<label class="radio-inline col-md-offset-1"><input type="radio" [(ngModel)]="currLesson.active" value="1" >Active in TCH and Dev</label>
					<label class="radio-inline"><input type="radio" [(ngModel)]="currLesson.active" value="2" >Active in Dev Only</label>
					<label class="radio-inline"><input type="radio" [(ngModel)]="currLesson.active" value="0" >Admin Only</label>
					<hr class="fulldark" />
					<ul class="list-group list-scrollable col-md-4">
						<li class="list-group-item" *ngFor="let s of selected; let i = index" 
								(click)="selectSelected(i)" 
								[ngClass]="{'active': i == currSelected}">
							{{s.word}}
						</li>
					</ul>
					<div class="col-md-6 col-md-offset-1">
						<div class="row">
							Enter comma-separated tags and click Save Tags.
						</div>
						<div class="row">
							<input class="btn btn-primary btn-spaced" type="submit" value="Save Tags"  (click)="saveTags()"/>
						</div>
						<div class="row">
							<textarea name="tags" rows="5" class="editing form-control adminstyle" 
								[(ngModel)]="tags"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
