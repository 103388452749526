import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LessonPlanService } from './core/lesson-plan.service';
import { ILessonPlan } from './core/lesson-plan';

@Component({
	selector: 'tch-teacher-plan-list',
	templateUrl: './teacher-plan-list.component.html',
	styleUrls: ['./teacher-plan-list.component.css']
})
export class TeacherPlanListComponent implements OnInit {
	@Input() elaTeacherId: number;
	plans: ILessonPlan[] = [];
	errorMessage: string;

	constructor(private lessonPlanService: LessonPlanService, route: ActivatedRoute) {
		this.elaTeacherId = 0;
	}

	ngOnInit() {
		if (this.elaTeacherId) {
			this.lessonPlanService.getTeacherLessonPlans(this.elaTeacherId).subscribe(
				data => this.plans = data,
				error => this.errorMessage = <any>error,
				() => { }
			);
		};
	}

	ngOnChanges() {
		if (this.elaTeacherId) {
			this.lessonPlanService.getTeacherLessonPlans(this.elaTeacherId).subscribe(
				data => this.plans = data,
				error => this.errorMessage = <any>error,
				() => {}
			);
		};
	}

}
