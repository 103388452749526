import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { SpellingModule } from './spelling/spelling.module';
import { GrammarModule } from './grammar/grammar.module';
import { NarrativeModule } from './narrative/narrative.module';
import { DictationModule } from './dictation/dictation.module';
import { LepModule } from './lep/lep.module';
import { AdminModule } from './admin/admin.module';
import { TeacherModule } from './teacher/teacher.module';
import { AppLessonsComponent } from './app-lessons.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { LessonListComponent } from './lesson-list.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { FormsModule } from "@angular/forms";
import { SpellingTasksService } from './spelling/spelling-tasks.service';
import { TeacherPlanListComponent } from './teacher-plan-list.component';
import { CurrentPlansModule } from './current-plans/current-plans.module';

@NgModule({
  declarations: [
	AppComponent,
    AppLessonsComponent,
    PageNotFoundComponent,
	LessonListComponent,
	TeacherPlanListComponent
  ],
  imports: [
	BrowserModule,
	FormsModule,
    RouterModule.forRoot([
	  { path: 'apps', component: AppLessonsComponent },
      { path: '', redirectTo: '/apps', pathMatch: 'full' },
      { path: '**', redirectTo: '/apps', pathMatch: 'full' },
    ], {onSameUrlNavigation: 'reload'}),
    HttpClientModule,
    SpellingModule,
    GrammarModule,
	DictationModule,
	NarrativeModule,
    LepModule,
    AdminModule,
    TeacherModule,
	CoreModule,
	CurrentPlansModule
  ],
  providers: [SpellingTasksService],
  bootstrap: [AppComponent]
})
export class AppModule { 

private newMethod(): string {
  return '/';
}
}
