<nav class="navbar-default" >
    <div class="navbar-header">
        <a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
	</div>
	<div class="visible-md-inline pull-right" style="padding-top: 6px; padding-right: 10px;">{{lessonName}}</div>
	<div class="row hidden-md">
		<div class="hidden-md" style="text-align: center; font-size: 80%;">{{lessonName}}</div>
	</div>
</nav>
<div class="input-group col-sm-offset-5 mt-2" *ngIf="atStart">
	<button class="btn btn-default" (click)="startLesson()">Start</button>
</div>
<div *ngIf="currWord">
	<bs-modal [animation]="animation" [keyboard]="keyboard" [backdrop]="backdrop" #answerDisplay  >
		<bs-modal-body>
				{{currWord.sentenceA}} <u>{{currWord.answer1}}</u> {{currWord.sentenceB}} <u>{{currWord.answer2}}</u> {{currWord.sentenceC}}
		</bs-modal-body>
		<bs-modal-footer>
			<button autofocus type="button" class="btn btn-primary" (click)="answerClosed()">Ok</button>
		</bs-modal-footer>
	</bs-modal>
	<bs-modal id="tryAgain" [animation]="animation" [keyboard]="keyboard" [backdrop]="false"  (onClose)="tryAgainClosed()" #tryAgain >
		<bs-modal-body>Try again.</bs-modal-body>
	</bs-modal>
	<div class="container visible-md-block visible-lg-block" style="margin-top: 10px">
		<div class="col-md-12 table-bordered"  *ngIf="currWord">
			<div class="col-md-11">
				<form #form="ngForm" >
					<div class="row">
						<div class="col-md-5 col-md-offset-2">
							<img style="margin: 30px" src="{{currWord.imageUrl}}" /> 
						</div>
						<div class="col-md-3 col-md-offset-2" >
							<button class="btn btn-primary" style="margin-top: 100px;" type="submit" (click)=checkAnswer(); >Next Item</button>
						</div>
					</div>
					<div layout="row">
						<table class="col-md-12 text-center lgfont table table-bordered" >
							<tr><td>{{currWord.cue}}</td></tr>
							<tr style="height: 76px;" class="form-inline">
								<td style="vertical-align: middle; font-weight: bold;">
									<span style="margin-right: .5em;">{{currWord.sentenceA}}</span>
									<input #ans1 name="answer1" [(ngModel)]="answer1" class="form-control typein" [ngStyle]="{'width.px': word1Width}" type="text" 
										[tchFocus]="word1Focus" autocapitalize="none" autocomplete="off"/>
									<span style="margin-left: .5em; margin-right: .5em;">{{currWord.sentenceB}}</span>
									<input #ans2 name="answer2" *ngIf="currWord.answer2" [(ngModel)]="answer2" class="form-control typein" [ngStyle]="{'width.px': word2Width}" 
											type="text" [tchFocus]="word2Focus" autocapitalize="none" autocomplete="off"/>
									<span *ngIf="currWord.answer2" style="margin-left: .5em;">{{currWord.sentenceC}}</span>
								</td>
							</tr>
						</table>
					</div>
				</form>
			</div>
			<div class="col-md-1">
				<div class="row" style="font-size: 60%; text-align: center; padding-top: 10px; padding-right: 0px; padding-bottom: 10px;">
					Block {{currBlock+1}}<br />of {{blockStarts.length}}
				</div>
				<table class="itemtable">
					<tr *ngFor='let attempt of attempts; let i = index' >
						<td [class.boxactive]="i == currItem" [class.boxinactive]="i != currItem" *ngIf="attempt>-1 && attempt<4">{{i+1}}</td>
						<td class="boxinactive success" *ngIf="attempt==-1">{{i+1}}</td>
						<td class="boxinactive errors" *ngIf="attempt==4">{{i+1}}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>	
	<div class="container hidden-md hidden-lg" style="font-size: 80%;">
		<div  *ngIf="currWord">
			<form #form="ngForm" >
				<div class="row" style="text-align: center;">
					<img src="{{currWord.imageUrl}}" /> 
					<button class="btn btn-primary" style="margin: 6px;" type="submit" (click)=checkAnswer(); >Next Item</button>
					<p>{{currWord.cue}}</p>
					<hr />
				</div>
				<div class="form-row">
					<div class="col">
						<div style="min-height: 55px; vertical-align: middle;">
							<span style="margin-right: .5em;" >{{currWord.sentenceA}}&nbsp;</span>
							<input #ans3 name="answer1" [(ngModel)]="answer1" class="inline-form-control typein" [ngStyle]="{'width.px': word1Width}" type="text" 
									[tchFocus]="word1Focus" autocapitalize="none" autocomplete="off" spellcheck="false"  />
							<span style="margin-left: .5em; margin-right: .5em;">{{currWord.sentenceB}}</span>
							<input #ans4 name="answer2" *ngIf="currWord.answer2" [(ngModel)]="answer2" class="inline-form-control typein" [ngStyle]="{'width.px': word2Width}" 
									type="text" [tchFocus]="word2Focus" autocapitalize="none" autocomplete="off" spellcheck="false"  />
							<span  style="margin-left: .5em;" *ngIf="currWord.answer2">{{currWord.sentenceC}}</span>
						</div>
					</div>
				</div>

			</form>
			<div class="row" style="font-size: 60%; text-align: center; padding-top: 10px; padding-right: 0px; padding-bottom: 10px;">
				Block {{currBlock+1}} of {{blockStarts.length}}
			</div>
			<div >
				<div style="display: inline;" *ngFor='let attempt of attempts; let i = index' >
					<span style="padding: 4px;" [class.boxactive]="i == currItem" [class.boxinactive]="i != currItem" *ngIf="attempt>-1 && attempt<4">{{i+1}}</span>
					<span style="padding: 4px;" class="boxinactive success" *ngIf="attempt==-1">{{i+1}}</span>
					<span style="padding: 4px;" class="boxinactive errors" *ngIf="attempt==4">{{i+1}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row container" style="margin-top: 20px;" *ngIf="atEnd == true">
	<div class="col-md-2">
		<img src="./images/smiley.jpg" />
	</div>	
</div>

