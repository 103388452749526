import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LessonService } from '../core/lesson.service';
import { GrammarLessonService } from '../core/grammar-lesson.service';
import { IGrammarItem } from '../core/grammar-item';
import { BsModalComponent, BsModalService  } from 'ng2-bs3-modal';

@Component({
	selector: 'tch-fill-blank',
	templateUrl: './fill-blank.component.html',
	styleUrls: ['./fill-blank.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class FillBlankComponent implements OnInit {
	lesson : number;
	lessonName : string;
	items : IGrammarItem[] = [];
	errorMessage: string;
	atStart : boolean = true;
	atEnd : boolean = false;
	defaultBlockSize : number = 10;
	blockSizes : number[] = [];
	blockStarts : number[] = [];
	@ViewChild('tryAgain') tryAgain: BsModalComponent;
	@ViewChild('answerDisplay') answerDisplay: BsModalComponent;
	@ViewChild('ans1') ans1 : ElementRef;
	@ViewChild('ans2') ans2 : ElementRef;
	@ViewChild('ans3') ans3 : ElementRef;
	@ViewChild('ans4') ans4 : ElementRef;
	animation = true;
	keyboard = true;
	backdrop : string | boolean = true;
	css = false;
	cssClass = '';
	attempts : number[];
	block : IGrammarItem[];
	currBlock : number;
	currItem : number;
	currWord : IGrammarItem;
	answer1: string = null;
	answer2: string = null;
	word1Width: number = null;
	word2Width: number = null;
	word1Focus = true;
	word2Focus = false;
	
	constructor(private grammarLessonService : GrammarLessonService, 
		private route: ActivatedRoute, private lessonService : LessonService,
		private modalservice : BsModalService
	) { 
  	}

  	ngOnInit() {
		this.lesson = +this.route.snapshot.params['lesson'];
		this.lessonService.getLesson(this.lesson).subscribe(
			result => this.lessonName = result.name
		);  
		this.grammarLessonService.getGrammarLesson(this.lesson).subscribe (
			data => this.items = data,
			error => this.errorMessage = <any>error,
			() => {
				// determine where each page starts, and the number of items on the page
				for (var i=0;i<this.items.length;i+=this.defaultBlockSize) {
					this.blockStarts.push(i);
					if (i + this.defaultBlockSize > this.items.length) {
						this.blockSizes.push (this.items.length - i);
					} else {
						this.blockSizes.push(this.defaultBlockSize);
					}
				}	
			}
		);
	}

	 startLesson() : void {
		this.atStart = false;
		this.startBlock();
	}

	startBlock() {
		this.attempts = [];
		this.block = [];
		this.currBlock = this.currBlock == null ? 0 : this.currBlock+1;
		this.currItem = null;
		for (var i=0;i<this.blockSizes[this.currBlock];i++) {
			this.attempts.push(0);
			this.block.push(this.items[this.blockStarts[this.currBlock]+i]);
		}
		this.startItem();
	}

	startItem() {
		this.currItem = this.currItem == null ? 0 : this.currItem+1;
		this.currWord = this.block[this.currItem];
		this.answer1 = null;
		this.answer2 = null;
		this.word1Width = this.currWord.answer1.length*22;
		this.word2Width = this.currWord.answer2 ? this.currWord.answer2.length * 22 : 0;
		this.word1Focus = true;
		this.word2Focus = false;
		if  (this.word1Width < 100) this.word1Width = 100;
		if  (this.word2Width && this.word2Width < 100) this.word2Width = 100;
		if (this.ans1) {
			this.ans1.nativeElement.focus();
		}
		if (this.ans3) {
			this.ans3.nativeElement.focus();
		}
		this.attempts[this.currItem] = 0;
	}

	checkAnswer() {
		this.attempts[this.currItem]++;
		let goodAnswer = true;
		if (!this.answer1 || this.answer1.trim() != this.currWord.answer1) {
			goodAnswer = false;
			this.word1Focus = true;
			this.word2Focus = false;
		}
		// if there is supposed to be a second answer
		if (goodAnswer && this.currWord.answer2 && this.currWord.answer2 != '' && (!this.answer2 || this.answer2.trim() != this.currWord.answer2)) {
			goodAnswer = false;
			this.word1Focus = false;
			this.word2Focus = true;
		}
		if (goodAnswer) {
			this.attempts[this.currItem] = -1;
			this.findNext();	
		} else {
			// pop-up with answer that must be dismissed.
			if (this.attempts[this.currItem] == 3) {
				this.answerDisplay.open();
			// pop-up that will fade away
			} else {
				let that = this;
				this.tryAgain.open('sm')
					.then(function() { setTimeout(function() {that.tryAgain.close();},2000);
				});
			}
		}
	}

	answerClosed() {
		this.answerDisplay.close();
		this.attempts[this.currItem] = 4;
		this.findNext();
	}

	findNext() {
		// go to the next item if there are more
		if (this.currItem+1 < this.block.length && this.attempts[this.currItem+1] == 0) {
			this.startItem();
		// go to the next item that needs to be revisited after this one (set to one behind because startItem will increment)
		} else if (this.currItem+1 < this.block.length && this.attempts.indexOf(4, this.currItem+1) > -1) {
			this.currItem = this.attempts.indexOf(4, this.currItem+1)-1;
			this.startItem();
		// go to the first item that needs to be revisited (set to one behind because startItem will increment)
		} else if (this.attempts.indexOf(4) > -1) {
			this.currItem = this.attempts.indexOf(4)-1;
			this.startItem();
		// go to the next block
		} else if (this.currBlock+1 < this.blockStarts.length) {
			this.startBlock();
		} else {
			this.atEnd = true;
			this.currWord = null;
		}
	}

	tryAgainClosed() {
		if (this.answer1 != this.currWord.answer1) {
			if (this.ans1) {
				this.ans1.nativeElement.focus();
			}
			if (this.ans3) {
				this.ans3.nativeElement.focus();
			}
		} else {
			if (this.ans2) {
				this.ans2.nativeElement.focus();
			}
			if (this.ans4) {
				this.ans4.nativeElement.focus();
			}
		}
	}

}
