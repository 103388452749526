import { Component } from '@angular/core';

@Component({
  selector: 'tch-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tch';

  onRightClick() {
	if (window.location.hostname.split('.')[0] == 'tch') {
		return false;
	} else {
		return true;
	}
  }
}
