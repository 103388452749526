import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IGrammarItem } from './grammar-item';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/of';

@Injectable()
export class GrammarLessonService {
	items : IGrammarItem[] = [];
    errorMessage : string;
    
    constructor(private _http: HttpClient) {  }

	getGrammarLesson(lessonId) : Observable<IGrammarItem[]> {
		if (window.location.hostname == 'localhost') {
		  return Observable.of ([
			  {	lessonId : 1,
				sectionId : 1,
				itemId : 1,
				cue : 'color',
				sentenceA: 'Do you like my',
				sentenceB: 'boots?',
				sentenceC: null,
				answer1: 'black',
				answer2: null,
				imageUrl: './images/black.jpg'},
			{	lessonId : 1,
				sectionId : 1,
				itemId : 2,
				cue : 'color',
				sentenceA: 'She is wearning',
				sentenceB: 'jeans and a',
				sentenceC: 'to the picnic.',
				answer1: 'blue',
				answer2: 'blouse',
				imageUrl: './images/blue.jpg' }
		  ]);
		} else {
			let webServiceUrl = 'webservices/services/getGrammarItems.php?lessonId=' + encodeURIComponent(lessonId);
			return this._http.get<IGrammarItem[]>(webServiceUrl)
			  .catch(this.handleError);
		}
	}	

	deleteItem (item : IGrammarItem) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/deleteGrammarItem.php', encodedData);
	}

	addItem (item : IGrammarItem) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/addGrammarItem.php', encodedData);
	}
	
	updateItem (item : IGrammarItem) {
		var encodedData = JSON.stringify(item);
		return this._http.post('webservices/services/updateGrammarItem.php', encodedData);
	}	

	public fixSection(item : IGrammarItem) {
		// adjust for the old numbering scheme of 4 blocks of 10 items
		if (item.itemId > 50) {
			item.sectionId = 5;
		} else {
			item.sectionId = Math.floor(item.itemId / 10);
			item.itemId = item.itemId - (item.sectionId*10);
		}	
	}

	private handleError(err: HttpErrorResponse) {
		if (err.error instanceof ErrorEvent) {
			console.error(err.message);
			console.error('Client Side Error: ', err.error.message);
		} else {
			console.error(err.message);
			console.error('Error from web service: ', err.error.text);
		}
		return Observable.throw(err.message);
	}
}
