import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LessonService } from './lesson.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { SpellingWordService } from './spelling-word.service';
import { LoginService } from './login.service';
import { LepService } from './lep.service'
import { GrammarLessonService } from './grammar-lesson.service';
import { FocusDirective } from './focus.directive';
import { DictationLessonService } from './dictation-lesson.service';
import { CompareService } from './compare.service';
import { PersonService } from './person.service';
import { TagService } from './tag.service'

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FocusDirective],
  providers: [
		LessonService, 
		SpellingWordService, 
		GrammarLessonService,
		LepService, 
		DictationLessonService,
		LoginService,
		CompareService,
		PersonService,
		TagService
	],
  exports: [FocusDirective]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
 }
