<nav class="navbar-default" >
		<div class="navbar-header">
			<a class="navbar-brand" href="/">&lt;-Back to TCH Menu</a>
		</div>
		<div class="pull-right" style="padding-top: 6px; padding-right: 10px;">{{plan.lessonPlanName}}</div>
	</nav>
	<div class="row" style="margin-top: 30px;">
		<router-outlet></router-outlet>
	</div>
	<div class="container-fluid col-md-offset-1">
		<h3>Your teacher would like you to do these lessons:</h3>
		<table>
			<tr *ngFor='let pi of planItems; let i = index'>
				<td class="col-md-4"><h4>{{i+1}}. {{pi.description}}</h4></td>
				<td class="col-md-6"><a href href="{{pi.url}}" target="_blank"><h4>{{pi.url}}</h4></a></td>
			</tr>
		</table>
	</div>
