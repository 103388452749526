import { Component, OnInit, OnChanges, Input, AfterViewInit  } from '@angular/core';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { ActivatedRoute } from '@angular/router';
import { SpellingTasksService } from './spelling-tasks.service';

@Component({
  selector: 'tch-fill-in-word',
  templateUrl: './fill-in-word.component.html',
  styleUrls: ['./fill-in-word.component.css']
})
export class FillInWordComponent implements OnInit {
	lesson: number;
	words: ISpellingWord[] = [];
	wordsExt : any[] = [];
	curr : number = 0;
	showButtons : boolean = false;
	atEnd : Boolean = false;
	typedWord : string;
	errorMessage : string;

	constructor(private spellingWordService : SpellingWordService, 
				private spellingTasksService : SpellingTasksService,
				private route: ActivatedRoute) { 
	}

	ngOnInit() {
        this.lesson = +this.route.parent.snapshot.params['lesson'];
		this.words = this.spellingWordService.spellingWords;
		if (this.words.length > 0) {
			this.prepWords();
		// we've lost the words -- probably through a page refresh, so get them again
		} else {
			this.spellingWordService.getWords(this.lesson).subscribe(
				words => {this.words = words; this.prepWords();},
				error => {this.errorMessage = <any>error; console.log(this.errorMessage)}
			);
		}
	}

	prepWords(): void {
		this.spellingWordService.shuffle(this.words);
		// add some Extension properties to the words applicable for this activity
		for (var i=0;i<this.words.length;i++) {
			this.wordsExt.push({});
			this.wordsExt[i].attempts = 0;
		}
		this.typedWord = "";
		this.displayWord();
	}


	displayWord() {
		this.showButtons = false;
	}

	repeatWord() {
		this.wordsExt[this.curr].attempts = 0;
		this.typedWord = "";
		this.displayWord();
		document.getElementById("typedWordField").focus();
	}

	nextWord() {
		this.curr++;
		if (this.curr == this.words.length) {
			this.finishUp();
		} else {
			this.typedWord = "";
			this.displayWord();
			document.getElementById("typedWordField").focus();
		}
	}

	onCheck() {
		document.getElementById("typedWordField").focus();
		this.wordsExt[this.curr].attempts += 1;
		if (this.typedWord.trim() == this.words[this.curr].word) {
			this.curr++;
			this.typedWord = "";
			if (this.curr == this.words.length) {
				this.finishUp();
			} else {
				this.displayWord();
			}
		} else {
			if (this.wordsExt[this.curr].attempts >= 3) {
				this.showButtons = true;
			} else {
				this.displayWord();
			}
		}
	}

	finishUp() {
		this.atEnd = true;
	}	
}
