import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LessonPlanService } from '../core/lesson-plan.service';
import { ILessonPlan } from '../core/lesson-plan';
import { ILessonPlanItem } from '../core/lesson-plan-item';

@Component({
  selector: 'tch-current-plans',
  templateUrl: './current-plans.component.html',
  styleUrls: ['./current-plans.component.css']
})
export class CurrentPlansComponent implements OnInit {
	planId : number;
	plan : ILessonPlan;	
	planItems : ILessonPlanItem[] = [];	
	errorMessage: string;	

  constructor(private lessonPlanService: LessonPlanService, private route: ActivatedRoute) { }

  ngOnInit() {
	this.planId = +this.route.snapshot.params['plan']; 
	this.lessonPlanService.getLessonPlan(this.planId).subscribe (
		data => this.plan = data,
		error => this.errorMessage = <any>error
	);
	this.lessonPlanService.getLessonPlanItems(this.planId).subscribe (
		data => this.planItems = data,
		error => this.errorMessage = <any>error
	);
  }

}
