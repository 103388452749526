import { Component, OnInit } from '@angular/core';
import { LessonPlanService } from '../core/lesson-plan.service';
import { ILessonPlan } from '../core/lesson-plan';
import { ILessonPlanItem } from '../core/lesson-plan-item';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherGuardService } from './teacher-guard.service';
import { SpellingWordService } from '../core/spelling-word.service';
import { ISpellingWord } from '../core/spelling-word';
import { ILesson } from '../core/lesson';
import { LessonService } from '../core/lesson.service';

@Component({
  selector: 'tch-plan-edit',
  templateUrl: './plan-edit.component.html',
  styleUrls: ['./plan-edit.component.css']
})
export class PlanEditComponent implements OnInit {
	plan : ILessonPlan;
	planItems : ILessonPlanItem[] = [];
	errorMessage : string;
	spellingList : ILesson[];
	grammarList: ILesson[];
	dictationList: any[];
	spellingWords : ISpellingWord[];

	constructor(private lessonPlanService: LessonPlanService, private route: ActivatedRoute, private teacherGuardService : TeacherGuardService,
				private spellingWordService: SpellingWordService, private lessonService: LessonService, private router: Router) { }

	ngOnInit() {
		if (+this.route.snapshot.params['id'] == 0) {
			this.plan = {lessonPlanId: 0, ownerId: +this.teacherGuardService.teacherId, lessonPlanName: '', visibleToStudents: false };
			this.planItems = [];
			for (var x=1;x<=6;x++) {
				this.planItems.push({lessonPlanId: this.plan.lessonPlanId, itemOrder: x, description: '' , url:''});
			}
		} else {
			this.lessonPlanService.getLessonPlan(+this.route.snapshot.params['id']).subscribe (
				data => {this.plan = data; 
							// mySQL is retaining these as tinyInts rather than boolean
							if (data.visibleToStudents == "0") this.plan.visibleToStudents = false;
							if (data.visibleToStudents == "1") this.plan.visibleToStudents = true;
						},
				error => this.errorMessage = <any>error
			);
			this.lessonPlanService.getLessonPlanItems(+this.route.snapshot.params['id']).subscribe (
				data => {this.planItems = data;
					if (this.planItems.length < 6) {
						for (var x=this.planItems.length+1;x<=6;x++) {
							this.planItems.push({lessonPlanId: this.plan.lessonPlanId, itemOrder: x, description: '' , url:''});
						}
					}
				},
				error => this.errorMessage = <any>error
			);
		}
	}

	savePlan() {
		if (this.plan.lessonPlanId == 0) {
			this.lessonPlanService.addNewLessonPlan(this.plan).subscribe(
				data => {
					this.lessonPlanService.getMostRecentLessonPlan(String(this.plan.ownerId)).subscribe(
						data => {
							this.plan = data[0]; 
							for (var j=0;j<this.planItems.length;j++) {
								this.planItems[j].lessonPlanId = this.plan.lessonPlanId;
							}
							this.lessonPlanService.addLessonPlanItems(this.planItems).subscribe(
								data => {this.router.navigate(['teacher/plans', this.plan.lessonPlanId]);},
								error => this.errorMessage = JSON.stringify(<any>error),
								() => {if (this.errorMessage) 
											console.log('Error updating plan items: ' + this.errorMessage);
										}
							);
						},
						error => this.errorMessage = JSON.stringify(<any>error),
						() => {if (this.errorMessage) 
									console.log('Error retrieving plan: ' + this.errorMessage);
								}
					);
				},
				error => this.errorMessage = JSON.stringify(<any>error),
				() => {if (this.errorMessage) 
							console.log('Error inserting new plan: ' + this.errorMessage);
						}
			);
		} else {
			this.lessonPlanService.updateLessonPlan(this.plan).subscribe(
				data => {this.lessonPlanService.updateLessonPlanItems(this.planItems).subscribe(
							data => {this.router.navigate(['teacher/plans', this.plan.lessonPlanId]);
								},
							error => this.errorMessage = JSON.stringify(<any>error)
						);
				},
				error => this.errorMessage = JSON.stringify(<any>error)
			);
		}
	}

	listSpelling() {
		this.grammarList = null;
		this.dictationList = null;
		this.spellingList = [];
		this.lessonService.getLessons('1').subscribe(
			lessons => {this.spellingList = lessons;
						this.spellingWordService.getSpellingLessonWords().subscribe(
							words => {
								let matches = words;
								for (var l=0;l<lessons.length;l++) {
									this.spellingList[l].url = 'TCHSpelling/' + String(this.spellingList[l].id) + '?modules=12345';
									for (var w=0;w<matches.length;w++) {
										if (matches[w].lessonId == lessons[l].id) {
											if (this.spellingList[l].wordListString) {
												this.spellingList[l].wordListString += ', ' + matches[w].word;
											} else {
												this.spellingList[l].wordListString = matches[w].word;
											}									
										}
									}
								}
							},
							error => this.errorMessage = JSON.stringify(<any>error)
				  		);
			},
			error => this.errorMessage = JSON.stringify(<any>error)
		);
	}

	listGrammar() {
		this.spellingList = null;
		this.dictationList = null;
		this.grammarList = [];
		this.lessonService.getLessons('2').subscribe(
			lessons => {this.grammarList = lessons;
				for (var l=0;l<lessons.length;l++) {
					this.grammarList[l].url = 'TCHGrammar/' + String(this.grammarList[l].id);
				}
			},
			error => this.errorMessage = JSON.stringify(<any>error)
		);
	}

	listDictation() {
		this.spellingList = null;
		this.grammarList = null;
		this.dictationList = [];
		this.lessonService.getLessons('3').subscribe(
			lessons => {this.dictationList = lessons;
				for (var l=0;l<lessons.length;l++) {
					this.dictationList[l].url = 'TCHDictation/' + String(this.dictationList[l].id);
				}
			},
			error => this.errorMessage = JSON.stringify(<any>error)
		);
	}

	addLesson(lessonType: string, idx: number) {
		var lessonName : string;
		var lessonUrl : string;
		var lessonIdx : number = -1;
		if (lessonType == 'grammar') {
			lessonName = this.grammarList[idx].name;
			lessonUrl = this.grammarList[idx].url;
		} else if (lessonType == 'spelling') {
			lessonName = this.spellingList[idx].name;
			lessonUrl = this.spellingList[idx].url;
		} else if (lessonType == 'dictation') {
			lessonName = this.dictationList[idx].name;
			lessonUrl = this.dictationList[idx].url;
		}
		for (var x=0;x<6;x++) {
			if (lessonIdx == -1 && this.planItems[x].url == '') {
				this.planItems[x].description = lessonName;
				this.planItems[x].url = lessonUrl;
				lessonIdx = x;
			}
		}
	}

}
